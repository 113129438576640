import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footerHead">
      <footer className="footer container">
        <div className="footsection1">
          <div className="sectionn1">
            <h4>Quick Links</h4>
            <button type="button" onClick={() => navigate("/")}>
              Home
            </button>
            {/* <button type="button">Services</button> */}
            {/* <button type="button" onClick={() => navigate("/query")}>
              sumit query
            </button> */}
            <button type="button" onClick={() => navigate("/contact-us")}>
              Contact Us
            </button>
          </div>

          <div className="section2">
            <h4>Legal</h4>
            <button type="button" onClick={() => navigate("/privacy-policy")}>
              Privacy Policy
            </button>
            <button onClick={() => navigate("/cookie-policy")}>
              Cookie Policy
            </button>
            {/* <button>Security Policy</button> */}
            <button
              type="button"
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms and Conditions
            </button>
            <button onClick={() => navigate("/refund-can-user")}>
              Cancellation/Refund Policy
            </button>
            <button onClick={() => navigate("/shipping-policy")}>
              Shipping Policy
            </button>
          </div>

          <div className="section3">
            <h4>About Us</h4>
            <button type="button" onClick={() => navigate("/about-us")}>
              Our Story
            </button>
            <button type="button" onClick={() => navigate("/about-us")}>
              Team
            </button>
          </div>
          <div>
            <h4>Address</h4>
            <p>
              &nbsp; &nbsp;C-94, Ground Floor, Dev Vihar, Agra Road, Jaipur,
              Rajasthan
            </p>
          </div>
        </div>
        <div className="footer-info">
          <p>&copy; 2024 EzzOrder. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
