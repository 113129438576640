import React, { useEffect, useState } from "react";
import NavbarTop from "../../Navbar/NavbarTop";
import "./PrivacyPolicy.css";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";

function PrivacyPolicy() {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <div>
      <div>
        <NavbarTop />
      </div>
      <div className="container privacyHead">
        <div>
          <h1 className="privacyTitle">EzzOrder - Privacy Policy</h1>
        </div>

        <div className="PrivacyItems">
          <h4>1. Information We Collect:</h4>
          <p>
            EzzOrder collects two main types of information: personal
            information provided directly by users and non-personal information
            gathered through the use of our platform. Personal information may
            include but is not limited to names, contact details, and payment
            information. Non-personal information includes user preferences,
            device information, and usage patterns.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>2. How We Use Information:</h4>
          <p>
            The information collected by EzzOrder is used to provide and improve
            our services. Personal information is utilized to process
            transactions, customize user experience, and communicate important
            updates. Non-personal information is employed for analytical
            purposes, enhancing our platform's functionality, and tailoring it
            to user preferences.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>3. Information Sharing:</h4>
          <p>
            EzzOrder is committed to protecting user privacy. We do not sell,
            trade, or otherwise transfer personal information to third parties
            without explicit consent, except for the purpose of providing our
            services. Certain information may be shared with external service
            providers to facilitate transactions or enhance platform features,
            always adhering to strict privacy and security protocols.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>4. Data Security:</h4>
          <p>
            EzzOrder employs industry-standard security measures to protect user
            data. We use encryption, firewalls, and secure socket layer
            technology to ensure the confidentiality and integrity of
            information. Regular security audits are conducted to identify and
            address potential vulnerabilities promptly.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>5. Cookies and Tracking Technologies:</h4>
          <p>
            EzzOrder utilizes cookies and similar tracking technologies to
            enhance user experience and gather information about user
            preferences. These technologies help us optimize our platform,
            provide personalized content, and track usage patterns. Users have
            the option to manage cookie preferences through their browser
            settings.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>6. User Control and Access:</h4>
          <p>
            Users have control over the information they provide to EzzOrder.
            They can update, correct, or delete their personal information
            through their account settings. Users can also contact our support
            team to exercise their rights regarding data access, rectification,
            or deletion.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>7. Third-Party Links:</h4>
          <p>
            EzzOrder may include links to third-party websites or services. We
            are not responsible for the privacy practices or content of these
            external sites. Users are encouraged to review the privacy policies
            of any linked sites they visit through EzzOrder.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>8. Updates to Privacy Policy:</h4>
          <p>
            EzzOrder may update this Privacy Policy periodically to reflect
            changes in our practices and legal requirements. Users will be
            notified of any significant updates, and their continued use of our
            services implies acceptance of the revised policy.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>9. Children's Privacy:</h4>
          <p>
            EzzOrder is not intended for use by individuals under the age of 18.
            We do not knowingly collect personal information from children. If
            we become aware of any such information, we will take prompt steps
            to delete it.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>10. Compliance with Laws:</h4>
          <p>
            EzzOrder operates in compliance with relevant data protection laws.
            Users outside the jurisdiction of our operations consent to the
            transfer and processing of their information in accordance with this
            Privacy Policy.
          </p>
        </div>

        <div className="PrivacyItems">
          <h4>11. Contact Information:</h4>
          <p>
            For any inquiries or concerns regarding this Privacy Policy or the
            handling of personal information, users can contact our Privacy
            Officer at [privacy@ezzorder.com].
          </p>
        </div>

        <div className="PrivacyItems">
          <p>
            By using EzzOrder, users acknowledge that they have read and
            understood this Privacy Policy. It is crucial for users to review
            this policy regularly to stay informed about how their information
            is being handled. For legal advice tailored to specific
            circumstances, users are encouraged to seek professional
            consultation.
          </p>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
