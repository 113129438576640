import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./User2.css"; // Import the CSS file for styling

const User2 = () => {
  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 3, // Set the number of slides to show at once
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 500,
  };

  return (
    <div className="container userHead2">
      <Slider {...settings}>
        <div className="imageHead">
          <img
            src="https://placekitten.com/800/400"
            alt="Image 1"
            className="slider-image"
          />
          <p>manish</p>
        </div>
        <div className="imageHead">
          <img
            src="https://placekitten.com/800/400"
            alt="Image 1"
            className="slider-image"
          />
          <p>manish</p>
        </div>
        <div className="imageHead">
          <img
            src="https://placekitten.com/800/400"
            alt="Image 1"
            className="slider-image"
          />
          <p>manish</p>
        </div>
        <div className="imageHead">
          <img
            src="https://placekitten.com/800/400"
            alt="Image 1"
            className="slider-image"
          />
          <p>manish</p>
        </div>
        <div className="imageHead">
          <img
            src="https://placekitten.com/800/400"
            alt="Image 1"
            className="slider-image"
          />
          <p>manish</p>
        </div>
        <div className="imageHead">
          <img
            src="https://placekitten.com/800/400"
            alt="Image 1"
            className="slider-image"
          />
          <p>manish</p>
        </div>
      </Slider>
    </div>
  );
};

export default User2;
