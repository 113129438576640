import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import "./GetOrder.css";
import NavbarTop from "../../Navbar/NavbarTop";
import { UserContext } from "../../../UserContext";
import socket from "../../../socket";
import audioFile from "./app ko ek order pratap hua . thank you.mp3";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";

function GetOrders() {
  console.log(process.env.REACT_APP_ADD_RES_INFO);
  const { token } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [date, setDate] = useState(new Date());
  const [sendToken, setSendToken] = useState(token);
  const [dataLoad, setDataLoad] = useState(false);

  const playSong = () => {
    const audioElement = new Audio(audioFile);
    audioElement.play();
    audioElement.addEventListener("ended", () => {
      audioElement.pause();
      audioElement.removeEventListener("ended", () => {});
    });
  };

  const loadOrders = async () => {
    if (socket) {
      socket.emit("getOrders", {
        token: Cookies.get("userToken"),
        date: date.toISOString(),
      });
    }
    // }
  };

  useEffect(() => {
    socket.on("getOrdersError", (data) => {
      console.log(data);
    });
    socket.on("getOrdersSuccess", (data) => {
      setOrders(data);
      console.log(data.length);
      setDataLoad(true);
    });

    socket.on("new-order-coming", (data) => {
      console.log("Received new order:", data);
      loadOrders();
      playSong();
      setDataLoad(true);
      // alert("new order");
    });
  });

  useEffect(() => {
    loadOrders();
  }, [date]);

  const handleDateChange = (e) => {
    const newDate = new Date(e.target.value);
    setDate(newDate);
  };

  return (
    <div className="getOrdersHead">
      <div className="sec1">
        <div className="OrderTitle">
          <span className="titleGetOrders">Orders</span>
          <input
            type="date"
            value={date.toISOString().split("T")[0]}
            onChange={handleDateChange}
          />
        </div>
      </div>
      <div className="headGetOrders">
        <div>
          <div>
            <div>
              <div className="headH">
                {dataLoad ? (
                  orders.length > 0 ? (
                    orders.reverse().map((orderItem, index) => (
                      <div className="headO" key={index}>
                        <div className="tabOrderNo">
                          <p className="tabNo">Table No: {orderItem.tableNo}</p>
                          <p className="orderNo">Order {index + 1}</p>
                        </div>
                        {/* <h4>Order Items:</h4> */}
                        <ul>
                          {Array.isArray(orderItem.items) && (
                            <ul>
                              {orderItem.items.map((item, itemIndex) => (
                                <li key={itemIndex}>
                                  <p>
                                    {item.quantity}&nbsp; X &nbsp;{item.iName}
                                    &nbsp;({item.category})
                                  </p>
                                  {/* <p>Price: {item.price}</p> */}
                                </li>
                              ))}
                            </ul>
                          )}
                        </ul>
                        <p>Total Price: {orderItem.totalPrice}</p>
                      </div>
                    ))
                  ) : (
                    <div>
                      <h5>Order Not Available</h5>
                    </div>
                  )
                ) : (
                  <div
                    className="growSpinnerHead"
                    style={{ display: dataLoad ? "none" : "" }}
                  >
                    <Spinner
                      className="growSpinner"
                      style={{
                        color: "#ff5722",
                      }}
                      animation="grow"
                      role="status"
                    ></Spinner>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GetOrders;
