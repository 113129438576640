import React, { useState, useContext } from 'react';
import NavbarTop from '../../Navbar/NavbarTop';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import axios from 'axios';
import { UserContext } from '../../../UserContext';

function Register() {
    const { setToken } = useContext(UserContext);
    const [showPassword, setShowPassword] = useState(false);
    const [restaurantInfo, setRestaurantInfo] = useState({
        "rest": {
            "nameOfcus": "",
            "email": "",
            "pass": ""
        },
        "menu": [],
        "orders": []
    }
    )

    function validateEmail(email) {
        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
        return emailPattern.test(email);
    }


    function validatePassword(password) {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!passwordPattern.test(password)) {
            const errors = [];
            if (!/(?=.*[a-z])/.test(password)) {
                errors.push(' one lowercase letter,');
            }
            if (!/(?=.*[A-Z])/.test(password)) {
                errors.push(' one uppercase letter,');
            }
            if (!/(?=.*\d)/.test(password)) {
                errors.push(' one digit,');
            }
            if (!/(?=.*[@$!%*?&])/.test(password)) {
                errors.push(' one special character,');
            }
            if (password.length < 8) {
                errors.push(' length of 8 characters');
            }

            return `Password must have at Least: ${errors.join(' ')}`;
        }

        return true;
    }

    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault();
        const isEmailValid = validateEmail(restaurantInfo.rest.email);
        const isPasswordValid = validatePassword(restaurantInfo.rest.pass);
        console.log(isEmailValid);
        console.log(isPasswordValid);
        if (isEmailValid !== true && isPasswordValid !== true) {
            alert(`Please Enter Correct Email and ${isPasswordValid}`)
        }
        else if (isEmailValid !== true) {
            alert("Please Enter Correct Email")
        }
        else if (isPasswordValid !== true) {
            alert("Please Enter Correct Password");
        } else if (isEmailValid === true && isPasswordValid === true) {
            try {
                restaurantInfo.rest.email = await restaurantInfo.rest.email.toLowerCase();
                const response = await axios.post('http://localhost:5000/register', { restaurantInfoData: restaurantInfo }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setToken(response.data.token);
                console.log(response.data.token);

                if (response.status === 200) {
                    console.log('Restaurant info submitted successfully');
                    alert('Restaurant info submitted successfully');
                    navigate("/CafeRegister");
                } else if (response.status === 201) { // Check for 409 Conflict status
                    // Display a custom alert message
                    console.log("manish");
                    alert("You are a registered user");
                } else {
                    console.log('Error submitting restaurant info');
                    alert('Error submitting restaurant info');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }

    };;

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'pass') {
            setRestaurantInfo((prevRestaurantInfo) => ({
                ...prevRestaurantInfo,
                rest: {
                    ...prevRestaurantInfo.rest,
                    [name]: value,
                },
            }));
        } else {
            setRestaurantInfo((prevRestaurantInfo) => ({
                ...prevRestaurantInfo,
                rest: {
                    ...prevRestaurantInfo.rest,
                    [name]: value,
                },
            }));
        }
    };

    // const handlePasswordChange = (event) => {
    //     setPassword(event.target.value);
    // };

    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const navigateLogin = () => {
        navigate('/login');
    };

    return (
        <div className='registerPageHead'>
            <div className='navRegister'>
                <NavbarTop />
            </div>
            <div className="registerContainer">
                <div className="registerHead">
                    <h2 className='registerHeading'>Register</h2>
                    <form onSubmit={handleRegister} className="RegisterForm">
                        <div className="form-group">
                            <label htmlFor="nameOfcus">Name</label>
                            <input
                                type="text"
                                id="nameOfcus"
                                name="nameOfcus"
                                placeholder='Enter your name'
                                required
                                value={restaurantInfo.rest.nameOfcus}
                                onChange={handleInputChange}
                            />

                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder='Enter Email'
                                required
                                value={restaurantInfo.rest.email}
                                onChange={handleInputChange}
                            />
                            <div className='emailCheck'>
                                {(validateEmail(restaurantInfo.rest.email) === false) && restaurantInfo.rest.email !== "" ? <p>Enter a valid mail</p> : <p></p>}

                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pass">Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className='InputPassword'
                                id="pass"
                                name="pass"
                                placeholder='Enter Password'
                                required
                                value={restaurantInfo.rest.pass}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className='showPass'>
                            <input
                                type="checkbox"
                                id="showPassword"
                                checked={showPassword}
                                onChange={handleToggleShowPassword}
                            />
                        </div>
                        <div>
                            <button type="submit" className="RegisterButton" >Register</button>
                        </div>
                        <div>
                            <button type="button" className="alreadyRegister" onClick={navigateLogin}>Already Registered User ?</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register;

