import React, { useState } from "react";

const AudioCheck = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <h2>Audio Player</h2>
      <audio controls>
        <source
          src={require("./app ko ek order pratap hua . thank you.mp3")}
          type="audio/mp3"
        />
        Your browser does not support the audio element.
      </audio>
      <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button>
    </div>
  );
};

export default AudioCheck;
