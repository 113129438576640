import React, { useEffect } from "react";
import "./RefundCanEndUser.css";
import NavbarTop from "../../../Navbar/NavbarTop";
import Footer from "../../Footer/Footer";

function RefundCanEndUser() {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);
  return (
    <div className="RefundEndUserHead">
      <div>
        <NavbarTop />
      </div>
      <div className="container">
        <h3>Privacy Policy:</h3>
        <p>
          At this restaurant, we are committed to protecting your privacy. This
          Privacy Policy outlines how we collect, use, disclose, and safeguard
          your information when you use our digital menu and payment services.
        </p>

        <h3>Information We Collect:</h3>
        <ul>
          <li>
            We only use your name (for delivering your order in the restaurant).
          </li>
          <li>
            We ask for the table number (for delivering your order in the
            restaurant).
          </li>
          <li>
            We may ask for suggestions for food (e.g., food must be hot, food
            must be spicy).
          </li>
          <li>
            You proceed with your payment directly from your RefundCanEndUser or
            sometimes (scan QR for payment).
          </li>
          <li>
            Many times we need a phone number for payment when you use our
            services.
          </li>
          <li>Sometimes we take suggestions for our restaurant.</li>
        </ul>

        <p>
          We use the information we collect to provide and improve our services,
          process transactions, and communicate with you about your orders.
        </p>

        <h3>Data Security:</h3>
        <p>
          We employ industry-standard security measures to protect your
          information from unauthorized access, disclosure, alteration, or
          destruction. However, no method of transmission over the internet or
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </p>

        <h3>Third-Party Services:</h3>
        <p>
          We use Razorpay to proceed with the transactions. We may use
          third-party services, such as payment processors and analytics
          providers, to facilitate our services. These third parties may have
          their own privacy policies governing the use of your information.
        </p>

        <h3>Updates to Privacy Policy:</h3>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting on our
          website. By using our digital menu and payment services, you consent
          to the collection and use of your information as described in this
          Privacy Policy.
        </p>

        <h1>Terms and Conditions:</h1>

        <h3>Service Availability:</h3>
        <ul>
          <li>
            We strive to provide uninterrupted access to our services, but we
            cannot guarantee that our services will always be available or
            error-free.
          </li>
          <li>Our services are not available when the restaurant is closed.</li>
          <li>
            We reserve the right to suspend, terminate, or modify our services
            at any time without prior notice.
          </li>
        </ul>

        <h3>User Responsibilities:</h3>
        <p>
          You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account.
        </p>

        <h3>Prohibited Activities:</h3>
        <p>
          You agree not to engage in any illegal or unauthorized activities when
          using our services, including but not limited to fraud, hacking, or
          unauthorized access to our systems.
        </p>

        <h3>Intellectual Property:</h3>
        <p>
          All content and materials provided through our services, including the
          digital menu interface and payment processing features, are the
          property of this restaurant and are protected by intellectual property
          laws.
        </p>

        <h1>Refund and Cancellation Policy:</h1>

        <h3>Cancellation:</h3>
        <p>
          The user and end-user (customer of the small restaurant and cafe) when
          doing an order, you are not allowed to cancel the order after payment
          because of maintaining food procedures, quality, and food wastage.
        </p>
        <p>
          If the user sends the payment and only the payment process only up to
          capture then within 24 hours payment coming in settlement state to the
          owner of the restaurant.
        </p>
        <p>
          Capture State also visible to the owner and you are requested to give
          the order.
        </p>

        <h3>Refunds:</h3>
        <p>
          If the payment does not go up to the captured state, then there is no
          refund or no order in that case.
        </p>
        <p>
          Refunds will be provided only in specific circumstances and at the
          discretion of our management.
        </p>
        <p>
          Refund requests must be submitted in writing to our support team,
          stating the reason for the request.
        </p>
        <p>
          Refunds will not be provided for partial subscription periods that
          have already been used.
        </p>

        <h1>Disclaimer:</h1>
        <p>
          This restaurant reserves the right to modify, update, or discontinue
          any aspect of our services at any time without prior notice. By using
          our digital menu and payment services, you acknowledge and agree to
          abide by the terms and conditions outlined above. If you have any
          questions or concerns, please contact our reception.
        </p>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default RefundCanEndUser;
