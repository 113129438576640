import React, { useState } from "react";
import axios from "axios";

const AdminRegister = () => {
  const [adminId, setAdminId] = useState("");
  const [pass, setPass] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://localhost:5000/admin-register",
        {
          restaurantInfoData: {
            adminId: adminId,
            pass: pass,
          },
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage("An error occurred during registration");
      console.error("Error during registration:", error);
    }
  };

  return (
    <div>
      <h2>Admin Registration Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={adminId}
            onChange={(e) => setAdminId(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            required
          />
        </div>
        <button type="submit">Register</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AdminRegister;
