import React, { useState, useContext, useEffect } from "react";
import "./Kyc.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../UserContext";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";

function Kyc() {
  const Navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [penKycsRes, setPenKycsRes] = useState([]);
  const [penDataLoadStatus, setPenDataLoadStatus] = useState(false);
  const [penSpecDataLoadStatus, setSpecDataLoadStatus] = useState(false);
  const [account, setAccount] = useState({
    nameOfCafe: "",
    bankName: "",
    accNo: "",
    ifsc: "",
    cusName: "",
    kycRegDate: null,
    kycVerifiedDate: null,
    kycVerifiedId: "",
  });
  const [kycVerifiedId, setKycVerifiedId] = useState("");
  const [razDesId, setRazDesId] = useState("");
  const [noOfReg, setNoOfReg] = useState();
  const handleClose = () => {
    setShow(false);
  };

  const handleLoadPendingInfo = async () => {
    setPenDataLoadStatus(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_KYC_PENDING_DATA_LOAD,
        {},
        {
          headers: {
            Authorization: `bearer ${Cookies.get("adminLoginToken")}`, // Assuming 'token' contains your authorization token
          },
        }
      );

      const data = response.data;
      if (response.status === 200) {
        console.log(response.data);
        setPenKycsRes(response.data);
      } else {
        console.log("Unexpected status code:", response.status);
      }
      setPenDataLoadStatus(false);
    } catch (error) {
      console.error("An error occurred", error);
    }
  };
  useEffect(() => {
    handleLoadPendingInfo();
  }, []);

  const handleLoadPendingSpecificInfo = async (noOfReg) => {
    console.log(noOfReg);
    setNoOfReg(noOfReg);
    setSpecDataLoadStatus(true);
    try {
      const response = await axios.post(
        // process.env.REACT_APP_KYC_PENDING_SPECIFIC_DATA_LOAD,
        "http://localhost:5000/kyc-pending-specific-data-load",
        { noOfReg: noOfReg },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("adminLoginToken")}`,
          },
        }
      );

      const data = response.data;
      if (response.status === 200) {
        console.log(response.data);
        console.log(data[0].accNo);
        setAccount({
          bankName: data[0].bankName,
          accNo: data[0].accNo,
          ifsc: data[0].ifsc,
          cusName: data[0].cusName,
          kycRegDate: data[0].kycRegDate,
          kycVerifiedDate: data[0].kycVerifiedDate,
          kycVerifiedId: data[0].kycVerifiedId,
        });
        setShow(true);
        console.log(account);
      } else if (response.status === 201) {
        console.log(response.data.message);
      } else {
        console.log("Unexpected status code:", response.status);
      }
      setSpecDataLoadStatus(false);
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const handleSaveKycVerifiedId = async () => {
    console.log(kycVerifiedId);
    console.log(noOfReg);
    try {
      const response = await axios.post(
        process.env.REACT_APP_SAVE_KYC_VERIFIED_ID,
        { kycVerifiedId: kycVerifiedId, noOfReg: noOfReg, razDesId: razDesId },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("adminLoginToken")}`,
          },
        }
      );
      const data = response.data;
      if (response.status === 200) {
        console.log(response.data.message);
        alert(response.data.message);
      } else if (response.status === 200) {
        console.log(data.message);
      } else {
        console.log("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  return (
    <div
      className="kycAdminHead"
      style={{
        opacity: penDataLoadStatus || penSpecDataLoadStatus ? "0.7" : "1",
      }}
    >
      <div
        className="growSpinnerHead"
        style={{
          display: penDataLoadStatus || penSpecDataLoadStatus ? "" : "none",
        }}
      >
        <Spinner
          className="growSpinner"
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner>
      </div>
      <h1 className="kycTitle">Kyc's Pendings</h1>
      <div className="kycPendingSection">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Cafe Name</th>
              <th>Verify</th>
            </tr>
          </thead>
          <tbody>
            {penKycsRes &&
              penKycsRes.map((item, index) => (
                <tr key={index}>
                  <td>{item.noOfReg}</td>
                  <td>{item.nameOfCafe}</td>
                  <td>
                    <button
                      className="verifyButton"
                      onClick={() =>
                        handleLoadPendingSpecificInfo(item.noOfReg)
                      }
                    >
                      Verify
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div>
        <Modal
          show={show}
          onHide={handleClose}
          className="d-flex justify-content-center align-items-center kycModal"
        >
          <Modal.Body className="kycModalBody">
            <h4>Update Kyc Status</h4>
            <div className="modalItems">
              <div className="modalItem">
                <label className="">KYC Registration Date</label>
                <input
                  type="text"
                  defaultValue={account.kycRegDate}
                  className=""
                />
              </div>
              <div className="modalItem">
                <label className="">Owner Name</label>
                <input
                  type="text"
                  defaultValue={account.cusName}
                  className=""
                />
              </div>
              <div className="modalItem">
                <label className="">Bank Name</label>
                <input
                  type="text"
                  defaultValue={account.bankName}
                  className=""
                />
              </div>
              <div className="modalItem">
                <label className="">Account Number</label>
                <input type="text" defaultValue={account.accNo} className="" />
              </div>
              <div className="modalItem">
                <label className="">IFSC Code</label>
                <input type="text" defaultValue={account.ifsc} className="" />
              </div>
              <div className="modalItem">
                <label className="">KYC Verified ID</label>
                <input
                  type="text"
                  value={kycVerifiedId}
                  onChange={(event) => setKycVerifiedId(event.target.value)}
                  className="kycVerifiedId"
                />
              </div>
              <div className="modalItem">
                <label className="">Destination Id</label>
                <input
                  type="text"
                  value={razDesId}
                  onChange={(event) => setRazDesId(event.target.value)}
                  className="kycVerifiedId"
                />
              </div>
              <div className="modalItem submitKycBtn">
                <button onClick={handleSaveKycVerifiedId}>click me</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div></div>
      <div></div>
    </div>
  );
}
export default Kyc;
