import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../UserContext";
import axios from "axios";
import "./Home.css";
import Chart from "react-apexcharts";
import Spinner from "react-bootstrap/Spinner";
import StaticChart from "./static-month-order-chart.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

function Home() {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);

  const [month, setMonth] = useState(2);
  const [year, setYear] = useState(2024);

  const [totalMonthOrders, setTotalMonthOrders] = useState();
  // const [accStatus, setAccStatus] = useState();
  const [kycStatus, setKycStatus] = useState();
  const [KycVerifyStatus, setKycVerifyStatus] = useState(false);
  const [kycShow, setKycShow] = useState(true);

  const [orderPerDay, setOrderPerDay] = useState(); // this is object in key value pain to show each day number of day
  const [earningPerDay, setEarningPerDay] = useState(); // this is object in key value pain to show earning of each day

  const [countOrderPerDay, setCountOrderPerDay] = useState(0); // this show no of order in today
  const [countOrderPerMonth, setCountOrderPerMonth] = useState(0); // this sow no of order in a month

  const [countEarningPerDay, setCountEarningPerDay] = useState(0); //this show no of earning in a day
  const [countEarningPerMonth, setCountEarningPerMonth] = useState(0); // this show no of earning in a month

  const [orderDataLoad, setOrderDataLoad] = useState(false);
  const [todayDate, setTodayDate] = useState({
    date: 0,
    month: 0,
    year: 0,
  });
  const [selectOrderEarning, setSelectOrderEarning] = useState("1");
  const noOfDayInMonth = {
    1: 31,
    2: 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    series: [],
    xaxis: {
      title: {
        text: "Day of the Month",
      },
    },
    yaxis: {
      title: {
        text: selectOrderEarning === "1" ? "Number of Orders" : "Earning",
      },
      decimalsInFloat: 0,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 5,
      strokeColors: "#fff",
      strokeWidth: 2,
      colors: ["#008FFB"],
    },
  };

  if (orderDataLoad) {
    options.series.push({
      name: "Orders",
      data: Object.entries(
        selectOrderEarning === "1" ? orderPerDay : earningPerDay
      ).map(([day, orders]) => ({
        x: parseInt(day), // Convert the day to an integer if necessary
        y: orders,
      })),
    });
  } else {
    options.xaxis.categories = Array.from({ length: 30 }, (_, i) =>
      (i + 1).toString()
    );
    options.series.push({
      name: "Orders",
      data: Array.from({ length: 30 }, (_, i) => ({ x: i + 1, y: 0 })),
    });
  }

  const handleOrderMonthData = async (monthJi, yearJi) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_TOTAL_MONTH_ORDERS,
        // "http://localhost:5000/orders_month_data",
        { month: monthJi, year: yearJi },
        {
          headers: {
            // Authorization: `bearer ${token}`,
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // const data = response.data;
      const { arrOfOrders, todayDate } = response.data;

      if (response.status === 200) {
        setTotalMonthOrders(arrOfOrders);
        const dateObject = new Date(todayDate);
        const date = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();
        setTodayDate({
          date: date,
          month: month,
          year: year,
        });
        setOrderDataLoad(true);
      } else {
        alert("something went wrong");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };

  const handleLoadAccStatus = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_ACC_STATUS,
        {},
        {
          headers: {
            // Authorization: `bearer ${token}`,
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data == "2") {
          setKycStatus("Your KYC is not complete");
        } else if (response.data === "3") {
          setKycStatus("Your KYC Verification is Under Process");
        } else if (response.data === "4") {
          setKycVerifyStatus(true);
          setKycStatus("Your KYC Verification is Successfully Verified");
        }
      } else {
        alert("something went wrong");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    handleLoadAccStatus();
    handleOrderMonthData(month, year);
  }, []);

  useEffect(() => {
    const totalEarningsPerDay = {};
    let daysInMonth;
    if (month === todayDate.month) {
      daysInMonth = todayDate.date;
    } else {
      daysInMonth = noOfDayInMonth[month];
    }

    const currentDate = new Date(); // Current date for the month
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
    for (let day = 1; day <= daysInMonth; day++) {
      const dateString = `${parseInt(day.toString().padStart(2, "0"))}`;
      totalEarningsPerDay[dateString] = 0;
    }

    // Update earnings based on totalMonthOrders
    if (totalMonthOrders && totalMonthOrders.length > 0) {
      totalMonthOrders.forEach((order) => {
        const orderDate = new Date(order.oDate)
          .toISOString()
          .split("T")[0]
          .split("-")[2]; // Extract DD
        const day = parseInt(orderDate); // Parse as integer to remove leading zero
        totalEarningsPerDay[day] =
          (totalEarningsPerDay[day] || 0) + parseInt(order.tPrice);
      });
    }

    setEarningPerDay(totalEarningsPerDay);
  }, [totalMonthOrders]);

  useEffect(() => {
    const updatedOrdersPerDay = {};
    // const daysInMonth = noOfDayInMonth[month];
    let daysInMonth;
    if (month === todayDate.month) {
      daysInMonth = todayDate.date;
    } else {
      daysInMonth = noOfDayInMonth[month];
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    for (let day = 1; day <= daysInMonth; day++) {
      const dateString = `${day}`;
      updatedOrdersPerDay[dateString] = 0;
    }

    if (totalMonthOrders && totalMonthOrders.length > 0) {
      totalMonthOrders.forEach((order) => {
        const orderDate = new Date(order.oDate)
          .toISOString()
          .split("T")[0]
          .split("-")[2]; // Extract DD
        const day = parseInt(orderDate); // Parse as integer to remove leading zero
        updatedOrdersPerDay[day] += 1;
      });
    }

    setOrderPerDay(updatedOrdersPerDay);
  }, [totalMonthOrders]);

  useEffect(() => {
    let totalNumberOfOrder = 0;
    let noOfOrderPerDay = 0;
    if (orderPerDay) {
      for (let day in orderPerDay) {
        totalNumberOfOrder += orderPerDay[day];
        if (parseInt(day) === todayDate.date) {
          noOfOrderPerDay += parseInt(orderPerDay[day]);
        }
      }
      setCountOrderPerDay(parseInt(orderPerDay[todayDate.date]));
    }

    setCountOrderPerMonth(totalNumberOfOrder);
  }, [orderPerDay]);

  useEffect(() => {
    let totalNumberOfEarning = 0;
    let perDayEarning = 0;
    if (earningPerDay) {
      for (let day in earningPerDay) {
        totalNumberOfEarning += earningPerDay[day];
        if (day === todayDate.date) {
          perDayEarning += earningPerDay[day];
        }
      }
      setCountEarningPerDay(earningPerDay[todayDate.date]);
    }
    setCountEarningPerMonth(totalNumberOfEarning);
  }, [earningPerDay]);

  const [selectedMonth, setSelectedMonth] = useState(2);
  const handleMonthChange = async (event) => {
    setSelectedMonth(parseInt(event.target.value));
    setMonth(parseInt(event.target.value));
    setOrderDataLoad(false);
    handleOrderMonthData(parseInt(event.target.value), year);
  };

  const [selectedYear, setSelectedYear] = useState(2024); // Set default year to 2021

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
    setYear(parseInt(event.target.value));
    setOrderDataLoad(false);
    handleOrderMonthData(month, parseInt(event.target.value));
  };

  return (
    <div className="homeHead" style={{ opacity: orderDataLoad ? "1" : "0.5" }}>
      <div
        className="DashHomeSpinner"
        style={{ display: orderDataLoad ? "none" : "" }}
      >
        <Spinner
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner>
      </div>
      <div className="home">
        <p className="welcomeMessage">Welcome to the Ezzorder</p>
        <div className="kycHomeHead" style={{ display: kycShow ? "" : "none" }}>
          <FontAwesomeIcon className="pendingIcon" icon={faHourglassHalf} />
          <p className="kycMessage">{kycStatus}</p>
          <button
            className="kycCompleteButton"
            style={{ display: KycVerifyStatus ? "none" : "" }}
            onClick={() => navigate("/kyc")}
          >
            complete
          </button>
          <button className="crossKyc" onClick={() => setKycShow(false)}>
            X
          </button>
        </div>
        <div className="sec1Home">
          <div className="leftSec1Home">
            <h1 className="currentMonthTitle">Overview</h1>
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="selectMonth"
            >
              <option value={1}>January</option>
              <option value={2}>February</option>
              <option value={3}>March</option>
              <option value={4}>April</option>
              <option value={5}>May</option>
              <option value={6}>June</option>
              <option value={7}>July</option>
              <option value={8}>August</option>
              <option value={9}>September</option>
              <option value={10}>October</option>
              <option value={11}>November</option>
              <option value={12}>December</option>
            </select>

            <select
              value={selectedYear}
              onChange={handleYearChange}
              className="selectYear"
            >
              <option value={2021}>2021</option>
              <option value={2022}>2022</option>
              <option value={2023}>2023</option>
              <option value={2024}>2024</option>
            </select>
          </div>
          <select
            className="selectOrderEarning"
            onChange={(event) => setSelectOrderEarning(event.target.value)}
          >
            <option value="1">orders</option>
            <option value="2">Earning</option>
          </select>
        </div>
        <div className="totalOverview">
          <div className="items item1">
            <p className="col1 col">
              {selectOrderEarning === "1"
                ? "Total Month Orders"
                : "Total Month Earning"}
            </p>
            <p className="col2 col">
              {selectOrderEarning === "1"
                ? countOrderPerMonth
                : countEarningPerMonth}
            </p>
          </div>
          <div className="items item2">
            <p className="col">
              {selectOrderEarning === "1" ? "Today Orders" : "Today Earning"}
            </p>
            <p className="col2 col">
              {selectOrderEarning === "1"
                ? countOrderPerDay
                : countEarningPerDay}
            </p>
          </div>
        </div>

        <div>
          <div style={{ border: "1px solid rgb(219, 216, 216)" }}>
            <Chart
              options={options}
              series={options.series}
              type="line"
              height={300}
              width={"100%"}
            />
          </div>
        </div>
      </div>
     </div>
  );
}
export default Home;
