import React, { useState, useContext, useEffect } from "react";
import "./LoginAndSignup.css";
import NavbarTop from "../../Navbar/NavbarTop";
import Log from "./img/log.svg";
import Register from "./img/register.svg";
import axios from "axios";
import Cookies from "js-cookie";

import { UserContext } from "../../../UserContext";
import { useNavigate } from "react-router-dom";
import socket from "../../../socket";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
function LoginAndSignup() {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [passVisible, setPassVisible] = useState(false);

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [condi, setCondi] = useState(false);
  const [noti, setNoti] = useState(false);
  const [loginSucess, setloginSucess] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState(false);
  const [otp, setOtp] = useState(false);
  const [otpSendStatus, setOtpSendStatus] = useState(false);
  const [otpSendSucess, setOtpSendSucess] = useState(false);
  const [verifySendStatus, setVerifySendStatus] = useState(false);
  const [verifySucess, setVerifySucess] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
    setEmail("");
    setUserName("");
    setPassword("");
    setMessage("");
  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
    setEmail("");
    setUserName("");
    setPassword("");
    setMessage("");
  };

  const navigate = useNavigate();
  const { token, setToken } = useContext(UserContext);


  // const handleLogin = async () => {
  //   console.log("yes login");
  //   setloginSucess(true);

  //   try {
  //     socket.emit("login", { email, password });

  //     socket.on("login-status", async (data) => {
  //       setToken("");
  //       if (data.status) {
  //         setToken(data.token);
  //         if (data.currentFormStatus === "1") {
  //           navigate("/CafeRegister");
  //         } else if (data.currentFormStatus === "2") {
  //           navigate("/kyc");
  //         } else {
  //           navigate("/dashboard");
  //         }
  //       }
  //       if (data.status) {
  //         setloginSucess(true);
  //       } else {
  //         setloginSucess(false);
  //       }
  //       console.log(data.status);
  //       setMessage(data.message);
  //     });
  //   } catch (error) {
  //     console.error("Error logging in:", error);
  //     setMessage("An error occurred");
  //   }
  // };

  // const userToken = Cookies.get("userToken");

  const handleLogin = async () => {
    setloginSucess(true);
    // "http://localhost:5000/login2";
    try {
      const response = await axios.post(process.env.REACT_APP_LOGIN2, {
        email: email,
        password: password,
      });
      if (response.status === 200) {
        const { token, currentFormStatus, message } = response.data;
        setMessage(message + " " + currentFormStatus);
        console.log(message);
        Cookies.set("userToken", token, { expires: 7 });
        if (currentFormStatus === "1") {
          navigate("/CafeRegister");
        } else if (currentFormStatus == "2") {
          navigate("/kyc");
        } else if (currentFormStatus == "3") {
          navigate("/dashboard/home");
        } else {
          navigate("/dashboard/home");
        }
      } else if (response.status === 201) {
        console.log(response.data.message);
        setMessage(response.data.message);
      } else {
        console.log("Unexpected status code:", response.status);
      }
      setloginSucess(false);
    } catch (error) {
      console.error("Error logging in:", error);
      setMessage("An error occurred");
    }
  };

  //register
  function validateEmail(email) {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
    return emailPattern.test(email);
  }

  function validatePassword(password) {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordPattern.test(password)) {
      const errors = [];
      if (!/(?=.*[a-z])/.test(password)) {
        errors.push(" one lowercase letter,");
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        errors.push(" one uppercase letter,");
      }
      if (!/(?=.*\d)/.test(password)) {
        errors.push(" one digit,");
      }
      if (!/(?=.*[@$!%*?&])/.test(password)) {
        errors.push(" one special character,");
      }
      if (password.length < 8) {
        errors.push(" length of 8 characters");
      }

      return `Password must have at Least: ${errors.join(" ")}`;
    }

    return true;
  }

  const handleSubmit = async () => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);
    if (isEmailValid !== true && isPasswordValid !== true) {
      setMessage(`Please Enter Correct Email and ${isPasswordValid}`);
    } else if (isEmailValid !== true) {
      setMessage("Please Enter Correct Email");
    } else if (isPasswordValid !== true) {
      setMessage("Please Enter Correct Password");
    } else if (isEmailValid === true && isPasswordValid === true) {
      if (isSignUpMode) {
        if (!condi && !noti) {
          setMessage("Accept Conditon And Notification");
        } else if (!condi) {
          setMessage("Condition Not Accepted");
        } else if (!noti) {
          setMessage("Notification Not Accepted");
        } else {
          handleRegister();
        }
      } else {
        handleLogin();
      }
    }
  };

  const handleRegister = async () => {
    // event.preventDefault();
    console.log("yes");
    setSignUpStatus(true);
    if (otpSendSucess && verifySucess) {
      try {
        let lowerCaseEmail = await email.toLowerCase();
        let restaurantInfo = await {
          rest: {
            nameOfcus: userName,
            email: lowerCaseEmail,
            pass: password,
          },
          menu: [],
          orders: [],
          status: "1",
        };
        const response = await axios.post(
          process.env.REACT_APP_REGISTER,
          { restaurantInfoData: restaurantInfo },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setToken(response.data.token);
        if (response.status === 200) {
          alert("Please Login");
          setIsSignUpMode(!isSignUpMode);
          setMessage("");
        } else if (response.status === 201) {
          setMessage("You are a registered user");
        } else {
          console.log("Error submitting restaurant info");
          alert("Error submitting restaurant info");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setMessage("Please Verify Your Email ! ");
    }
    setSignUpStatus(false);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
    setMessage("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setMessage("");
  };

  const handlePasswordchange = (e) => {
    setPassword(e.target.value);
    setMessage("");
  };
  const togglePasswordVisibility = () => {
    setPassVisible(!passVisible);
  };

  const handleCheckboxCondi = () => {
    setCondi(!condi);
  };

  const handleCheckboxNoti = () => {
    setNoti(!noti);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
    setMessage("");
  };

  const handleOtpSend = async () => {
    const isEmailValid = validateEmail(email);
    if (isEmailValid) {
      setOtpSendStatus(true);
      try {
        const response = await axios.post(process.env.REACT_APP_SEND_OTP, {
          email: email,
        });
        if (response.status == 200) {
          // alert(response.data.message);
          setOtpSendStatus(false);
          setOtpSendSucess(true);
          setMessage(response.data.message);
        } else if (response.status == 201) {
          setOtpSendStatus(false);
          setMessage(response.data.message);
        }
      } catch (error) {
        alert("Error in cafe registration");
        console.error("Error:", error);
      }
    } else {
      setMessage("Please Enter Correct Email");
    }
  };

  const handleOtpVerify = async () => {
    setVerifySendStatus(true);

    try {
      const response = await axios.post(process.env.REACT_APP_VERIFY_OTP, {
        email: email,
        otp: parseInt(otp),
      });
      if (response.status == 200) {
        // alert(response.data.message);
        setVerifySendStatus(false);
        setVerifySucess(true);
        setMessage(response.data.message);
        console.log(response.data.message);
      } else if (response.status == 201) {
        setMessage(response.data.message);
        alert(response.data.message);
        setVerifySendStatus(false);
      } else {
        alert("some error");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };
  return (
    <div
      className="loginSignUpTopHead"
      style={{
        opacity:
          loginSucess || otpSendStatus || verifySendStatus || signUpStatus
            ? "0.7"
            : "1",
      }}
    >
      <div>
        <NavbarTop />
      </div>
      <div
        className={`container1 loginSignUpHead ${
          isSignUpMode ? "sign-up-mode" : ""
        }`}
      >
        <div
          className="growSpinnerHead"
          style={{
            display:
              loginSucess || otpSendStatus || verifySendStatus || signUpStatus
                ? ""
                : "none",
          }}
        >
          <Spinner
            className="growSpinner"
            style={{
              color: "#ff5722",
            }}
            animation="grow"
            role="status"
          ></Spinner>
        </div>
        <div className="forms-container">
          <div className="signin-signup">
            <div
              action="#"
              className={`${isSignUpMode} ? "sign-up-form" : "sign-in-form" loginForm`}
            >
              <h2 className="title">{isSignUpMode ? "Sign up" : "Sign in"}</h2>
              <p className="message">{message}</p>
              {/* {isSignUpMode && (
                <div className="input-field">
                  <i className="fas">
                    <FontAwesomeIcon className="fas" icon={faUser} />
                  </i>
                  <input
                    type="text"
                    placeholder="Username"
                    value={userName}
                    onChange={handleUserNameChange}
                  />
                </div>
              )} */}
              <div className="emailInputHead">
                <div
                  className="emailInput"
                  style={{ width: verifySucess ? "90%" : "95%" }}
                >
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div
                  className="emailCheckSymbol"
                  style={{ display: verifySucess ? "" : "none" }}
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      color: "green",
                    }}
                  />
                </div>
              </div>

              <div
                className="otpHead"
                style={{
                  display: isSignUpMode && email && !verifySucess ? "" : "none",
                }}
              >
                <input
                  className="otpInput"
                  placeholder="Enter OTP"
                  style={{ display: otpSendSucess ? "" : "none" }}
                  onChange={handleOtpChange}
                ></input>
                <button
                  onClick={otpSendSucess ? handleOtpVerify : handleOtpSend}
                >
                  {otpSendSucess ? "Verify" : "Send OTP"}
                </button>
              </div>

              <div className="passHeadHead">
                <div className="passHead1">
                  <div className="passInput1">
                    <input
                      type={passVisible ? "text" : "password"}
                      className=""
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordchange}
                    />
                  </div>
                  <div className="passCheck1">
                    <input
                      type="checkbox"
                      className=""
                      name="myCheckbox"
                      onChange={togglePasswordVisibility}
                    />
                  </div>
                </div>
              </div>

              {isSignUpMode ? (
                <div className="conditionsAndNoti">
                  <div className="conditons">
                    <input
                      type="checkbox"
                      className="checkbo"
                      checked={condi}
                      onChange={handleCheckboxCondi}
                    />
                    I agree to the
                    <button onClick={() => navigate("/terms-and-conditions")}>
                      Terms And Condtions
                    </button>
                    and
                    <button onClick={() => navigate("/privacy-policy")}>
                      Privacy Policy
                    </button>
                  </div>
                  <div className="noti">
                    <input
                      type="checkbox"
                      className="checkbo"
                      checked={noti}
                      onChange={handleCheckboxNoti}
                    />
                    Notify me about new features and special offers
                  </div>
                </div>
              ) : (
                <div className="forgotPassHead">
                  <button onClick={() => navigate("/forgot-pass")}>
                    Forgot Password ?
                  </button>
                </div>
              )}

              <button
                className={`btn submitBtn ${
                  isSignUpMode ? "" : "solid"
                } submitBtn`}
                onClick={handleSubmit}
                value={isSignUpMode ? "Sign up" : "Login"}
              >
                submit
              </button>
              {/* {isSignUpMode ? "Sign up" : "Login"} */}
              {/* </input> */}

              <p className="social-text">
                Or Sign {isSignUpMode ? "up" : "in"} with social platforms
              </p>
              <div className="social-media">
                <a href="#" className="social-icon">
                  {/* <FontAwesomeIcon className="fab" icon={faFacebookF} /> */}
                </a>
                <a href="#" className="social-icon">
                  {/* <FontAwesomeIcon icon={faTwitter} className="fab" /> */}
                </a>
                <a href="#" className="social-icon">
                  {/* <FontAwesomeIcon icon={faGoogle} className="fab" /> */}
                </a>
                <a href="#" className="social-icon">
                  {/* <FontAwesomeIcon icon={faLinkedinIn} className="fab" /> */}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="panels-container">
          <div className="panel left-panel">
            <div className="content">
              <h3>New here ?</h3>
              <p>
                Don't have an account yet ? Sign up and get access to all our
                services.
              </p>
              <button
                className="btn transparent"
                id="sign-up-btn"
                onClick={handleSignUpClick}
              >
                Sign up
              </button>
            </div>
            {/* Add your left panel image */}
            <img src={Log} className="image" alt="" />
          </div>
          <div className="panel right-panel">
            <div className="content">
              <h3>One of us ?</h3>
              <p>
                Already made an account here? Sign in and get access to all our
                services.
              </p>
              <button
                className="btn transparent"
                id="sign-in-btn"
                onClick={handleSignInClick}
              >
                Sign in
              </button>
            </div>
            {/* Add your right panel image */}
            <img src={Register} className="image" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginAndSignup;
