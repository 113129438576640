import React, { useEffect } from "react";
import "./RefundCanUser.css";
import NavbarTop from "../../../Navbar/NavbarTop";
import Footer from "../../Footer/Footer";

function RefundCanUser() {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);
  return (
    <div className="RUserHead">
      <div>
        <NavbarTop />
      </div>
      <div className="RUserHeadBody container">
        <h3>Cancellation/Refund Policy</h3>
        <p>
          This Cancellation/Refund Policy outlines the guidelines and procedures
          for cancelling orders and requesting refunds on our digital menu and
          payment system. Our platform, developed using MERN Technology
          (MongoDB, Express, React, Node), aims to provide a seamless experience
          for restaurants and customers alike, enhancing convenience and
          efficiency in transactions.
        </p>

        <h3>Cancellation:</h3>
        <ul>
          <li>
            Customers may cancel their subscription or service at any time.
          </li>
          <li>
            To cancel, customers must contact our support team or use the
            cancellation feature provided in their account settings.
          </li>
          <li>
            Upon cancellation, access to the digital menu service will be
            terminated at the end of the current billing period.
          </li>
        </ul>

        <h3>Refunds:</h3>
        <ul>
          <li>
            If the payment is only captured in state, it will processed to
            settlement state within 24 hours.
          </li>
          <li>
            Refunds will be provided only in specific circumstances and at the
            discretion of our management.
          </li>
          <li>
            Refund requests must be submitted in writing to our support team,
            stating the reason for the request.
          </li>
          <li>
            Refunds will not be provided for partial subscription periods that
            have already been used.
          </li>
          <li>
            Refunds may be issued in cases of technical issues or service
            unavailability that significantly impact the customer's ability to
            use the service.
          </li>
          <li>
            Refunds will not be provided for dissatisfaction with the service if
            it is due to subjective preferences or preferences not met.
          </li>
          <li>
            Refunds will be processed using the same method as the original
            payment.
          </li>
        </ul>

        <p>
          We reserve the right to modify or update this cancellation/refund
          policy at any time without prior notice and will be mail as soon as
          possible.
        </p>
        <p>
          Changes to the policy will be effective immediately upon posting on
          our website.
        </p>
        <p>
          By using our digital menu and payment service, customers agree to
          abide by this cancellation/refund policy. If you have any questions or
          concerns regarding this policy, please contact our support team for
          clarification.
        </p>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default RefundCanUser;
