import React, { useState, useContext, useEffect, useRef } from "react";
import QRCode from "qrcode.react";
import { UserContext } from "../../../UserContext";
import axios from "axios";
import "./QRcode.css";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import Spinner from "react-bootstrap/Spinner";
import backImg from "./photos/217.png";
import QR from "./photos/qr.png";
import Cookies from "js-cookie";

import jsPDF from "jspdf";

function QRcode() {
  console.log(process.env.REACT_APP_ADD_RES_INFO);
  const qrCodeRef = useRef(null);
  const [input, setInput] = useState("");
  const [dataLoad, setDataLoad] = useState(false);
  // const [qrCodeValue, setQRCodeValue] = useState(
  //   process.env.REACT_APP_ADD_USERPAGE
  // );
  const [qrCodeValue, setQRCodeValue] = useState(
    "https://ezzorder.in/menu/"
  );
  const { token } = useContext(UserContext);
  const [nameOfCafe, setNameOfCafe] = useState("");

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const qrCodeStyle = {
    // width: "230px",
    // height: "350px",
    width: "180px",
    height: "200px",
    padding: "15px 10px",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  useEffect(() => {
    generateQRCode();
  }, []);

  const generateQRCode = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_ADD_QR_CODE,
        {},
        {
          headers: {
            // Authorization: `bearer ${token}`,
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      const newQrCodeValue = qrCodeValue + response.data.noOfReg;
      setQRCodeValue(newQrCodeValue);
      setNameOfCafe(response.data.nameOfCafe);
      setDataLoad(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const divRef = useRef(null);

  const handleDownloadClick = () => {
    // Capture the content of the div as an image
    html2canvas(divRef.current).then((canvas) => {
      // Create a canvas with the desired dimensions
      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.width = 270;
      resizedCanvas.height = 350;
      const ctx = resizedCanvas.getContext("2d");

      // Scale and draw the captured image onto the resized canvas
      ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

      // Convert the resized canvas to a data URL
      const imageUrl = resizedCanvas.toDataURL("image/png");

      // Create a temporary link element
      const downloadLink = document.createElement("a");
      downloadLink.href = imageUrl;
      downloadLink.download = "downloaded_image.png"; // Set the file name

      // Trigger the download
      downloadLink.click();
    });
  };

  return (
    <div
      className="qrCodeHeadTop"
      // style={{
      //   opacity: dataLoad ? "1" : "0.2",
      // }}
    >
      <div>
        <span className="titleQRCode">Your QR Code</span>
      </div>
      {dataLoad ? (
        <div>
          <div className="qrCodeHead">
            <div className="qrCode">
              {qrCodeValue && (
                <div class="container2" ref={divRef}>
                  <p className="menuName">{nameOfCafe}</p>
                  <img src={backImg} alt="300x300 Image" className="mainImg" />
                  {/* <img class="small-image" src={QR} alt="100x100 Image"></img> */}
                  <QRCode
                    className="small-image qrCodeImg"
                    style={qrCodeStyle}
                    value={qrCodeValue}
                  />
                  <p className="scanYour">Scan Your Order</p>
                </div>
              )}
            </div>
          </div>
          <div className="downloadBtn">
            {/* <button onClick={convertDivToImage}>Download QR</button> */}
            <button onClick={handleDownloadClick}>Download QR</button>
          </div>
        </div>
      ) : (
        <div
          className="growSpinnerHead"
          style={{ display: dataLoad ? "none" : "" }}
        >
          <Spinner
            className="growSpinner"
            style={{
              color: "#ff5722",
            }}
            animation="grow"
            role="status"
          ></Spinner>
        </div>
      )}
    </div>
  );
}

export default QRcode;

{
  /* <div className="qrCodeInside">
                  {nameOfCafe ? <h2>{nameOfCafe}</h2> : <h2>Name of Cafe</h2>}
                  <QRCode style={qrCodeStyle} value={qrCodeValue} />
                  <h2>Scan Your Order</h2>
                </div> */
}
