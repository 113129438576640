// socket.js
import socketIOClient from "socket.io-client";

const socket = socketIOClient(process.env.REACT_APP_BASE_URL);

// Handle disconnection event
socket.on("disconnect", () => {
  console.log("Disconnected from the server");
});

// Handle reconnect event
socket.on("reconnect", () => {
  console.log("Reconnected to the server");
});

export default socket;
