import React, { useEffect, useState } from "react";
import NavbarTop from "../Navbar/NavbarTop";
import "./MainPage.css";
import Typed from "typed.js";
import Footer from "../ownerOfweb/Footer/Footer";
import BackImg from "./img/backImg1.png";
import Ezzorder_Main from "./img/ezzorder_main.png";
import Ezzorder_Main2 from "./img/ezzorder_main2.png";
import Carousel from "react-bootstrap/Carousel";
import sec2_printer from "./img/images__2_-removebg-preview.png";
import picture from "./img/images__3_-removebg-preview.png";
import clock from "./img/images__4_-removebg-preview.png";
import phoneImg from "./img/phone-menu.png";
import menuFeature_item1 from "./img/images__6_-removebg-preview.png";
import menuFeature_item2 from "./img/images__5_-removebg-preview.png";
import menuFeature_item3 from "./img/mani.png";

function MainPage() {
  const [widthOfScreen, setWidthOfScreen] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidthOfScreen(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);

  useEffect(() => {
    // Initialize Typed instances
    const typed1 = new Typed(".typing-1", {
      strings: ["Smarter, Dine and Happier"],
      typeSpeed: 100,
      backSpeed: 60,
      loop: true,
    });

    // Clean up function to destroy Typed instances when the component unmounts
    return () => {
      typed1.destroy();
    };
  }, []);
  return (
    <div>
      <div className="navMainPage ">
        <NavbarTop />
      </div>
      <div className="sec2">
        <div className="cItem container">
          <h1>Ezzorder</h1>
          <h2>Order</h2>
          <h2>
            <span className="typing-1"></span>
          </h2>
          <h2>with Ezzorder</h2>
        </div>
        {/* <div className="sec3">
          <div className="container">
            <h4>Solution for every Dinner Need</h4>
            <h6>
              Revolutionize your restaurant experience with our cutting edge
              digital menu and payment platform Tailored to your unique needs
              Ezzorder empowers your patrons to effortlessly explore your
              offerings, place orders, and settle bills, all from their
              smartphones Say goodbye to waiting times and hello to seamless
              dining Join us in streamlining service, enhancing customer
              satisfaction, and embracing the future of dining Experience the
              power of personalized hospitality with Ezzorder today
            </h6>
          </div> */}
        {/* </div> */}
      </div>

      <div className="sec3">
        <div className="container">
          <h4>Solution for every Dinner Need</h4>
          <h6>
            Revolutionize your restaurant experience with our cutting edge
            digital menu and payment platform Tailored to your unique needs
            Ezzorder empowers your patrons to effortlessly explore your
            offerings, place orders, and settle bills, all from their
            smartphones Say goodbye to waiting times and hello to seamless
            dining Join us in streamlining service, enhancing customer
            satisfaction, and embracing the future of dining Experience the
            power of personalized hospitality with Ezzorder today
          </h6>
        </div>
      </div>

      {/* <div className="MainPageBody container ">
        <div className="sec1 ">
          <img src={Ezzorder_Main} />
        </div>
        <div className="sec2">
          <img src={Ezzorder_Main2} />
        </div>
      </div> */}

      {/* <div className="sec1 ">
        <img src={BackImg} />
        <div className="content">
          <p className="ezzheading">EzzOrder</p>
          <p className="item1">"Order Smarter,</p>
          <p className="item2">Dine Happier"</p>
        </div>
      </div> */}

      <div className="sec_benifits_head">
        <div className="container sec_benifits">
          <h1 style={{ fontWeight: "bold" }} className="title_benifits">
            Benifits You Get
          </h1>
          {/* style={{ flexDirection: widthOfScreen < 800 ? "column" : "row" }} */}
          {widthOfScreen > 992 ? (
            <Carousel>
              <Carousel.Item>
                <div className="benefit_card">
                  <div className="card1 benefit_section_card">
                    <p className="heading">Cost Optimization</p>
                    <p>
                      No need to spend on paper menus when updating price and
                      dishes.
                    </p>
                    <img src={sec2_printer} />
                  </div>
                  <div className="card1 benefit_section_card">
                    <p className="heading">Always at hand</p>
                    <p>Smartphone management instant menu updates.</p>
                    <p> </p>

                    <img src={picture} />
                  </div>
                  <div className="card1 benefit_section_card">
                    <p className="heading">Convenient for guests</p>
                    <p>No waiting for waiters. No app installation needed</p>
                    <p> </p>
                    <img src={clock} />
                  </div>
                </div>
                {/* <Carousel.Caption> Manish</Carousel.Caption> */}
              </Carousel.Item>
            </Carousel>
          ) : (
            <Carousel data-bs-theme="dark">
              <Carousel.Item>
                <div className="card1 benefit_section_card">
                  <p className="heading">Cost Optimization</p>
                  <p>
                    No need to spend on paper menus when updating price and
                    dishes.
                  </p>
                  <img src={sec2_printer} />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card1 benefit_section_card">
                  <p className="heading">Always at hand</p>
                  <p>Smartphone management instant menu updates.</p>
                  <img src={picture} />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card1 benefit_section_card">
                  <p className="heading">Convenient for guests</p>
                  <p>No waiting for waiters. No app installation needed</p>
                  <img src={clock} />
                </div>
              </Carousel.Item>
            </Carousel>
          )}
        </div>
      </div>

      {/* <div className="sec_pricing container">
        <h1>Pricing</h1>
        {widthOfScreen > 992 ? (
          <Carousel>
            <Carousel.Item>
              <div className="pricing_card">
                <div className="card1">
                  <p className="heading">With Ads (1 month)</p>
                  <p>Rs 99</p>
                  <p> include basic features with advertisement</p>
                </div>
                <div className="card1">
                  <p className="heading">Standard (1 month)</p>
                  <p>Rs 199</p>
                  <p>include basic features without advertisement</p>
                </div>
                <div className="card1">
                  <p className="heading">Quarterly</p>
                  <p>Rs 599</p>
                  <p>include basic features without advertisement</p>
                </div>
                <div className="card1">
                  <p className="heading"> Annually</p>
                  <p>Rs 1999</p>
                  <p> include basic features without advertisement</p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel data-bs-theme="dark">
            <Carousel.Item>
              <div className="card1">
                <p className="heading">With Ads (1 month)</p>
                <p>Rs 99</p>
                <p> include basic features with advertisement</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card1">
                <p className="heading">Standard (1 month)</p>
                <p>Rs 199</p>
                <p>include basic features without advertisement</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card1">
                <p className="heading">Quarterly</p>
                <p>Rs 599</p>
                <p>include basic features without advertisement</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card1">
                <p className="heading"> Annually</p>
                <p>Rs 1999</p>
                <p> include basic features without advertisement</p>
              </div>
            </Carousel.Item>
          </Carousel>
        )}
      </div> */}

      <div className="sec_pricing_head">
        <div className="sec_pricing container">
          <h1 className="price_heading">Pricing</h1>
          <div className="pricing_cards">
            <div className="card1">
              <p className="heading">With Ads (1 month)</p>
              <p>Rs 99</p>
              <p> include basic features with advertisement</p>
            </div>
            <div className="card1">
              <p className="heading">Standard (1 month)</p>
              <p>Rs 199</p>
              <p>include basic features without advertisement</p>
            </div>
            <div className="card1">
              <p className="heading">Quarterly</p>
              <p>Rs 599</p>
              <p>include basic features without advertisement</p>
            </div>
            <div className="card1">
              <p className="heading"> Annually</p>
              <p>Rs 1999</p>
              <p> include basic features without advertisement</p>
            </div>
          </div>
        </div>
      </div>

      <div className="sec_about_head">
        <div className="sec_about container">
          <h2 className="aboutTitle">About Us?</h2>
          <div className="aboutBody">
            <div className="imgSection">
              <img src={phoneImg} />
            </div>
            <div className="aboutContent">
              <p>
                EzzOrder is a pioneering tech-driven company focused on
                transforming the dining experience for small cafes and
                restaurants. Established with a vision to simplify and enhance
                the way people interact with menus and make payments, EzzOrder
                is at the forefront of the digitalization wave in the food
                industry.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="acc_creation">
        <div className="container sec_benifits">
          <h1 className="title">Simple Way to Create Account</h1>
          {/* style={{ flexDirection: widthOfScreen < 800 ? "column" : "row" }} */}
          {widthOfScreen > 992 ? (
            <Carousel data-bs-theme="">
              <Carousel.Item>
                <div className="benefit_card">
                  <div className="card1 acc_creation_item1">
                    <p className="heading">
                      Fill in the Restaurant Information
                    </p>
                    <img src={sec2_printer} />
                  </div>
                  <div className="card1 acc_creation_item2">
                    <p className="heading">Add Items and Dish Category</p>
                    <img src={picture} />
                  </div>
                  <div className="card1 acc_creation_item3">
                    <p className="heading">Get The QR Code and Website Link</p>
                    <img src={clock} />
                  </div>
                </div>
                {/* <Carousel.Caption> Manish</Carousel.Caption> */}
              </Carousel.Item>
            </Carousel>
          ) : (
            <Carousel data-bs-theme="dark">
              <Carousel.Item>
                <div className="benefit_card">
                  <div className="card1 acc_creation_item1">
                    <p className="heading">
                      Fill in the Restaurant Information
                    </p>
                    <img src={sec2_printer} alt="Restaurant Information" />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="benefit_card">
                  <div className="card1 acc_creation_item2">
                    <p className="heading">Add Items and Dish Category</p>
                    <img src={picture} alt="Add Items and Dish Category" />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="benefit_card">
                  <div className="card1 acc_creation_item3">
                    <p className="heading">Get The QR Code and Website Link</p>
                    <img src={clock} alt="QR Code and Website Link" />
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          )}
        </div>
      </div>

      <div className="menu_feature_head">
        <div className="container sec_menu_feature">
          <h1 className="menu_feature_title">QR code menu features</h1>
          {widthOfScreen > 992 ? (
            <Carousel data-bs-theme="">
              <Carousel.Item>
                <div className="menu_feature_cards">
                  <div className="menu_feature_card menu_feature_card1">
                    <p className="heading">Orders</p>
                    <p>Get notifications and accept orders from guests</p>
                    <img src={menuFeature_item1} />
                  </div>
                  <div className="menu_feature_card menu_feature_card2">
                    <p className="heading">Cart</p>
                    <p>For guests to easily collect and memorize the orders.</p>
                    <img src={menuFeature_item2} />
                  </div>
                  <div className="menu_feature_card menu_feature_card3">
                    <p className="heading">payment</p>
                    <p>No need to carry cash. make cashless payment.</p>
                    <img src={menuFeature_item3} />
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          ) : (
            <Carousel data-bs-theme="dark">
              <Carousel.Item>
                <div className="menu_feature_card menu_feature_card1">
                  <p className="heading">Orders</p>
                  <p>Get notifications and accept orders from guests</p>
                  <div className="menu_feature_card_img">
                    <img src={menuFeature_item1} alt="Restaurant Information" />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="menu_feature_card menu_feature_card2">
                  <p className="heading">Cart</p>
                  <p>For guests to easily collect and memorize the orders.</p>
                  <div className="menu_feature_card_img">
                    <img
                      src={menuFeature_item2}
                      alt="Add Items and Dish Category"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="menu_feature_card menu_feature_card3">
                  <p className="heading">payment</p>
                  <p>No need to carry cash. make cashless payment.</p>
                  <div className="menu_feature_card_img">
                    <img
                      src={menuFeature_item3}
                      alt="QR Code and Website Link"
                    />
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          )}
        </div>
      </div>

      <Footer />
      {/* <div class="text-3">
        And I'm a <span class="typing-1"></span>
      </div> */}
    </div>
  );
}
export default MainPage;
