import React, { createContext, useState } from 'react';

// Create a context object
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  // const [user1, setUser1] = useState({
  //   "email": "",
  //   "pass": ""
  // });
  const [token, setToken] = useState('');

  return (
    <UserContext.Provider value={{ token, setToken }}>
      {children}
    </UserContext.Provider>
  );
};
