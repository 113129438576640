import React, { useState } from "react";
import NavbarTop from "../../Navbar/NavbarTop";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "./ForgotPage.css";
function ForgotPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [otpSendSucess, setOtpSendSucess] = useState();
  const [otpSendStatus, setOtpSendStatus] = useState();
  const [message, setMessage] = useState();
  const [verifySendStatus, setVerifySendStatus] = useState(false);
  const [verifySucess, setVerifySucess] = useState(false);
  const [changePassStatus, setChangePassStatus] = useState(false);
  const [otp, setOtp] = useState(false);

  function validateEmail(email) {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
    return emailPattern.test(email);
  }

  const handleOtpSend = async () => {
    const isEmailValid = validateEmail(email);
    if (isEmailValid) {
      setOtpSendStatus(true);
      try {
        const response = await axios.post(process.env.REACT_APP_FORGOT_PASS, {
          email: email,
        });
        if (response.status == 200) {
          // alert(response.data.message);
          setOtpSendStatus(false);
          setOtpSendSucess(true);
          setMessage(response.data.message);
        } else if (response.status == 201) {
          setOtpSendStatus(false);
          setMessage(response.data.message);
        }
      } catch (error) {
        alert("Error in cafe registration");
        console.error("Error:", error);
      }
    } else {
      setMessage("Please Enter Correct Email");
    }
  };

  const handleOtpVerify = async () => {
    setVerifySendStatus(true);

    try {
      const response = await axios.post(process.env.REACT_APP_VERIFY_OTP, {
        email: email,
        otp: parseInt(otp),
      });
      if (response.status == 200) {
        // alert(response.data.message);
        setVerifySendStatus(false);
        setVerifySucess(true);
        setMessage(response.data.message);
        console.log(response.data.message);
      } else if (response.status == 201) {
        setMessage(response.data.message);
        alert(response.data.message);
        setVerifySendStatus(false);
      } else {
        alert("some error");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };

  function validatePassword(password) {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordPattern.test(password)) {
      const errors = [];
      if (!/(?=.*[a-z])/.test(password)) {
        errors.push(" one lowercase letter,");
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        errors.push(" one uppercase letter,");
      }
      if (!/(?=.*\d)/.test(password)) {
        errors.push(" one digit,");
      }
      if (!/(?=.*[@$!%*?&])/.test(password)) {
        errors.push(" one special character,");
      }
      if (password.length < 8) {
        errors.push(" length of 8 characters");
      }

      return `Password must have at Least: ${errors.join(" ")}`;
    }

    return true;
  }

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setMessage("");
  };
  const handleOtp = (event) => {
    setOtp(event.target.value);
    console.log(event.target.value);
    setMessage("");
  };
  const handlePass = (event) => {
    setPassword(event.target.value);
    setMessage("");
  };
  const handleRePass = (event) => {
    setRePassword(event.target.value);
    setMessage("");
  };

  const handlePassChangeSubmit = async () => {
    setChangePassStatus(true);
    const isPasswordValid = validatePassword(password);
    if (!(password == rePassword)) {
      setMessage("Password and ReEnter password must be the same");
    } else if (!isPasswordValid) {
      setMessage(isPasswordValid);
    } else {
      if (otpSendSucess && verifySucess) {
        try {
          const response = await axios.post(process.env.REACT_APP_CHANGE_PASS, {
            email: email,
            pass: password,
          });
          if (response.status == 200) {
            setMessage(response.data.message);
            alert(response.data.message);
            navigate("/login-signup");
          } else if (response.status == 201) {
            setMessage(response.data.message);
          }
        } catch (error) {
          alert("Error in cafe registration");
          alert(error);
          console.error("Error:", error);
        }
      } else {
        setMessage("Something went wrong");
      }
    }
    setChangePassStatus(false);
  };

  return (
    <div>
      <div>
        <NavbarTop />
      </div>
      <div className="forgotPageHead container">
        <div
          className="forgotPage"
          style={{
            opacity:
              otpSendStatus || verifySendStatus || changePassStatus
                ? "0.7"
                : "1",
          }}
        >
          <Spinner
            className="forgotGrowSpinner"
            style={{
              color: "#ff5722",
              display:
                otpSendStatus || verifySendStatus || changePassStatus
                  ? ""
                  : "none",
            }}
            animation="grow"
            role="status"
          ></Spinner>
          <p className="forgotPageTitle">Forgot Password</p>
          <p className="forgotMessage">{message}</p>
          <div className="forgotItem">
            <label>Enter Email</label>
            <input placeholder="Enter Email" onChange={handleEmail}></input>
          </div>
          <div
            className="forgotItem forgotOtp"
            style={{ display: verifySucess ? "none" : "" }}
          >
            <input
              className=""
              placeholder="Enter OTP"
              onChange={handleOtp}
              style={{ display: otpSendSucess ? "" : "none" }}
            ></input>
            <button onClick={otpSendSucess ? handleOtpVerify : handleOtpSend}>
              {otpSendSucess ? "Verify" : "Send OTP"}
            </button>
          </div>
          <div
            className="forgotItem"
            style={{ display: verifySucess ? "" : "none" }}
          >
            <label>Enter Password</label>
            <input onChange={handlePass} placeholder="Enter Password"></input>
          </div>
          <div
            className="forgotItem"
            style={{ display: verifySucess ? "" : "none" }}
          >
            <label>Re-Enter Password</label>
            <input onChange={handleRePass} placeholder="Enter Password"></input>
          </div>
          <div
            className="forgotItem changeBtn"
            style={{ display: verifySucess ? "" : "none" }}
          >
            <button onClick={handlePassChangeSubmit}>Change Password</button>
          </div>
          <div>
            <button
              className="forgotLoginSignUp"
              onClick={() => navigate("/login-signup")}
            >
              Login/Signup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPage;
