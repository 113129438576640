import React, { useState, useContext, useEffect } from "react";
import "./CafeRegister.css"; // Import the CSS file
import axios from "axios";
import NavbarTop from "../../Navbar/NavbarTop.js";
import { UserContext } from "../../../UserContext";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";

const CafeRegister = () => {
  // i refers to item , o refers to orders
  const { token } = useContext(UserContext);
  const [sendToken, setSendToken] = useState(token);
  const navigate = useNavigate();
  const [submitClick, setSubmitClick] = useState(false);
  // useEffect(() => {
  //   const user = Cookies.get("userToken");
  //   if (user === undefined) {
  //     console.log("not logged in");
  //     setloginSucess(false);
  //   } else {
  //     console.log("User Token:", user);
  //   }
  // }, []);
  const [restAddInfo, setAddInfo] = useState({
    nameOfCafe: "",
    noOfReg: null,
    add: {
      street: "",
      city: "",
      state: "",
      pCode: "",
    },
    mo: "",
    GST: "",
    Timing: {
      start: "",
      close: "",
    },
    status: true,
  });

  // Authorization: `bearer ${sendToken}`,

  const assignResNo = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_REGISTER_CAFE,
        { restAddInfo: restAddInfo },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      const data = response.data;
      console.log(response);
      if (data.message === "cafe info updated successfully") {
        alert("Record submitted successfully");
        navigate("/kyc");
        setSubmitClick(false);
      } else if (data.message === "login first") {
        alert("Login first");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };

  const handleSubmit = async () => {
    setSubmitClick(true);
    console.log(restAddInfo);
    try {
      const response = await axios.get(process.env.REACT_APP_GET_REG_NO);
      const data = response.data;
      if (response.status === 200) {
        console.log(response.data.message);
      } else {
        console.log("Unexpected status code:", response.status);
      }
      // Update the noOfReg value in restAddInfo
      setAddInfo((prevInfo) => ({ ...prevInfo, noOfReg: data.noOfReg }));
    } catch (error) {
      console.error("An error occurred", error);
    }
  };
  useEffect(() => {
    if (restAddInfo.noOfReg !== null && restAddInfo.noOfReg !== undefined) {
      assignResNo();
    }
  }, [restAddInfo.noOfReg]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddInfo((prevAddInfo) => ({
      ...prevAddInfo,
      [name]: value,
    }));
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddInfo((prevAddInfo) => ({
      ...prevAddInfo,
      add: {
        ...prevAddInfo.add,
        [name]: value,
      },
    }));
  };

  const handleTimingChange = (event) => {
    const { name, value } = event.target;
    setAddInfo((prevAddInfo) => ({
      ...prevAddInfo,
      Timing: {
        ...prevAddInfo.Timing,
        [name]: value,
      },
    }));
  };

  return (
    <div className="cafeRegisterHead">
      <div
        className="spinnerHead"
        style={{ display: submitClick ? "" : "none" }}
      >
        <Spinner
          className=""
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner>
      </div>
      <div className="naveCafeRegister">
        <NavbarTop />
      </div>
      <h1 className="">Restaurant Owner Information</h1>
      <div
        className="cafeRegister container"
        style={{
          opacity: submitClick ? "0.7" : "1",
        }}
      >
        <div className="itemsHead">
          <div className="items">
            <label>Name of Cafe:</label>
            <input
              type="text"
              name="nameOfCafe"
              placeholder="Enter Name of Cafe"
              value={restAddInfo.nameOfres}
              onChange={handleInputChange}
            />
          </div>
          <div className="items">
            <label>Address:</label>
            <input
              type="text"
              name="street"
              placeholder="Enter Address"
              required
              value={restAddInfo.add.street}
              onChange={handleAddressChange}
            />
          </div>
          <div className="items">
            <label>City:</label>
            <input
              type="text"
              name="city"
              required
              placeholder="Enter City"
              value={restAddInfo.add.city}
              onChange={handleAddressChange}
            />
          </div>
          <div className="items">
            <label>State:</label>
            <input
              type="text"
              name="state"
              required
              placeholder="Enter State"
              value={restAddInfo.add.state}
              onChange={handleAddressChange}
            />
          </div>
          <div className="items">
            <label>Pin Code:</label>
            <input
              type="text"
              name="pCode"
              required
              placeholder="Enter Pin Code"
              value={restAddInfo.add.pCode}
              onChange={handleAddressChange}
            />
          </div>
          <div className="items">
            <label>Mobile:</label>
            <input
              type="text"
              name="mo"
              required
              placeholder="Enter Mobile Number"
              value={restAddInfo.mo}
              onChange={handleInputChange}
            />
          </div>
          <div className="items">
            <label>GST:</label>
            <input
              type="text"
              name="GST"
              placeholder="Ener Gst Number"
              value={restAddInfo.GST}
              onChange={handleInputChange}
            />
          </div>
          <div className="items">
            <label>Open Timing:</label>
            <input
              type="text"
              name="start"
              placeholder="Enter Opening Timing"
              value={restAddInfo.Timing.start}
              onChange={handleTimingChange}
            />
          </div>
          <div className="items">
            <label>Close Timing:</label>
            <input
              type="text"
              name="close"
              placeholder="Enter Closing Timing"
              value={restAddInfo.Timing.close}
              onChange={handleTimingChange}
            />
          </div>
          <div className="btnCafeSubmit">
            <button onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CafeRegister;
