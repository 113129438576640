import React, { useEffect } from "react";
import "./CookiePolicy.css";
import NavbarTop from "../../Navbar/NavbarTop";
import Footer from "../Footer/Footer";

const CookiePolicy = () => {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);
  return (
    <div className="cookieHead">
      <NavbarTop />
      <div className="cookie-policy-container">
        <h1>Cookie Policy</h1>
        <p>Last Updated: 11 Februray 2023</p>
        <section>
          <h2>1. Introduction</h2>
          <p>
            This Cookie Policy explains how our digital menu and payment system,
            developed using MERN Technology (MongoDB, Express, React, Node),
            utilizes cookies to enhance user experience and provide essential
            functionalities. By using our platform, you consent to the use of
            cookies as described in this policy.
          </p>
        </section>
        <section>
          <h2>2. What are Cookies?</h2>
          <p>
            Cookies are small text files that are stored on your device
            (computer, smartphone, or tablet) when you interact with our
            platform. They serve various purposes, including remembering your
            preferences, analyzing usage patterns, and facilitating
            transactions.
          </p>
        </section>
        <section>
          <h2>3. Types of Cookies We Use</h2>
          <p>We use the following types of cookies:</p>
          <ul>
            <li>
              <strong>Essential Cookies:</strong> These cookies are necessary
              for the proper functioning of our platform. They enable core
              functionalities such as user authentication, session management,
              and payment processing.
            </li>
            <li>
              <strong>Analytical/Performance Cookies:</strong> These cookies
              allow us to collect information about how users interact with our
              platform. We use this data to analyze usage patterns, identify
              areas for improvement, and optimize our services.
            </li>
            <li>
              <strong>Functionality Cookies:</strong> These cookies enhance the
              user experience by remembering your preferences and settings. They
              enable features such as language selection and personalized
              content delivery.
            </li>
            <li>
              <strong>Third-Party Cookies:</strong> Some third-party services
              integrated into our platform may use cookies to track your
              browsing activity and provide targeted advertisements. We do not
              have control over these cookies, and their use is subject to the
              respective third-party privacy policies.
            </li>
          </ul>
        </section>
        <section>
          <h2>4. How We Use Cookies</h2>
          <p>We use cookies for the following purposes:</p>
          <ul>
            <li>To provide and maintain our services.</li>
            <li>To analyze usage patterns and improve our platform.</li>
            <li>To personalize content and advertisements.</li>
            <li>To enhance security and prevent fraudulent activity.</li>
            <li>To remember your preferences and settings.</li>
          </ul>
        </section>
        <section>
          <h2>5. Managing Cookies</h2>
          <p>
            You have the option to manage cookies through your web browser
            settings. You can choose to accept, reject, or delete cookies.
            Please note that disabling cookies may affect the functionality and
            user experience of our platform.
          </p>
        </section>
        <section>
          <h2>6. Changes to this Policy</h2>
          <p>
            We may update this Cookie Policy from time to time to reflect
            changes in our practices or legal requirements. We will notify you
            of any significant changes by posting the updated policy on our
            platform.
          </p>
        </section>
        <section>
          <h2>7. Contact Us</h2>
          <p>
            If you have any questions or concerns about our Cookie Policy,
            please
            <button className="contactbutton">contact us</button>.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
