import React, { useState, useContext, useEffect } from "react";
import "./AdminLogin.css";
import Log from "./img/log.svg";
import axios from "axios";
import Cookies from "js-cookie";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { UserContext } from "../../../UserContext";
import { useNavigate } from "react-router-dom";
import socket from "../../../socket";
import Spinner from "react-bootstrap/Spinner";
function AdminLogin() {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loginSucess, setloginSucess] = useState(false);
  const [tokenAvai, setTokenAvai] = useState(false);

  const handleSignInClick = () => {
    setIsSignUpMode(false);
    setEmail("");
    //setUserName("");
    setPassword("");
  };

  const navigate = useNavigate();
  const { token, setToken } = useContext(UserContext);

  const handleLogin = async () => {
    setloginSucess(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_ADMIN_LOGIN_TOKEN,
        {
          adminId: email,
          pass: password,
        }
      );
      if (response.status === 200) {
        const { token, message } = response.data;
        console.log(message);
        Cookies.set("adminLoginToken", token, { expires: 7 });
        navigate("/admin-dash-board/home");
      } else if (response.status === 201) {
        console.log(response.data.message);
        setMessage(response.data.message);
      } else {
        console.log("Unexpected status code:", response.status);
      }
      setloginSucess(false);
    } catch (error) {
      console.error("Error logging in:", error);
      setMessage("An error occurred");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setMessage("");
  };

  const handlePasswordchange = (e) => {
    setPassword(e.target.value);
    setMessage("");
  };
  const togglePasswordVisibility = () => {
    setPassVisible(!passVisible);
  };

  useEffect(() => {
    if (Cookies.get("adminLoginToken")) {
      setTokenAvai(true);
    }
  }, []);

  const Logout = () => {
    Cookies.remove("adminLoginToken");
    navigate("/login-admin");
    window.location.reload();
  };

  const navigateDash = () => {
    navigate("/admin-dash-board/home");
  };

  return (
    <div>
      <div className="navbarAdmin">
        <Navbar className="navbarHead" bg="light" expand="lg">
          <Navbar.Brand className="navbarHeading">EzzOrder</Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="toggleBtn"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end navbarCollapse"
          >
            <Nav className="ml-auto HomePageLink">
              {tokenAvai ? (
                <div className="loginItem items d-flex flex-row">
                  <Nav.Link className="collapseItem" onClick={navigateDash}>
                    Dashboard
                  </Nav.Link>
                  <Nav.Link className="collapseItem" onClick={Logout}>
                    Logout
                  </Nav.Link>
                </div>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div
        className="loginSignUpTopHead"
        style={{
          opacity: loginSucess ? "0.7" : "1",
        }}
      >
        <div
          className={`container1 loginSignUpHead ${
            isSignUpMode ? "sign-up-mode" : ""
          }`}
        >
          <div
            className="growSpinnerHead"
            style={{ display: loginSucess ? "" : "none" }}
          >
            <Spinner
              className="growSpinner"
              style={{
                color: "#ff5722",
              }}
              animation="grow"
              role="status"
            ></Spinner>
          </div>
          <div className="forms-container">
            <div className="signin-signup">
              <div
                action="#"
                className={
                  isSignUpMode
                    ? "sign-up-form formClass"
                    : "sign-in-form formClass"
                }
              >
                <h2 className="title">
                  {isSignUpMode ? "Sign up" : "Sign in"}
                </h2>
                <p className="message">{message}</p>
                <div className="input-field">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>

                <div className="input-field passwordHead">
                  <input
                    type={passVisible ? "text" : "password"}
                    className="passInput"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordchange}
                  />
                  <input
                    type="checkbox"
                    className="passCheckbox"
                    name="myCheckbox"
                    onChange={togglePasswordVisibility}
                  />
                </div>

                <button
                  style={{ color: "white" }}
                  className={`btn1 ${isSignUpMode ? "" : "solid"}`}
                  onClick={handleLogin}
                >
                  Login
                </button>

                <p className="social-text">
                  Or Sign {isSignUpMode ? "up" : "in"} with social platforms
                </p>
                <div className="social-media">
                  <a href="#" className="social-icon"></a>
                  <a href="#" className="social-icon"></a>
                  <a href="#" className="social-icon"></a>
                  <a href="#" className="social-icon"></a>
                </div>
              </div>
            </div>
          </div>
          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3>Admin Login Panel</h3>
                <button className="btn transparent welAdmin" id="sign-up-btn">
                  Welcome
                </button>
              </div>
              <img src={Log} className="image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminLogin;
