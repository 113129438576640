import React, { useState, useContext, useEffect } from "react";
import "./MenuCreation.css";
import axios from "axios";
import NavbarTop from "../../Navbar/NavbarTop";
import { UserContext } from "../../../UserContext";
import Cookies from "js-cookie";

const MenuCreation = () => {
  const { token } = useContext(UserContext);
  const [sendToken] = useState(token);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [otherCategory, setOtherCategory] = useState("");

  const [menuItem, setMenuItem] = useState({
    cateName: "",
    iName: "",
    price: "",
    foodType: "",
    preparationTime: "",
    des: "",
    status: true,
  });

  //  useEffect(() => {
  //    setMenuItem(prevItem => ({
  //     ...prevItem,
  //     cateName: selectedCategory!=='other'?otherCategory:selectedCategory
  //    }));
  //    selectedCategory !== 'other' ? setOtherCategory(""):<div></div>

  // }, [selectedCategory,otherCategory]);

  useEffect(() => {
    setMenuItem((prevItem) => ({
      ...prevItem,
      cateName: selectedCategory === "other" ? otherCategory : selectedCategory,
    }));

    if (selectedCategory !== "other") {
      setOtherCategory("");
    }
  }, [selectedCategory, otherCategory]);

  const categories = [
    { value: "Burger", label: "Burger" },
    { value: "Pizza", label: "Pizza" },
    { value: "Pasta", label: "Pasta" },
    { value: "Salad", label: "Salad" },
    { value: "Sandwich", label: "Sandwich" },
    { value: "Sushi", label: "Sushi" },
    { value: "Steak", label: "Steak" },
    { value: "Seafood", label: "Seafood" },
    { value: "Dessert", label: "Dessert" },
    { value: "Beverages", label: "Beverages" },
    // Add more items as needed
  ];

  // handle other category
  const handleOtherCategoryChange = (event) => {
    setOtherCategory(event.target.value);
  };

  const handleMenuItemChange = (e) => {
    const { name, value } = e.target;
    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      [name]: value,
    }));
  };

  const handleSuggAddItemChange = (index, field, value) => {
    const updatedItems = [...menuItem.suggAddItems];
    updatedItems[index][field] = value;

    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      suggAddItems: updatedItems,
    }));
  };

  const addSuggAddItem = () => {
    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      suggAddItems: [
        ...prevMenuItem.suggAddItems,
        {
          EItemName: "",
          EItemPrice: "",
        },
      ],
    }));
  };

  const handleRemoveSuggAddItem = (index) => {
    const updatedItems = [...menuItem.suggAddItems];
    updatedItems.splice(index, 1);

    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      suggAddItems: updatedItems,
    }));
  };

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
  };
  const handleAddMenuItem = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_ADD_ITEM_MENU,
        {
          menuItemData: menuItem,
        },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );

      if (response.status === 201) {
        alert("Menu item added successfully");
      } else {
        alert("Error adding menu item");
      }
    } catch (error) {
      console.error("Error adding menu item:", error);
      alert("Error adding menu item");
    }
  };

  return (
    <div className="MenuCreation menuCreationHead">
      <div className="">
        <div className="">
          <span className="titleMenuHeading">Create Your Menu</span>
          <form>
            <div className="tabStr">
              <div className="item">
                <label>Item Category:</label>
                <div>
                  <select
                    className="optionSelect"
                    name="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled hidden>
                      Category
                    </option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.value}>
                        {category.label}
                      </option>
                    ))}
                    <option value="other">other</option>
                  </select>
                </div>
              </div>

              {selectedCategory === "other" && (
                <div className="item">
                  <label>Category Name : </label>
                  <input
                    type="text"
                    value={otherCategory}
                    onChange={handleOtherCategoryChange}
                    placeholder="Enter other category"
                  />
                </div>
              )}

              <div className="item">
                <label>Item Name:</label>
                <input
                  type="text"
                  name="iName"
                  value={menuItem.iName}
                  onChange={handleMenuItemChange}
                />
              </div>

              <div className="item">
                <label>Price:</label>
                <input
                  type="text"
                  name="price"
                  value={menuItem.price}
                  onChange={handleMenuItemChange}
                />
              </div>
              <div className="item">
                <label>Food Type:</label>
                <select
                  className="optionSelect"
                  name="foodType"
                  value={menuItem.foodType}
                  onChange={handleMenuItemChange}
                >
                  <option value="">Select One</option>
                  <option value="Veg">Veg</option>
                  <option value="NVeg">Non-Veg</option>
                </select>
              </div>

              <div className="item">
                <label>Preparation Time:</label>
                <input
                  type="text"
                  name="preparationTime"
                  value={menuItem.preparationTime}
                  onChange={handleMenuItemChange}
                />
              </div>

              <div className="item">
                <label>Description:</label>
                <textarea
                  name="description"
                  value={menuItem.description}
                  onChange={handleMenuItemChange}
                  rows="4"
                  cols="10"
                ></textarea>
              </div>
            </div>

            <button
              type="button"
              className="btn-primary addMenuBtn"
              onClick={handleAddMenuItem}
            >
              Add Menu Item
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MenuCreation;
