import React from "react";
import "./ShippingPolicy.css";
import NavbarTop from "../../Navbar/NavbarTop";
import Footer from "../Footer/Footer";
function ShippingPolicy() {
  return (
    <div className="shippingHead">
      <NavbarTop />
      <div className="container shippingHeadInside">
        <h1>Shipping Information</h1>
        <p>
          We appreciate your interest in our services. Please note that we do
          not deliver or ship any physical products or items to customers.
          Below, you will find important information regarding our shipping
          policies:
        </p>

        <ol>
          <li>
            <strong>Digital Services Only:</strong> Ezzorder exclusively offers
            digital services, including our digital menu and payment system.
          </li>

          <li>
            <strong>Instant Access:</strong> Upon completing your order through
            our platform, you will gain instant access to the digital services
            or products purchased. Enjoy the convenience of immediate use
            without the need for waiting for shipping or delivery.
          </li>

          <li>
            <strong>No Shipping Charges:</strong> Since we do not deliver
            physical items, there are no shipping charges associated with orders
            placed through our platform. You can enjoy our services without any
            additional shipping fees.
          </li>

          <li>
            <strong>Secure Transactions:</strong> Our platform ensures secure
            and encrypted transactions to protect your personal and financial
            information. You can trust that your data is safe when making
            purchases through Ezzorder.
          </li>

          <li>
            <strong>Customer Support:</strong> Should you have any questions or
            require assistance, our customer support team is available to help.
            Contact us via email or phone, and we'll be happy to address any
            inquiries promptly.
          </li>
        </ol>

        <p>
          Thank you for choosing Ezzorder for your digital needs. We're
          committed to providing you with a seamless and hassle-free experience.
        </p>
      </div>
      <Footer />
    </div>
  );
}
export default ShippingPolicy;
