import React, { useEffect, useState, useContext } from "react";
import Account from "./account2.png";
import socket from "../../../socket";
import axios from "axios";
import NavbarTop from "../../Navbar/NavbarTop";
import "./Dashboard.css";
import MenuCreation from "../MenuCreation/MenuCreation";
import GetOrders from "../OrderControl/GetOrders";
import QRcode from "../QRcode/QRcode";
import EditMenu from "../EditMenu/EditMenu";
import Support from "../Support/Support";
import Setting from "../Setting/Setting";
import { Link, useNavigate, Route, Routes } from "react-router-dom";
import { UserContext } from "../../../UserContext";
import Spinner from "react-bootstrap/Spinner";
import Home from "./Home";
import Noti from "./noti.png";
import Dropdown from "react-bootstrap/Dropdown";
import StaticChart from "./static-month-order-chart.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faQrcode,
  faEdit,
  faCog,
  faLifeRing,
  faPlus,
  faShoppingCart,
  faUser,
  faBell,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

function Dashboard() {
  // const [showOrders, setshowOrders] = useState(true);
  // const [createMenu, setCreateMenu] = useState(false);
  // const [qrCode, setQRCode] = useState(false);
  // const [editMenu, setEditMenu] = useState(false);
  // const [setting, setSetting] = useState(false);
  // const [support, setSupport] = useState(false);
  const [dataLoad, setDataLoad] = useState(true);
  // const [shopStatus, setShopStatus] = useState(false);

  const [nameOfCafe, setNameOfCafe] = useState();
  const [noOfReg, setNoOfReg] = useState();
  const [resEmail, setResEmail] = useState();
  const [currentPageName, setCurrentPageName] = useState("Home");
  const [resStatus, setResStatus] = useState(false);

  const [widthOfScreen, setWidthOfScreen] = useState(window.innerWidth);

  const navigate = useNavigate();
  const { token } = useContext(UserContext);

  useEffect(() => {
    function handleResize() {
      setWidthOfScreen(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [widthOfNav, setwidthOfNav] = useState(
    widthOfScreen < 800 ? "0%" : "20%"
  );

  const toggleNavWidth = () => {
    setwidthOfNav(
      widthOfNav === "0%" ? (widthOfScreen < 800 ? "80%" : "20%") : "0%"
    );
  };

  useEffect(() => {
    navigate("/dashboard/home");
  }, []);

  const toggleMenu = (route, currentPageName) => {
    navigate(route);
    setwidthOfNav(widthOfScreen < 800 ? "0%" : "");
    setCurrentPageName(currentPageName);
  };

  useEffect(() => {
    setwidthOfNav(widthOfScreen < 800 ? "0%" : "20%");
  }, [widthOfScreen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_DASHBOARD,

          {},
          {
            headers: {
              Authorization: `bearer ${Cookies.get("userToken")}`,
            },
          }
        );
        setNoOfReg(response.data.noOfReg);
        setNameOfCafe(response.data.nameOfCafe);
        setResEmail(response.data.email);
        setResStatus(response.data.status);

        setDataLoad(true);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const handleSetResStatus = async (resStatus) => {
    setDataLoad(false);
    try {
      const response = await axios.post(
        // process.env.REACT_APP_DASHBOARD,
        "http://localhost:5000/set-res-status",
        { resStatus: !resStatus },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setResStatus(!resStatus);
        setDataLoad(true);
        console.log(" res set sucessful");
      } else {
        console.log("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLogout = () => {
    Cookies.remove("userToken");
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    socket.emit("login", Cookies.get("userToken"));
  }, []);

  return (
    <div className="dashboard" style={{ opacity: dataLoad ? "1" : "0.7" }}>
      <div
        className="growSpinnerHead"
        style={{ display: dataLoad ? "none" : "" }}
      >
        <Spinner
          className="growSpinner"
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner>
      </div>
      {/* <div className="navbarTop1">
        <NavbarTop />
      </div> */}
      <div className="navbarSection">
        <div className="toggleButton navSecton1" onClick={toggleNavWidth}>
          <p className="line"> </p>
          <p className="line"></p>
          <p className="line"></p>
        </div>
        <div className="navSection2">
          <div className="navLeft">
            <p className="home">{currentPageName}</p>
          </div>
          <div className="navRight">
            {/* <div>
              <img className="noti" src={Noti} alt="Noti" />
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0rem 0.2rem",
              }}
            >
              <FontAwesomeIcon
                className="shopStatusIcon"
                icon={resStatus ? faToggleOn : faToggleOff}
                onClick={() => handleSetResStatus(resStatus)}
                style={{
                  color: resStatus ? "green" : "red",
                  paddingRight: "0.3rem",
                }}
              />
              <FontAwesomeIcon
                icon={faBell}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
            </div>
            <div>
              <Dropdown>
                {/* <Dropdown.Toggle className="custom-toggle">
                  <img className="acc" src={Account} alt="Account" />
                </Dropdown.Toggle> */}
                <Dropdown.Toggle className="custom-toggle">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ color: "white", paddingRight: "0.3rem" }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom-toogle-body">
                  <p>your id : {noOfReg}</p>
                  <div>
                    <p>Name Of Cafe : {nameOfCafe}</p>
                  </div>
                  <div className="control-login">
                    <p className="loggP">Logged in as</p>
                    <div>
                      <div className="custom-toogle-body-img">
                        <img className="acc" src={Account} alt="Account" />

                        <p>{resEmail}</p>
                      </div>
                    </div>
                    <button className="logoutButton" onClick={handleLogout}>
                      Logout
                    </button>
                  </div>
                  <div className="">
                    <p>
                      <a href="https://ezzorder.onrender.com/menu/10002"></a>
                    </p>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="insideDashboard">
        <div
          className="navigationbar"
          style={{
            width: widthOfNav,
            transition: "width 0.5s",
            padding: "0%",
          }}
        >
          <h4 className="navCompanyName" onClick={() => navigate("/")}>
            Ezzorder
          </h4>
          <div className="navItems">
            <button
              className="sideButton"
              onClick={() => toggleMenu("/dashboard/home", "Home")}
            >
              <FontAwesomeIcon
                icon={faHome}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Home
            </button>
            <button
              className="sideButton"
              onClick={() => toggleMenu("/dashboard/show-orders", "Orders")}
            >
              <FontAwesomeIcon
                icon={faShoppingCart}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Orders
            </button>
            <button
              className="sideButton"
              onClick={() =>
                toggleMenu("/dashboard/create-menu", "Create Menu")
              }
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Create Menu
            </button>
            <button
              className="sideButton"
              onClick={() => toggleMenu("/dashboard/edit-menu", "Edit Menu")}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Edit Menu
            </button>
            <button
              className="sideButton"
              onClick={() => toggleMenu("/dashboard/qr-code", "QR Code")}
            >
              <FontAwesomeIcon
                icon={faQrcode}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              QR Code
            </button>
            <button
              className="sideButton"
              onClick={() => toggleMenu("/dashboard/setting", "Setting")}
            >
              <FontAwesomeIcon
                icon={faCog}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Setting
            </button>
            <button
              className="sideButton"
              onClick={() => toggleMenu("/dashboard/support", " Support")}
            >
              <FontAwesomeIcon
                icon={faLifeRing}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Support
            </button>
          </div>
        </div>
        <div
          className="layout"
          style={{
            opacity:
              widthOfScreen < 800 ? (widthOfNav == "80%" ? "0.5" : "1") : "1",
          }}
          onClick={() => (widthOfScreen < 800 ? setwidthOfNav("0%") : null)}
        >
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/show-orders" element={<GetOrders />} />
            <Route path="/create-menu" element={<MenuCreation />} />
            <Route path="/edit-menu" element={<EditMenu />} />
            <Route path="/qr-code" element={<QRcode />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/support" element={<Support />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
