// import React, { useEffect, useState, useMemo, useRef } from "react";
// // import { useParams } from "react-router-dom";
// // import { v4 as uuidv4 } from "uuid";
// import Spinner from "react-bootstrap/Spinner";
// import Slider from "react-slick";
// // import "slick-carousel/slick/slick.css";
// // import "slick-carousel/slick/slick-theme.css";
// import { Carousel } from "react-bootstrap";

// import axios from "axios";
// import "./User.css";
// import DefaultImg from "./UserImages/DefaultImg.png";
// // import DefaultImg2 from "./UserImages/DefaultImg2.png";
// // import Img1 from "./UserImages/img1.jpg";
// // import Img2 from "./UserImages/img2.jpg";
// import Logo from "./UserImages/Logo.jpg";
// import Ad from "./UserImages/ad.jpeg";
// import CartBtn from "./UserImages/logo/CartBtn.png";
// import Menu from "./UserImages/logo/menu.png";
// import Pay5 from "./UserImages/logo/pay5.png";

// ///model nedd
// import Button from "react-bootstrap/Button";
// // import Form from "react-bootstrap/Form";
// import Modal from "react-bootstrap/Modal";
// import { scroller, animateScroll } from "react-scroll";

// function User3() {
//   console.log(process.env.REACT_APP_ADD_ORDER_SEND);
//   const clickBoxRef = useRef();
//   // const { param } = useParams();
//   const [showSuccess, setShowSuccess] = useState(false);

//   const [resId, setResId] = useState(null);
//   const [email, setEmail] = useState(resId);
//   // const [resAvail, setResAvail] = useEffect(true);
//   const [resInfo, setRestInfo] = useState();
//   const [newMenu, setNewMenu] = useState([]); // it is in simple way all item arrange without cateogry that is taken from ba

//   const [newMenu2, setNewMenu2] = useState({}); //it arrange the item in category wise to show pefectly
//   const [catList, setCatList] = useState([]); // it is category of item use to load images
//   const [showImages, setShowImages] = useState([]); //all image storage
//   // const [seeMore, setSeeMore] = useState(false); // it use to show only 4 item initially
//   const [order, setOrder] = useState([]);
//   const [pageControlFlag, setPageControlFlag] = useState(0); // it is used for contron cart/menu/payment
//   const [totalPayment, setTotalPayment] = useState(0); // it is use to calculate all price
//   const [sendOrder, setSendOrder] = useState([]);
//   const [tableNo, setTableNo] = useState([]);
//   const [cartEmpty, setCartEmpty] = useState(sendOrder.length);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [subItemCatName, setSubItemCatName] = useState();
//   const [pause, setPause] = useState("hover");

//   const handleMouseEnter = () => {
//     setPause("hover mouseEnter");
//   };

//   // const [isOverflowHidden, setIsOverflowHidden] = useState(true);
//   const [sugg, setSugg] = useState("");
//   const [newMenu3, setNewMenu3] = useState();
//   const [seeList, setSeeList] = useState();
//   const [resStatus, setResStatus] = useState("");
//   // const [resStatus, setResStatus] = useState(true);
//   const [resDataUpdate, setResDataUpdate] = useState(false);
//   // const [resDataUpdate, setResDataUpdate] = useState(true);

//   const [selectedItemCate, setSelectedItemCate] = useState({
//     item: "",
//     category: "",
//   });
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [seeSearchedItem, setSeeSearchedItem] = useState(false);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 3, // Set the number of slides to show at once
//     // slidesToScroll: 1,
//     // swipeToSlide: true,
//     // autoplaySpeed: 1000,
//     // arrows: false,
//   };
//   // 0 = menu  1 = cart 2 = payment

//   const initializeRawData = () => {
//     const rawResInfo = {
//       nameOfCafe: "....",
//       add: {
//         street: "",
//         city: "",
//         state: "",
//         pCode: "",
//       },
//       mo: "",
//       GST: "",
//     };

//     const rawMenudata = [
//       {
//         cateName: ".",
//         iName: "",
//         price: "",
//         foodType: "Veg",
//         preparationTime: "",
//         des: "",
//         status: true,
//       },

//       {
//         cateName: "..",
//         iName: "",
//         price: "",
//         foodType: "Veg",
//         preparationTime: "",
//         des: "",
//         status: true,
//       },

//       {
//         cateName: "...",
//         iName: "",
//         price: "",
//         foodType: "",
//         preparationTime: "",
//         des: "",
//         status: true,
//       },
//     ];

//     const restaurant = rawResInfo;
//     const menu = rawMenudata;

//     return { restaurant, menu };
//   };

//   const transformMenu = (menu) => {
//     setNewMenu(menu);
//     const uniqueCateNames = [...new Set(menu.map((item) => item.cateName))];
//     const combinedMenu = [...catList, ...uniqueCateNames];
//     setCatList(combinedMenu);

//     LoadImages(uniqueCateNames);

//     // convert menu into different form
//     const transformedMenu = {};

//     menu.forEach((item) => {
//       const { cateName, ...rest } = item;

//       if (!transformedMenu[cateName]) {
//         transformedMenu[cateName] = [];
//       }

//       transformedMenu[cateName].push(rest);
//     });

//     setNewMenu2(transformedMenu);
//   };

//   const handleResInfo = async () => {
//     const { restaurant, menu } = await initializeRawData();
//     setRestInfo(restaurant);
//     setNewMenu(menu);
//     await transformMenu(menu);

//     // while (resAvail) {
//     let resIdji;
//     if (!resId) {
//       resIdji = prompt("Please Enter Restauant Number : ");
//       setResId(resIdji);
//     }
//     while (isNaN(resIdji)) {
//       alert("please Enter a valid number");
//       resIdji = prompt("Please Enter Restauant Number : ");
//       setResId(resIdji);
//     }

//     try {
//       const response = await axios.get(
//         process.env.REACT_APP_ADD_RES_INFO + `?noOfReg=${parseInt(resIdji)}`
//         //   `https://digital-menu-backend3.onrender.com/user/resInfo?noOfReg=${parseInt(
//         //   resIdji
//         // )}`
//       );
//       const { restaurant, menu } = response.data;
//       setRestInfo(restaurant);
//       await transformMenu(menu);
//       // setResStatus(response.data.restaurant.status);
//       setResStatus(true);
//       setResDataUpdate(true);
//       // setResAvail(false);
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         // Handle 404 error (Not Found) gracefully, e.g., display a user-friendly message
//         setResDataUpdate(true);
//         // setResAvail(true);
//       }
//     }
//     // }
//   };

//   const LoadImages = async (combinedMenu) => {
//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_ADD_LOAD_IMAGE,
//         {
//           email: "manish",
//           pass: "manish",
//           catList: combinedMenu,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         setShowImages(response.data); // Set retrieved image data
//         // alert("Please wait, images are loading");
//       } else {
//         console.error("Request failed");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   const addItemToOrder = (category, item) => {
//     // Create a new object with all the fields

//     const newItem = {
//       category,
//       item: {
//         iName: item.iName,
//         price: parseInt(item.price, 10),
//         foodType: item.foodType,
//         preparationTime: item.preparationTime,
//       },
//       quantity: parseInt(1, 10),
//     };

//     // Check if the item is already in the order
//     const itemIndex = order.findIndex(
//       (orderItem) => orderItem.item.iName === item.iName
//     );

//     if (itemIndex !== -1) {
//       // If the item already exists, update its quantity
//       const updatedOrder = [...order];
//       updatedOrder[itemIndex].quantity += 1;

//       // Check if the updated quantity is greater than 0, and if not, remove the item
//       if (updatedOrder[itemIndex].quantity <= 0) {
//         updatedOrder.splice(itemIndex, 1);
//       }

//       setOrder(updatedOrder);
//     } else {
//       // If the item doesn't exist, add it to the order
//       setOrder([...order, newItem]);
//     }
//     console.log(order);
//   };

//   useEffect(() => {
//     const formattedOrders = order.map((initialOrder) => ({
//       category: initialOrder.category,
//       iName: initialOrder.item.iName,
//       price: initialOrder.item.price,
//       foodType: initialOrder.item.foodType,
//       preparationTime: initialOrder.item.preparationTime,
//       quantity: initialOrder.quantity,
//     }));
//     setSendOrder(formattedOrders);
//     setCartEmpty(sendOrder.length);

//     const calculatedTotalPrice = formattedOrders.reduce((total, order) => {
//       // Check if price is a valid number before adding it to the total
//       if (!isNaN(order.price)) {
//         return total + order.price * order.quantity;
//       }
//       return total;
//     }, 0);

//     // Update total price state
//     setTotalPayment(calculatedTotalPrice);
//   }, [order]);

//   const clickToOrder = () => {
//     const postData = {
//       param: resId,
//       tableNo: tableNo === "" ? 0 : tableNo,
//       sendOrder: sendOrder,
//       totalPayment: totalPayment,
//       sugg: sugg,
//     };
//     if (sendOrder.length !== 0) {
//       console.log("sugg", sugg);
//       axios
//         .post(process.env.REACT_APP_ADD_ORDER_SEND, postData)
//         .then((response) => {
//           // Handle the response from the server if needed
//           console.log("Server response:", response.data);
//           setShow(false);
//           setShowSuccess(true);
//         })
//         .catch((error) => {
//           // Handle errors if the request fails
//           console.error("Error sending data to the server:", error);
//         });
//     }
//   };

//   // const toggleOverflow = () => {
//   //   setIsOverflowHidden(!isOverflowHidden);
//   // };

//   useEffect(() => {
//     handleResInfo();
//   }, []);

//   /// model control for input table
//   const [show, setShow] = useState(false);

//   const handleClose = () => {
//     setShow(false);
//     setTableNo("");
//     setSugg("");
//   };
//   const handleModelTableNo = () => {
//     {
//       sendOrder.length > 0 ? setShow(true) : <p></p>;
//     }
//   };

//   const handleTableNo = (e) => {
//     setTableNo(e.target.value);
//     console.log("table No", tableNo);
//   };

//   const handleSugg = (e) => {
//     setSugg(e.target.value);
//   };

//   const scrollToProperties = (categoryId) => {
//     let scrollOptions = {};
//     if (seeSearchedItem === false) {
//       scrollOptions = null; // or provide alternative options
//     } else {
//       scrollOptions = {
//         duration: 100,
//         smooth: "easeOutCubic",
//         offset: -100,
//       };
//     }

//     scroller.scrollTo(categoryId, scrollOptions);
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({
//       top: document.documentElement.scrollHeight, // Scrolls to the bottom of the page
//       behavior: "smooth",
//     });
//   };

//   const scrollToTop = () => {
//     animateScroll.scrollToTop({
//       duration: 10, // Adjust the duration as needed
//       delay: 0,
//       smooth: "easeInOutQuart", // Use your preferred easing function
//     });
//   };

//   const handleSearch = (event) => {
//     const newSearchTerm = event.target.value;
//     // const newSearchTerm = "manish"
//     setSearchTerm(newSearchTerm);
//   };

//   const emptySearch = () => {
//     let result = { item: "", category: "" };
//     setSearchTerm("");
//     setSelectedItemCate(result);
//   };

//   const searchResults = useMemo(() => {
//     if (!subItemCatName) {
//       return null; // or return an appropriate value
//     }

//     const results = {};

//     // Iterate over categories in subItemCatName
//     Object.keys(subItemCatName).forEach((category) => {
//       // Filter items in the current category based on searchTerm
//       const filteredItems = subItemCatName[category].filter((item) =>
//         item.toLowerCase().includes(searchTerm.toLowerCase())
//       );

//       // If there are matching items, add the entire category to the results object
//       if (filteredItems.length > 0) {
//         results[category] = subItemCatName[category];
//       }
//     });

//     const mergedResults = {};

//     // Iterate over categories in searchResults
//     Object.keys(results).forEach((category) => {
//       // Add the matching items to the newMenu3 object
//       mergedResults[category] = results[category].map((itemName) => {
//         // Find the corresponding item in newMenu2
//         return newMenu2[category].find((item) => item.iName === itemName);
//       });
//     });

//     setNewMenu3(mergedResults);
//     return results;
//   }, [subItemCatName, searchTerm]);

//   useEffect(() => {
//     const convertedData = {};
//     Object.keys(newMenu2).forEach((category) => {
//       convertedData[category] = newMenu2[category].map((item) => item.iName);
//     });

//     setSubItemCatName(convertedData);
//   }, [newMenu2]);

//   // const selectItemFun = ({ cate, name }) => {
//   //   setSelectedItemCate(cate);

//   //   let result = { item: "", category: "" };

//   //   Object.keys(newMenu2).forEach((category) => {
//   //     newMenu2[category].forEach((item) => {
//   //       if (item.iName === name && category === cate) {
//   //         result = { item, category };
//   //       }
//   //     });
//   //   });

//   //   setSelectedItemCate(result);
//   // };

//   useEffect(() => {
//     if (searchTerm === "") {
//       let result = { item: "", category: "" };
//       setSelectedItemCate(result);
//     }
//   }, [searchTerm]);

//   const handleCategoryChange = (event) => {
//     const selectedValue = event.target.value;
//     setSelectedCategory(selectedValue);
//   };

//   // Function to filter data and return only veg items
//   const filterVegItems = (data) => {
//     const vegItems = {};

//     Object.keys(data).forEach((category) => {
//       const categoryItems = data[category];
//       const vegItemsInCategory = categoryItems.filter(
//         (item) => item.foodType === "Veg"
//       );

//       if (vegItemsInCategory.length > 0) {
//         vegItems[category] = vegItemsInCategory;
//       }
//     });

//     return vegItems;
//   };

//   const filterNonVegItems = (data) => {
//     const nonVegItems = {};

//     Object.keys(data).forEach((category) => {
//       const categoryItems = data[category];
//       const nonVegItemsInCategory = categoryItems.filter(
//         (item) => item.foodType === "NVeg"
//       );

//       if (nonVegItemsInCategory.length > 0) {
//         nonVegItems[category] = nonVegItemsInCategory;
//       }
//     });

//     return nonVegItems;
//   };

//   useEffect(() => {
//     const result =
//       searchTerm === ""
//         ? selectedCategory === "Veg"
//           ? filterVegItems(newMenu2)
//           : selectedCategory === "NVeg"
//           ? filterNonVegItems(newMenu2)
//           : newMenu2
//         : selectedCategory === "Veg"
//         ? filterVegItems(newMenu3)
//         : selectedCategory === "NVeg"
//         ? filterNonVegItems(newMenu3)
//         : newMenu3;

//     setSeeList(result);
//     setSeeSearchedItem(false);
//   }, [searchTerm, newMenu2, newMenu3, selectedCategory]);

//   useEffect(() => {
//     // const disableButtons = () => {
//     //   const buttons = document.querySelectorAll("button");
//     //   buttons.forEach((button) => {
//     //     button.disabled = true;
//     //   });
//     // };

//     // disableButtons();

//     if (resStatus === true) {
//       // document.body.style.overflow = "auto";
//     }
//     if (resStatus === "") {
//       // document.body.style.overflow = "hidden";
//     }
//   }, [resStatus]);

//   const searchClickItem = (selectedCate, categoryItem) => {
//     let result = {};

//     const showObj =
//       selectedCategory === ""
//         ? newMenu2
//         : selectedCategory === "Veg"
//         ? filterVegItems(newMenu2)
//         : filterNonVegItems(newMenu2);
//     const r = newMenu[selectedCate];
//     if (!categoryItem) {
//       const categoryItems = showObj[selectedCate];
//       result[selectedCate] = categoryItems;
//     } else {
//       const categoryItems = showObj[selectedCate];
//       const resultInCategory = categoryItems.filter(
//         (item) => item.iName === categoryItem
//       );

//       if (resultInCategory.length > 0) {
//         result[selectedCate] = resultInCategory;
//       }
//     }
//     setSeeSearchedItem(true);
//     setSeeList(result);
//     console.log(result);

//     return result;
//   };
//   window.addEventListener("click", function (e) {
//     var searchedItemHead = document.querySelector(".searchedItemHead");

//     if (searchedItemHead && !searchedItemHead.contains(e.target)) {
//       setSeeSearchedItem(true);
//     }
//   });

//   useEffect(() => {
//     // Create a new array with the default values based on catList
//     const updatedShowImages = catList.map((catItem) => ({
//       cateName: catItem,
//       cateImg: DefaultImg,
//     }));
//     // Update the state with the new array
//     setShowImages(updatedShowImages);
//   }, [catList, DefaultImg]);

//   const remainder = showImages.length % 3;
//   let imagesToAdd = [];
//   if (remainder === 1) {
//     imagesToAdd = showImages.slice(0, 2);
//   } else if (remainder === 2) {
//     imagesToAdd = [showImages[0]];
//   }
//   const updatedShowImages = [...showImages, ...imagesToAdd];
//   const groupedImages = [];
//   for (let i = 0; i < updatedShowImages.length; i += 3) {
//     groupedImages.push(updatedShowImages.slice(i, i + 3));
//   }
//   const handleCloseSuccess = () => {
//     setShowSuccess(false);
//   };
//   return (
//     <div>
//       <div
//         className="UserHead"
//         style={{
//           filter:
//             resDataUpdate === false || resStatus !== true
//               ? "blur(2px)"
//               : "none",
//         }}
//       >
//         {newMenu.length > 0 && resInfo.nameOfCafe !== "" ? (
//           <div className="userPageHead ">
//             {pageControlFlag === 0 ? (
//               <div className="menu">
//                 <div className="userPageInside">
//                   {/* navbar */}
//                   <div className="navUser">
//                     <div className="shopLogo">
//                       <img alt="" src={Logo} />
//                     </div>
//                     <div className="shopName">
//                       <h1>
//                         {resInfo
//                           ? resInfo.nameOfCafe || "Shop Name"
//                           : "Loading..."}
//                       </h1>
//                       <h6>Our Name</h6>
//                     </div>
//                   </div>
//                   <div className="container">
//                     <div className="inputSearchContainer">
//                       <input
//                         type="text"
//                         placeholder="Search..."
//                         value={searchTerm}
//                         disabled={resStatus == true ? false : true}
//                         onChange={handleSearch}
//                       />
//                       <button className="crossButton" onClick={emptySearch}>
//                         X
//                       </button>
//                     </div>

//                     <div
//                       style={{
//                         display: seeSearchedItem === true ? "none" : "block",
//                       }}
//                       className="searchedItemHead"
//                     >
//                       <div className="searchedItem">
//                         {searchTerm !== ""
//                           ? Object.keys(searchResults).map((category) => (
//                               <div key={category}>
//                                 <button
//                                   className="searchCate"
//                                   onClick={() => searchClickItem(category, "")}
//                                 >
//                                   {category}
//                                 </button>
//                                 <ul>
//                                   {searchResults[category].map((itemName) => (
//                                     <button
//                                       key={itemName}
//                                       className="searchItemCate"
//                                       onClick={() =>
//                                         searchClickItem(category, itemName)
//                                       }
//                                     >
//                                       {/* Render other information about the item here */}
//                                       {itemName}
//                                     </button>
//                                   ))}
//                                 </ul>
//                               </div>
//                             ))
//                           : null}
//                       </div>
//                     </div>
//                     <div>
//                       {searchTerm !== "" &&
//                       newMenu3 &&
//                       Object.keys(newMenu3).length === 0 ? (
//                         <p className="noItemFound">No items found</p>
//                       ) : null}
//                     </div>
//                     {/* Advertisement */}
//                     <div className="Advertise">
//                       <img alt="" src={Ad}></img>
//                     </div>
//                     {/* line of explore */}
//                     <div className="exploreLine">
//                       <span className="hr1"></span>
//                       <p className="exploreWord">explore</p>
//                       <span className="hr1"></span>
//                     </div>
//                     {/* show menu and cate of veg and non-veg */}
//                     <div className="logoAndCate">
//                       <div className="menuName">
//                         <h3 className="logoIcon">≡</h3>
//                         <h3 className="menu">Menu</h3>
//                       </div>
//                       <div className="cateName">
//                         <select
//                           className="category"
//                           name="fruit"
//                           defaultValue=""
//                           onChange={handleCategoryChange}
//                           disabled={resStatus == true ? false : true}
//                         >
//                           <option className="man" value="">
//                             Category (Both)
//                           </option>
//                           <option value="Veg">Veg</option>
//                           <option value="NVeg">Non-Veg</option>
//                         </select>
//                       </div>
//                     </div>
//                     {/* showing category */}

//                     {/* <div
//                       className="cateSection"
//                       style={{ display: seeSearchedItem == true ? "none" : "" }}
//                     >
//                       <Slider {...settings} className="sliderCateSection">
//                         {(showImages.length > 0 ? showImages : catList).map(
//                           (item, index) => (
//                             <div
//                               key={index + item}
//                               className="cateSingleItem"
//                               onClick={() =>
//                                 scrollToProperties(item.cateName || item)
//                               }
//                             >
//                               <div className="cateItem">
//                                 <img
//                                   className="categoryshowImage"
//                                   src={item.cateImg || DefaultImg}
//                                   alt={item.cateName || item}
//                                 />
//                               </div>
//                               <div className="cateShowName">
//                                 <h5>{item.cateName || item}</h5>
//                               </div>
//                             </div>
//                           )
//                         )}
//                       </Slider>
//                     </div> */}

//                     {/* <div className="cateSection">
//                       <Carousel
//                         // interval={settings.autoplaySpeed}
//                         interval="200"
//                         pause="hover mouseEnter"
//                         // pause="hover"
//                         controls={false}
//                         onMouseEnter={handleMouseEnter}
//                         className="sliderCateSection"
//                       >
//                         {(showImages.length > 0 ? showImages : catList).map(
//                           (item, index) => (
//                             <Carousel.Item
//                               key={index + item}
//                               className="cateSingleItem"
//                               onClick={() =>
//                                 scrollToProperties(item.cateName || item)
//                               }
//                             >
//                               <div className="cateItem">
//                                 <img
//                                   className="categoryshowImage"
//                                   src={item.cateImg || DefaultImg}
//                                   alt={item.cateName || item}
//                                 />
//                               </div>
//                               <div className="cateShowName">
//                                 <h5>{item.cateName || item}</h5>
//                               </div>
//                             </Carousel.Item>
//                           )
//                         )}
//                       </Carousel>
//                     </div> */}

//                     <div
//                       className="cateSection"
//                       style={{ display: seeSearchedItem == true ? "none" : "" }}
//                     >
//                       <Carousel
//                         // interval={settings.interval}
//                         // {...settings}
//                         // interval="500"
//                         pause={pause}
//                         controls={false}
//                         className="sliderCateSection"
//                         onMouseEnter={handleMouseEnter}
//                         indicators={false}
//                       >
//                         {groupedImages.map((group, groupIndex) => (
//                           <Carousel.Item
//                             key={groupIndex}
//                             className="cateSingleItem"
//                           >
//                             <div className="d-flex groupImage">
//                               {group.map((item, itemIndex) => (
//                                 <div
//                                   key={itemIndex + item.cateName}
//                                   className="cateSingleItem oneImageSection"
//                                   onClick={() =>
//                                     scrollToProperties(item.cateName || item)
//                                   }
//                                 >
//                                   <img
//                                     className="categoryshowImage"
//                                     src={
//                                       (showImages.length > 0
//                                         ? item.cateImg || DefaultImg
//                                         : DefaultImg) || DefaultImg
//                                     }
//                                     alt={item.cateName || item}
//                                   />
//                                   <div className="cateShowName">
//                                     <h5>{item.cateName || item}</h5>
//                                   </div>
//                                 </div>
//                               ))}
//                             </div>
//                           </Carousel.Item>
//                         ))}
//                       </Carousel>
//                     </div>

//                     <div className="des-view">
//                       {seeList &&
//                         Object.keys(seeList).map((category, index) => (
//                           <div
//                             className="bothHeadSub"
//                             key={category + index}
//                             id={category}
//                           >
//                             <div className="head-cate">
//                               {showImages.map((image) => {
//                                 if (image.cateName === category) {
//                                   return (
//                                     <img
//                                       className="subCateImg"
//                                       key={image.cateName}
//                                       src={image.cateImg || DefaultImg}
//                                       alt={image.cateName}
//                                     />
//                                   );
//                                 }
//                                 return null;
//                               })}

//                               {!showImages.some(
//                                 (image) => image.cateName === category
//                               ) && <img src={DefaultImg} alt="Default" />}
//                               <h2 className="head-cate-name">{category}</h2>
//                             </div>

//                             <div className="sub-cate">
//                               {seeList[category].map((item, index) => (
//                                 <div className="item" key={item.iName + index}>
//                                   <div className="subItemJiHead">
//                                     {selectedItemCate.item.iName !==
//                                       item.iName && (
//                                       <div className="subItemTitle">
//                                         <div className="subItemContent">
//                                           <h5 className="itemName">
//                                             {item.iName}
//                                           </h5>
//                                           <h5 className="itemPrice">
//                                             {" "}
//                                             ₹ &#160;{item.price}{" "}
//                                           </h5>
//                                           <h5
//                                             style={{
//                                               color:
//                                                 item.foodType === "Veg"
//                                                   ? "lightgreen"
//                                                   : "red",
//                                             }}
//                                           >
//                                             ●
//                                           </h5>
//                                         </div>
//                                         <div className="subItemAddButton">
//                                           {order.map((orderItem) =>
//                                             orderItem.item.iName ===
//                                             item.iName ? (
//                                               <div
//                                                 key={
//                                                   orderItem.item.iName +
//                                                   orderItem.category
//                                                 }
//                                               >
//                                                 <button
//                                                   className="addSubQuan"
//                                                   onClick={() =>
//                                                     setOrder((prevOrder) => {
//                                                       const updatedOrder =
//                                                         prevOrder.map(
//                                                           (orderItem) =>
//                                                             orderItem.item
//                                                               .iName ===
//                                                               item.iName &&
//                                                             orderItem.quantity >
//                                                               0
//                                                               ? {
//                                                                   ...orderItem,
//                                                                   quantity:
//                                                                     orderItem.quantity -
//                                                                     1,
//                                                                 }
//                                                               : orderItem
//                                                         );

//                                                       return updatedOrder.filter(
//                                                         (orderItem) =>
//                                                           orderItem.quantity > 0
//                                                       );
//                                                     })
//                                                   }
//                                                 >
//                                                   -
//                                                 </button>
//                                                 <span className="quantity">
//                                                   {orderItem.quantity}
//                                                 </span>
//                                                 <button
//                                                   className="addSubQuan"
//                                                   onClick={() =>
//                                                     setOrder((prevOrder) =>
//                                                       prevOrder.map(
//                                                         (orderItem) =>
//                                                           orderItem.item
//                                                             .iName ===
//                                                           item.iName
//                                                             ? {
//                                                                 ...orderItem,
//                                                                 quantity:
//                                                                   orderItem.quantity +
//                                                                   1,
//                                                               }
//                                                             : orderItem
//                                                       )
//                                                     )
//                                                   }
//                                                 >
//                                                   +
//                                                 </button>
//                                               </div>
//                                             ) : null
//                                           )}
//                                           {order.some(
//                                             (orderItem) =>
//                                               orderItem.item.iName ===
//                                               item.iName
//                                           ) ? null : (
//                                             <button
//                                               className="AddItemBtn"
//                                               onClick={() =>
//                                                 addItemToOrder(category, item)
//                                               }
//                                             >
//                                               Add
//                                             </button>
//                                           )}
//                                         </div>
//                                       </div>
//                                     )}
//                                   </div>
//                                 </div>
//                               ))}
//                             </div>
//                           </div>
//                         ))}
//                     </div>
//                     {/* restaurants information */}
//                     <div className="resInfo">
//                       {resInfo && (
//                         <div className="resInfoDetails">
//                           <h2>Restaurant Details</h2>
//                           <p>
//                             <span>Name:</span> {resInfo.nameOfCafe}
//                           </p>
//                           <p>
//                             <span>Address:</span> {resInfo.add.street},{" "}
//                             {resInfo.add.city}, {resInfo.add.state},{" "}
//                             {resInfo.add.pCode}
//                           </p>
//                           <p>
//                             <span>Mobile:</span> {resInfo.mo}
//                           </p>
//                           <p>
//                             <span>GST:</span> {resInfo.gst}
//                           </p>
//                         </div>
//                       )}
//                     </div>
//                     {/* <button className="resInfoBtn" onClick={handleResInfo}>
//                     Click for Restaurant Info
//                   </button> */}
//                   </div>
//                   <div className="scrollBtn">
//                     <div className="scrollBtnInside">
//                       <button className="moveTop" onClick={scrollToTop}>
//                         ▲
//                       </button>
//                       <button className="moveBottom" onClick={scrollToBottom}>
//                         ▼
//                       </button>
//                     </div>
//                   </div>
//                   <div className="stickyFooter">
//                     <button onClick={() => setPageControlFlag(0)}>
//                       <img src={Menu} alt=""></img>
//                       <h6>Home</h6>
//                     </button>

//                     <button onClick={() => setPageControlFlag(1)}>
//                       <img src={CartBtn} alt=""></img>
//                       <h6>Cart</h6>
//                     </button>
//                     <button onClick={() => setPageControlFlag(2)}>
//                       <img src={Pay5} alt=""></img>
//                       <h6 className="payWord">Payment</h6>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ) : pageControlFlag === 1 ? (
//               <div className="container">
//                 <div className="cart">
//                   <div className="cartHeadArrowWord">
//                     <div
//                       className="cartLeftArrow"
//                       onClick={() => setPageControlFlag(0)}
//                     >
//                       {" "}
//                       <h1>&lt; </h1>{" "}
//                     </div>
//                     <div className="cartWord">
//                       {" "}
//                       <h1>Cart</h1>{" "}
//                     </div>
//                   </div>
//                   <div className="cartSection">
//                     <div className="cartItems">
//                       {order.length > 0 ? (
//                         <div>
//                           {order.map((orderItem) => (
//                             <div
//                               className="cartHead"
//                               key={orderItem.item.iName}
//                             >
//                               <div className="cartNameAndPrice">
//                                 <div>{orderItem.item.iName}</div>
//                                 <div>₹ {orderItem.item.price}</div>
//                               </div>
//                               <div className="cartQuant">
//                                 <div className="cartQunatInside">
//                                   <button
//                                     className="minCart"
//                                     onClick={() =>
//                                       setOrder((prevOrder) => {
//                                         const updatedOrder = prevOrder.map(
//                                           (prevOrderItem) =>
//                                             prevOrderItem.item.iName ===
//                                             orderItem.item.iName
//                                               ? {
//                                                   ...prevOrderItem,
//                                                   quantity:
//                                                     prevOrderItem.quantity - 1,
//                                                 }
//                                               : prevOrderItem
//                                         );

//                                         // Filter out items with quantity less than or equal to 0
//                                         return updatedOrder.filter(
//                                           (updatedOrderItem) =>
//                                             updatedOrderItem.quantity > 0
//                                         );
//                                       })
//                                     }
//                                   >
//                                     -
//                                   </button>
//                                   <div className="quanCart">
//                                     {orderItem.quantity}
//                                   </div>
//                                   {/* {typeof (orderItem.quantity)} */}

//                                   <button
//                                     className="maxCart"
//                                     onClick={() =>
//                                       setOrder((prevOrder) =>
//                                         prevOrder.map((prevOrderItem) =>
//                                           prevOrderItem.item.iName ===
//                                           orderItem.item.iName
//                                             ? {
//                                                 ...prevOrderItem,
//                                                 quantity:
//                                                   prevOrderItem.quantity + 1,
//                                               }
//                                             : prevOrderItem
//                                         )
//                                       )
//                                     }
//                                   >
//                                     +
//                                   </button>
//                                 </div>
//                               </div>
//                               <div className="cartCross">
//                                 <button
//                                   onClick={() =>
//                                     setOrder((prevOrder) =>
//                                       prevOrder.filter(
//                                         (prevOrderItem) =>
//                                           prevOrderItem.item.iName !==
//                                           orderItem.item.iName
//                                       )
//                                     )
//                                   }
//                                 >
//                                   &#x2716;{" "}
//                                   {/* Unicode character for a cross (×) */}
//                                 </button>
//                               </div>
//                             </div>
//                           ))}
//                           <div className="orderNow">
//                             <div className="orderNowInside">
//                               {totalPayment ? (
//                                 <h4>{"₹ " + totalPayment}</h4>
//                               ) : (
//                                 <h4>hello</h4>
//                               )}
//                               <button
//                                 className="btnOrderNow"
//                                 onClick={handleModelTableNo}
//                               >
//                                 Order Now
//                               </button>
//                               <div className="modalHead">
//                                 <Modal
//                                   show={show}
//                                   onHide={handleClose}
//                                   className="d-flex justify-content-center align-items-center modal "
//                                 >
//                                   <Modal.Header closeButton>
//                                     <Modal.Title>
//                                       Enter Table Number
//                                     </Modal.Title>
//                                   </Modal.Header>
//                                   <Modal.Body>
//                                     <p>Default for 0</p>
//                                     <input
//                                       type="number"
//                                       className="tableModel"
//                                       value={tableNo}
//                                       onChange={handleTableNo}
//                                       placeholder="Enter Table Number"
//                                     />
//                                     <p>Enter Some suggestion</p>
//                                     <textarea
//                                       rows="3"
//                                       className="suggTextArea"
//                                       value={sugg}
//                                       onChange={handleSugg}
//                                       placeholder="Enter Some suggestion"
//                                       cols="50"
//                                     ></textarea>
//                                   </Modal.Body>
//                                   <Modal.Footer className="modalButtonHead">
//                                     <Button
//                                       variant="primary"
//                                       onClick={clickToOrder}
//                                       className="modalButton"
//                                     >
//                                       Checkout
//                                     </Button>
//                                     <Button
//                                       variant="secondary"
//                                       onClick={handleClose}
//                                       className="modalButton"
//                                     >
//                                       Close
//                                     </Button>
//                                   </Modal.Footer>
//                                 </Modal>

//                                 <Modal
//                                   show={showSuccess}
//                                   onHide={handleCloseSuccess}
//                                   className="d-flex justify-content-center align-items-center modal headModalSucess "
//                                 >
//                                   <Modal.Header closeButton></Modal.Header>
//                                   <Modal.Body>
//                                     <h2>Your Order Submitted Successfully</h2>
//                                   </Modal.Body>
//                                 </Modal>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       ) : (
//                         <h3>Cart is empty</h3>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               <div className="payment container">
//                 <h1>This is payment Section</h1>
//                 <div className="stickyFooter">
//                   <button onClick={() => setPageControlFlag(0)}>
//                     <img src={Menu} alt=""></img>
//                     <h6>Home</h6>
//                   </button>

//                   <button onClick={() => setPageControlFlag(1)}>
//                     <img src={CartBtn} alt=""></img>
//                     <h6>Cart</h6>
//                   </button>
//                   <button onClick={() => setPageControlFlag(2)}>
//                     <img src={Pay5} alt=""></img>
//                     <h6 className="payWord">Payment</h6>
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         ) : null}
//         <div></div>
//       </div>

//       <div
//         className="scrollerCenter"
//         style={{ display: resStatus === true ? "none" : "" }}
//       >
//         {resDataUpdate === false ? (
//           <>
//             <div className="spinnerHead">
//               {/* <Spinner animation="border" className="custom-spinner" /> */}
//               <div className="spinner-border " role="status"></div>
//               <h2>Please Wait</h2>
//             </div>
//           </>
//         ) : (
//           <h2 style={{ display: resStatus === true ? "none" : "" }}>
//             {resStatus === ""
//               ? "Restaurent is not exist"
//               : resStatus === true
//               ? ""
//               : "restaurant is closed now"}
//           </h2>
//         )}
//       </div>
//     </div>
//   );
// }
// export default User3;

import React, { useEffect, useState, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import "./User3.css";
import DefaultImg from "./UserImages/DefaultImg.png";
import Logo from "./UserImages/Logo.jpg";
import Ad from "./UserImages/ad.jpeg";
import CartBtn from "./UserImages/logo/CartBtn.png";
import Menu from "./UserImages/logo/menu.png";
import Pay5 from "./UserImages/logo/pay5.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import html2canvas from "html2canvas";
import { scroller, animateScroll } from "react-scroll";

function User3() {
  // const clickBoxRef = useRef();
  const navigate = useNavigate();
  const date = new Date();
  const Navigate = useNavigate();
  // const { param } = useParams();
  // const [email, setEmail] = useState(param);
  const [resInfo, setRestInfo] = useState();
  const [newMenu, setNewMenu] = useState([]); // it is in simple way all item arrange without cateogry that is taken from ba

  const [newMenu2, setNewMenu2] = useState({}); //it arrange the item in category wise to show pefectly
  const [catList, setCatList] = useState([]); // it is category of item use to load images
  const [showImages, setShowImages] = useState([]); //all image storage
  // const [seeMore, setSeeMore] = useState(false); // it use to show only 4 item initially
  const [order, setOrder] = useState([]);
  const [pageControlFlag, setPageControlFlag] = useState(0); // it is used for contron cart/menu/payment
  const [totalPayment, setTotalPayment] = useState(0); // it is use to calculate all price
  const [sendOrder, setSendOrder] = useState([]);
  const [tableNo, setTableNo] = useState("");
  // const [cartEmpty, setCartEmpty] = useState(sendOrder.length);
  const [searchTerm, setSearchTerm] = useState("");
  const [subItemCatName, setSubItemCatName] = useState();

  const [sugg, setSugg] = useState("");
  const [newMenu3, setNewMenu3] = useState();
  const [seeList, setSeeList] = useState();
  const [resStatus, setResStatus] = useState("");

  const [resDataUpdate, setResDataUpdate] = useState(false);
  const [message, setMessage] = useState("");
  const [dataLoad, setDataLoad] = useState(false);

  const [selectedItemCate, setSelectedItemCate] = useState({
    item: "",
    category: "",
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [seeSearchedItem, setSeeSearchedItem] = useState(false);
  const [paymentOrderStatus, setPaymentOrderStatus] = useState(false);
  const [paymentSucess, setPaymentSucess] = useState(false);
  const [payId, setPayId] = useState("");
  const [orId, setOrId] = useState("");
  const [param, setParam] = useState("");

  let paramJi = "";
  let isValid = false;
  const handleResInfo = async () => {
    const { restaurant, menu } = await initializeRawData();
    setRestInfo(restaurant);
    setNewMenu(menu);
    await transformMenu(menu);
    while (!isValid) {
      paramJi = prompt("Please enter a five-digit number: dafault(10064)");
      console.log(paramJi);
      setParam(paramJi);

      if (paramJi === null) {
        navigate("/");
        break;
      } else if (paramJi === "") {
        alert("You didn't enter anything. Please enter a five-digit number.");
      } else {
        if (/^\d{5}$/.test(paramJi)) {
          isValid = true;
        } else {
          alert("Invalid input. Please enter a valid five-digit number.");
        }
      }
    }

    try {
      const response = await axios.get(process.env.REACT_APP_ADD_RES_INFO, {
        params: {
          noOfReg: parseInt(paramJi),
        },
      });

      if (response.status === 200) {
        const { restaurant, menu } = response.data;
        setRestInfo(restaurant);
        await transformMenu(menu);
        setResStatus(true);
        setResDataUpdate(true);
        setDataLoad(true);
      } else if (response.status == 201) {
        console.log(response.data.message);
        setDataLoad(true);
        setMessage(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setResDataUpdate(true);
        setDataLoad(true);
      }
    }
  };

  useEffect(() => {
    handleResInfo();
  }, [paramJi]);

  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 100,
    arrows: false,
  };
  // 0 = menu  1 = cart 2 = payment

  const initializeRawData = () => {
    const rawResInfo = {
      nameOfCafe: "....",
      add: {
        street: "",
        city: "",
        state: "",
        pCode: "",
      },
      mo: "",
      GST: "",
    };

    const rawMenudata = [
      {
        cateName: ".",
        iName: "",
        price: "",
        foodType: "Veg",
        preparationTime: "",
        des: "",
        status: true,
      },

      {
        cateName: "..",
        iName: "",
        price: "",
        foodType: "Veg",
        preparationTime: "",
        des: "",
        status: true,
      },

      {
        cateName: "...",
        iName: "",
        price: "",
        foodType: "",
        preparationTime: "",
        des: "",
        status: true,
      },
    ];

    const restaurant = rawResInfo;
    const menu = rawMenudata;

    return { restaurant, menu };
  };

  const transformMenu = (menu) => {
    setNewMenu(menu);
    const uniqueCateNames = [...new Set(menu.map((item) => item.cateName))];
    const combinedMenu = [...catList, ...uniqueCateNames];
    setCatList(combinedMenu);
    LoadImages(uniqueCateNames);

    // convert menu into different form
    const transformedMenu = {};

    menu.forEach((item) => {
      const { cateName, ...rest } = item;

      if (!transformedMenu[cateName]) {
        transformedMenu[cateName] = [];
      }

      transformedMenu[cateName].push(rest);
    });

    setNewMenu2(transformedMenu);
  };

  const LoadImages = async (combinedMenu) => {
    try {
      const response = await axios.post(
        // process.env.REACT_APP_ADD_LOAD_IMAGE,
        process.env.REACT_APP_ADD_LOAD_IMAGE,
        {
          email: "manish",
          pass: "manish",
          catList: combinedMenu,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setShowImages(response.data); // Set retrieved image data
        // alert("Please wait, images are loading");
      } else {
        console.error("Request failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const addItemToOrder = (category, item) => {
    // Create a new object with all the fields

    const newItem = {
      category,
      item: {
        iName: item.iName,
        price: parseInt(item.price, 10),
        foodType: item.foodType,
        preparationTime: item.preparationTime,
      },
      quantity: parseInt(1, 10),
    };

    // Check if the item is already in the order
    const itemIndex = order.findIndex(
      (orderItem) => orderItem.item.iName === item.iName
    );

    if (itemIndex !== -1) {
      // If the item already exists, update its quantity
      const updatedOrder = [...order];
      updatedOrder[itemIndex].quantity += 1;

      // Check if the updated quantity is greater than 0, and if not, remove the item
      if (updatedOrder[itemIndex].quantity <= 0) {
        updatedOrder.splice(itemIndex, 1);
      }

      setOrder(updatedOrder);
    } else {
      // If the item doesn't exist, add it to the order
      setOrder([...order, newItem]);
    }
  };

  useEffect(() => {
    const formattedOrders = order.map((initialOrder) => ({
      category: initialOrder.category,
      iName: initialOrder.item.iName,
      price: initialOrder.item.price,
      foodType: initialOrder.item.foodType,
      preparationTime: initialOrder.item.preparationTime,
      quantity: initialOrder.quantity,
    }));
    setSendOrder(formattedOrders);

    const calculatedTotalPrice = formattedOrders.reduce((total, order) => {
      // Check if price is a valid number before adding it to the total
      if (!isNaN(order.price)) {
        return total + order.price * order.quantity;
      }
      return total;
    }, 0);

    // Update total price state
    setTotalPayment(calculatedTotalPrice);
  }, [order]);

  const clickToOrder = async () => {
    setProcess1(true);
    setShow(false);
    setPaymentOrderStatus(true);
    await initiatePayment();
    setPaymentOrderStatus(false);
  };

  const [orderSendSucess, setOrderSendSuccess] = useState(false);

  useEffect(() => {
    if (paymentSucess) {
      setOrderSendSuccess(true);
      const postData = {
        param: paramJi,
        tableNo: tableNo === "" ? 0 : tableNo,
        sendOrder: sendOrder,
        totalPayment: totalPayment,
        sugg: sugg,
        orId: orId,
        payId: payId,
        transId: "",
      };

      if (sendOrder.length !== 0) {
        axios
          .post(process.env.REACT_APP_ADD_ORDER_SEND, postData)
          .then((response) => {
            setShow(false);
            setShowSuccess(true);
            setOrderSendSuccess(false);
          })
          .catch((error) => {
            // Handle errors if the request fails
            console.error("Error sending data to the server:", error);
          });
        setOrderSendSuccess(false);
      }
      setProcess1(false);
    }
  }, [paymentSucess]);

  /// model control for input table
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleClose = () => {
    setShow(false);
    setTableNo("");
    setSugg("");
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  const handleModelTableNo = () => {
    sendOrder.length > 0 ? setShow(true) : <p></p>;
  };

  const handleTableNo = (e) => {
    setTableNo(e.target.value);
    console.log("table No", tableNo);
  };

  const handleSugg = (e) => {
    setSugg(e.target.value);
  };

  const scrollToProperties = (categoryId) => {
    let scrollOptions = {};
    if (seeSearchedItem === false) {
      scrollOptions = null; // or provide alternative options
    } else {
      scrollOptions = {
        duration: 100,
        smooth: "easeOutCubic",
        offset: -100,
      };
    }

    scroller.scrollTo(categoryId, scrollOptions);
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight, // Scrolls to the bottom of the page
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 10, // Adjust the duration as needed
      delay: 0,
      smooth: "easeInOutQuart", // Use your preferred easing function
    });
  };

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value;
    // const newSearchTerm = "manish"
    setSearchTerm(newSearchTerm);
  };

  const emptySearch = () => {
    let result = { item: "", category: "" };
    setSearchTerm("");
    setSelectedItemCate(result);
  };

  const searchResults = useMemo(() => {
    if (!subItemCatName) {
      return null;
    }
    const results = {};
    Object.keys(subItemCatName).forEach((category) => {
      const filteredItems = subItemCatName[category].filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filteredItems.length > 0) {
        results[category] = subItemCatName[category];
      }
    });

    const mergedResults = {};

    Object.keys(results).forEach((category) => {
      if (newMenu2[category]) {
        const matchingItems = results[category].map((itemName) => {
          return newMenu2[category].find((item) => item.iName === itemName);
        });
        if (matchingItems.length > 0) {
          mergedResults[category] = matchingItems;
        }
      }
    });

    setNewMenu3(mergedResults);
    return results;
  }, [subItemCatName, searchTerm, newMenu2]);

  useEffect(() => {
    const convertedData = {};
    Object.keys(newMenu2).forEach((category) => {
      convertedData[category] = newMenu2[category].map((item) => item.iName);
    });

    setSubItemCatName(convertedData);
  }, [newMenu2]);

  useEffect(() => {
    if (searchTerm === "") {
      let result = { item: "", category: "" };
      setSelectedItemCate(result);
    }
  }, [searchTerm]);

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
  };

  // Function to filter data and return only veg items
  const filterVegItems = (data) => {
    const vegItems = {};

    Object.keys(data).forEach((category) => {
      const categoryItems = data[category];
      const vegItemsInCategory = categoryItems.filter(
        (item) => item.foodType === "Veg"
      );

      if (vegItemsInCategory.length > 0) {
        vegItems[category] = vegItemsInCategory;
      }
    });

    return vegItems;
  };

  const filterNonVegItems = (data) => {
    const nonVegItems = {};

    Object.keys(data).forEach((category) => {
      const categoryItems = data[category];
      const nonVegItemsInCategory = categoryItems.filter(
        (item) => item.foodType === "NVeg"
      );

      if (nonVegItemsInCategory.length > 0) {
        nonVegItems[category] = nonVegItemsInCategory;
      }
    });

    return nonVegItems;
  };

  useEffect(() => {
    const result =
      searchTerm === ""
        ? selectedCategory === "Veg"
          ? filterVegItems(newMenu2)
          : selectedCategory === "NVeg"
          ? filterNonVegItems(newMenu2)
          : newMenu2
        : selectedCategory === "Veg"
        ? filterVegItems(newMenu3)
        : selectedCategory === "NVeg"
        ? filterNonVegItems(newMenu3)
        : newMenu3;

    setSeeList(result);
    setSeeSearchedItem(false);
  }, [searchTerm, newMenu2, newMenu3, selectedCategory]);

  const searchClickItem = (selectedCate, categoryItem) => {
    let result = {};

    const showObj =
      selectedCategory === ""
        ? newMenu2
        : selectedCategory === "Veg"
        ? filterVegItems(newMenu2)
        : filterNonVegItems(newMenu2);
    if (!categoryItem) {
      const categoryItems = showObj[selectedCate];
      result[selectedCate] = categoryItems;
    } else {
      const categoryItems = showObj[selectedCate];
      const resultInCategory = categoryItems.filter(
        (item) => item.iName === categoryItem
      );

      if (resultInCategory.length > 0) {
        result[selectedCate] = resultInCategory;
      }
    }
    setSeeSearchedItem(true);
    setSeeList(result);
    console.log(result);
    return result;
  };

  window.addEventListener("click", function (e) {
    var searchedItemHead = document.querySelector(".searchedItemHead");

    if (searchedItemHead && !searchedItemHead.contains(e.target)) {
      setSeeSearchedItem(true);
    }
  });

  const initiatePayment = async () => {
    const amt = 5;
    try {
      const response = await axios.post(
        process.env.REACT_APP_ADD_PAYMENT_ORDER,
        { amount: amt }
      );
      const { data } = response;
      const options = {
        key: process.env.REACT_APP_PAYMENT_PUBLIC_KEY,
        amount: data.order.amount,
        currency: data.order.currency,
        order_id: data.order.id,
        name: resInfo.nameOfCafe,
        description: "Payment for Your Product",

        handler: async (response) => {
          // Handle success callback
          setPayId(response.razorpay_payment_id);
          setOrId(response.razorpay_order_id);

          // After successful payment, initiate transfer
          await initiateTransfer(
            response.razorpay_payment_id,
            data.order.amount
          );
        },
        prefill: {
          name: "Customer Name",
          email: "customer@example.com",
        },
        theme: {
          color: "#61dafb",
        },
      };
      // this is temporary
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const initiateTransfer = async (paymentId, transferAmount) => {
    setProcess1(true);
    console.log(param);
    try {
      const transferResponse = await axios.post(
        process.env.REACT_APP_ADD_PAYMENT_TRANSFER,
        {
          paymentId: paymentId,
          transferAmount: transferAmount,
          noOfReg: parseInt(param),
        }
      );
      if (transferResponse.status == 200) {
        setPaymentSucess(true);
      } else {
        console.log("Transfer Response:", transferResponse.data);
      }
    } catch (error) {
      console.error("Error initiating transfer:", error);
    }
  };

  useEffect(() => {
    const loadScript = async () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    };

    loadScript();
  }, []);

  const divRef = useRef(null);
  const handleDownloadClick = () => {
    // Capture the content of the div as an image
    html2canvas(divRef.current).then((canvas) => {
      // Create a canvas with the desired dimensions

      const resizedCanvas = document.createElement("canvas");
      const parentHeight = divRef.current.clientHeight;
      resizedCanvas.width = 300;
      resizedCanvas.height = parentHeight;
      const ctx = resizedCanvas.getContext("2d");

      // Scale and draw the captured image onto the resized canvas
      ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

      // Convert the resized canvas to a data URL
      const imageUrl = resizedCanvas.toDataURL("image/png");

      // Create a temporary link element
      const downloadLink = document.createElement("a");
      downloadLink.href = imageUrl;
      downloadLink.download = "downloaded_image.png"; // Set the file name

      // Trigger the download
      downloadLink.click();
    });
  };

  const [process1, setProcess1] = useState(false);

  return (
    <div className="user">
      {dataLoad == false ? (
        <div className="waitSection preventSection">
          <div className="spinner-border " role="status" />
          <p className="preventItem">Please Wait</p>
        </div>
      ) : message !== "" ? (
        <p className="preventItem preventSection"> {message}</p>
      ) : process1 ? (
        <div className="waitSection preventSection">
          <div className="spinner-border " role="status" />
        </div>
      ) : null}
      {/*  <div

      // className="scrollerCenter "
      // style={{ display: paymentOrderStatus || orderSendSucess ? "" : "none" }}
      >
        <Spinner
          className="growSpinnerUser"
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner> 
      </div>
*/}
      {/* //{" "} */}
      <div
      // className="UserHead"
      // style={{
      //   filter:
      //     resDataUpdate === false ||
      //     resStatus !== true ||
      //     paymentOrderStatus ||
      //     orderSendSucess
      //       ? "blur(2px)"
      //       : "none",
      // }}
      >
        {newMenu.length > 0 && resInfo.nameOfCafe !== "" ? (
          <div className="userPageHead ">
            {pageControlFlag === 0 ? (
              <div className="menu">
                <div className="userPageInside">
                  {/* navbar */}
                  <div className="navUser container">
                    {/* <div className="shopLogo">
                      <img alt="" src={Logo} />
                    </div> */}
                    <div className="shopName">
                      <h1>
                        {resInfo
                          ? resInfo.nameOfCafe || "Shop Name"
                          : "Loading..."}
                      </h1>
                      <h6>Our Name</h6>
                    </div>
                  </div>
                  <div className="container">
                    <div className="inputSearchContainer">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        disabled={resStatus === true ? false : true}
                        onChange={handleSearch}
                      />
                      <button className="crossButton" onClick={emptySearch}>
                        X
                      </button>
                    </div>

                    <div
                      style={{
                        display: seeSearchedItem === true ? "none" : "block",
                      }}
                      className="searchedItemHead"
                    >
                      <div className="searchedItem">
                        {searchTerm !== ""
                          ? Object.keys(searchResults).map((category) => (
                              <div key={category}>
                                <button
                                  className="searchCate"
                                  onClick={() => searchClickItem(category, "")}
                                >
                                  {category}
                                </button>
                                <ul>
                                  {searchResults[category].map((itemName) => (
                                    <button
                                      key={itemName}
                                      className="searchItemCate"
                                      onClick={() =>
                                        searchClickItem(category, itemName)
                                      }
                                    >
                                      {/* Render other information about the item here */}
                                      {itemName}
                                    </button>
                                  ))}
                                </ul>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    <div>
                      {searchTerm !== "" &&
                      newMenu3 &&
                      Object.keys(newMenu3).length === 0 ? (
                        <p className="noItemFound">No items found</p>
                      ) : null}
                    </div>
                    {/* Advertisement */}
                    <div className="Advertise">
                      <img alt="" src={Ad}></img>
                    </div>
                    {/* line of explore */}
                    <div className="exploreLine">
                      <span className="hr1"></span>
                      <p className="exploreWord">explore</p>
                      <span className="hr1"></span>
                    </div>
                    {/* show menu and cate of veg and non-veg */}
                    <div className="logoAndCate">
                      <div className="cateName">
                        <select
                          className="category"
                          name="fruit"
                          defaultValue=""
                          onChange={handleCategoryChange}
                          disabled={resStatus === true ? false : true}
                        >
                          <option className="man" value="">
                            Category (Both)
                          </option>
                          <option value="Veg">Veg</option>
                          <option value="NVeg">Non-Veg</option>
                        </select>
                      </div>
                    </div>
                    {/* showing category */}
                    <div
                      className="cateSection"
                      // style={{ display: seeSearchedItem == true ? "none" : "" }}
                    >
                      <Slider {...settings} className="sliderCateSection">
                        {(showImages.length > 0 ? showImages : catList).map(
                          (item, index) => (
                            <div
                              key={index + item}
                              className="cateSingleItem"
                              onClick={() =>
                                scrollToProperties(item.cateName || item)
                              }
                            >
                              <div className="cateItem">
                                <img
                                  className="categoryshowImage"
                                  src={item.cateImg || DefaultImg}
                                  alt={item.cateName || item}
                                />
                              </div>
                              <div className="cateShowName">
                                <h5>{item.cateName || item}</h5>
                                {/* <h3 className="discount">10%</h3> */}
                              </div>
                            </div>
                          )
                        )}
                      </Slider>
                    </div>
                    {/* {searchTerm === "" ? ( */}
                    <div className="des-view">
                      {seeList &&
                        Object.keys(seeList).map((category, index) => (
                          <div
                            className="bothHeadSub"
                            key={category + index}
                            id={category}
                          >
                            <div className="head-cate">
                              {showImages.map((image) => {
                                if (image.cateName === category) {
                                  return (
                                    <img
                                      className="subCateImg"
                                      key={image.cateName}
                                      src={image.cateImg || DefaultImg}
                                      alt={image.cateName}
                                    />
                                  );
                                }
                                return null;
                              })}

                              {!showImages.some(
                                (image) => image.cateName === category
                              ) && <img src={DefaultImg} alt="Default" />}
                              <h2 className="head-cate-name">{category}</h2>
                            </div>

                            <div className="sub-cate">
                              {seeList[category].map((item, index) => (
                                <div className="item" key={item.iName + index}>
                                  <div className="subItemJiHead">
                                    {selectedItemCate.item.iName !==
                                      item.iName && (
                                      <div className="subItemTitle">
                                        <div className="subItemContent">
                                          <h5 className="itemName">
                                            {item.iName}
                                          </h5>
                                          <h5 className="itemDes">
                                            {item.des}
                                          </h5>
                                          <h5 className="itemPrice">
                                            {" "}
                                            ₹ &#160;{item.price}{" "}
                                          </h5>
                                          <h5
                                            style={{
                                              color:
                                                item.foodType === "Veg"
                                                  ? "lightgreen"
                                                  : "red",
                                            }}
                                          >
                                            ●
                                          </h5>
                                        </div>
                                        <div className="subItemAddButton">
                                          {order.map((orderItem) =>
                                            orderItem.item.iName ===
                                            item.iName ? (
                                              <div
                                                key={
                                                  orderItem.item.iName +
                                                  orderItem.category
                                                }
                                              >
                                                <button
                                                  className="addSubQuan"
                                                  onClick={() =>
                                                    setOrder((prevOrder) => {
                                                      const updatedOrder =
                                                        prevOrder.map(
                                                          (orderItem) =>
                                                            orderItem.item
                                                              .iName ===
                                                              item.iName &&
                                                            orderItem.quantity >
                                                              0
                                                              ? {
                                                                  ...orderItem,
                                                                  quantity:
                                                                    orderItem.quantity -
                                                                    1,
                                                                }
                                                              : orderItem
                                                        );

                                                      return updatedOrder.filter(
                                                        (orderItem) =>
                                                          orderItem.quantity > 0
                                                      );
                                                    })
                                                  }
                                                >
                                                  -
                                                </button>
                                                <span className="quantity">
                                                  {orderItem.quantity}
                                                </span>
                                                <button
                                                  className="addSubQuan"
                                                  onClick={() =>
                                                    setOrder((prevOrder) =>
                                                      prevOrder.map(
                                                        (orderItem) =>
                                                          orderItem.item
                                                            .iName ===
                                                          item.iName
                                                            ? {
                                                                ...orderItem,
                                                                quantity:
                                                                  orderItem.quantity +
                                                                  1,
                                                              }
                                                            : orderItem
                                                      )
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            ) : null
                                          )}
                                          {order.some(
                                            (orderItem) =>
                                              orderItem.item.iName ===
                                              item.iName
                                          ) ? null : (
                                            <button
                                              className="AddItemBtn"
                                              onClick={() =>
                                                addItemToOrder(category, item)
                                              }
                                            >
                                              Add
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                    {/* restaurants information */}
                    <div className="resInfo">
                      {resInfo && (
                        <div className="resInfoDetails">
                          <h2>Restaurant Details</h2>
                          <p>
                            <span>Name:</span> {resInfo.nameOfCafe}
                          </p>
                          <p>
                            <span>Address:</span> {resInfo.add.street},{" "}
                            {resInfo.add.city}, {resInfo.add.state},{" "}
                            {resInfo.add.pCode}
                          </p>
                          <p>
                            <span>Mobile:</span> {resInfo.mo}
                          </p>
                          <p>
                            <span>GST:</span> {resInfo.gst}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="stickyFooter">
                    <button onClick={() => setPageControlFlag(0)}>
                      <img src={Menu} alt=""></img>
                      <h6>Home</h6>
                    </button>

                    <button onClick={() => setPageControlFlag(1)}>
                      <img src={CartBtn} alt=""></img>
                      <h6>Cart</h6>
                    </button>
                    <button onClick={() => setPageControlFlag(2)}>
                      <img src={Pay5} alt=""></img>
                      <h6 className="payWord">Payment</h6>
                    </button>
                  </div>
                </div>
              </div>
            ) : pageControlFlag === 1 ? (
              <div className="container">
                <div className="cart">
                  <div className="cartHeadArrowWord">
                    <div
                      className="cartLeftArrow"
                      onClick={() => setPageControlFlag(0)}
                    >
                      {" "}
                      <button className="arrowBtn">&lt; </button>{" "}
                    </div>
                    <div className="cartWord">
                      {" "}
                      <h1>Cart</h1>{" "}
                    </div>
                  </div>
                  <div className="cartSection">
                    <div className="cartItems">
                      {order.length > 0 ? (
                        <div>
                          {order.map((orderItem) => (
                            <div
                              className="cartHead"
                              key={orderItem.item.iName}
                            >
                              <div className="cartNameAndPrice">
                                <div>{orderItem.item.iName}</div>
                                <div>₹ {orderItem.item.price}</div>
                              </div>
                              <div className="cartQuant">
                                <div className="cartQunatInside">
                                  <button
                                    className="minCart"
                                    onClick={() =>
                                      setOrder((prevOrder) => {
                                        const updatedOrder = prevOrder.map(
                                          (prevOrderItem) =>
                                            prevOrderItem.item.iName ===
                                            orderItem.item.iName
                                              ? {
                                                  ...prevOrderItem,
                                                  quantity:
                                                    prevOrderItem.quantity - 1,
                                                }
                                              : prevOrderItem
                                        );

                                        // Filter out items with quantity less than or equal to 0
                                        return updatedOrder.filter(
                                          (updatedOrderItem) =>
                                            updatedOrderItem.quantity > 0
                                        );
                                      })
                                    }
                                  >
                                    -
                                  </button>
                                  <div className="quanCart">
                                    {orderItem.quantity}
                                  </div>
                                  {/* {typeof (orderItem.quantity)} */}

                                  <button
                                    className="maxCart"
                                    onClick={() =>
                                      setOrder((prevOrder) =>
                                        prevOrder.map((prevOrderItem) =>
                                          prevOrderItem.item.iName ===
                                          orderItem.item.iName
                                            ? {
                                                ...prevOrderItem,
                                                quantity:
                                                  prevOrderItem.quantity + 1,
                                              }
                                            : prevOrderItem
                                        )
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="cartCross">
                                <button
                                  onClick={() =>
                                    setOrder((prevOrder) =>
                                      prevOrder.filter(
                                        (prevOrderItem) =>
                                          prevOrderItem.item.iName !==
                                          orderItem.item.iName
                                      )
                                    )
                                  }
                                >
                                  &#x2716;{" "}
                                  {/* Unicode character for a cross (×) */}
                                </button>
                              </div>
                            </div>
                          ))}
                          <div className="orderNow">
                            <div className="orderNowInside">
                              {totalPayment ? (
                                <h4>{"₹ " + totalPayment}</h4>
                              ) : (
                                <h4>hello</h4>
                              )}
                              <button
                                className="btnOrderNow"
                                onClick={handleModelTableNo}
                              >
                                Order Now
                              </button>
                              <div className="modalHead">
                                <Modal
                                  show={show}
                                  onHide={handleClose}
                                  className="d-flex justify-content-center align-items-center modal "
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Enter Table Number
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <p>Default for 0</p>
                                    <input
                                      type="number"
                                      className="tableModel"
                                      value={tableNo}
                                      onChange={handleTableNo}
                                      placeholder="Enter Table Number"
                                    />
                                    <p>Enter Some suggestion</p>
                                    <textarea
                                      rows="3"
                                      className="suggTextArea"
                                      value={sugg}
                                      onChange={handleSugg}
                                      placeholder="Enter Some suggestion"
                                      cols="50"
                                    ></textarea>
                                  </Modal.Body>

                                  <Modal.Footer className="modalButtonHead">
                                    <div className="checkoutHead">
                                      <Button
                                        variant="primary"
                                        onClick={clickToOrder}
                                        className="modalButton"
                                      >
                                        CHECKOUT
                                      </Button>
                                      <Button
                                        variant="secondary"
                                        onClick={handleClose}
                                        className="modalButton"
                                      >
                                        Close
                                      </Button>
                                    </div>
                                    <button
                                      className="endUserTermsCond"
                                      onClick={() =>
                                        Navigate("/terms-condition-end-user")
                                      }
                                    >
                                      terms and conditions
                                    </button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <h3>Cart is empty</h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="payment container">
                <h1>This is payment Section</h1>
                <div className="stickyFooter">
                  <button onClick={() => setPageControlFlag(0)}>
                    <img src={Menu} alt=""></img>
                    <h6>Home</h6>
                  </button>

                  <button onClick={() => setPageControlFlag(1)}>
                    <img src={CartBtn} alt=""></img>
                    <h6>Cart</h6>
                  </button>
                  <button onClick={() => setPageControlFlag(2)}>
                    <img src={Pay5} alt=""></img>
                    <h6 className="payWord">Payment</h6>
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <div></div>
      </div>
      <Modal
        show={showSuccess}
        onHide={handleCloseSuccess}
        className="d-flex justify-content-center align-items-center modal headModalSucess billModal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="billBody">
          <div className="receipt" ref={divRef}>
            <div className="receipt_inside">
              <p className="cafeName">
                {resInfo == undefined ? "" : resInfo.nameOfCafe}
              </p>
              <p className="add">
                {resInfo === undefined
                  ? ""
                  : resInfo.add.street +
                    ", " +
                    resInfo.add.city +
                    ", " +
                    resInfo.add.state +
                    ", " +
                    resInfo.add.pCode}
              </p>

              <div className="nameSec">
                <p>table no. : {tableNo}</p>
                <p>
                  {`${date.getDate()}/${date.getMonth() + 1}/${date
                    .getFullYear()
                    .toString()
                    .slice(-2)}`}
                </p>
              </div>
              <div className="tabSec">
                <p>
                  {date.getHours() >= 12
                    ? `${date.getHours() / 2}:${date.getMinutes()} pm`
                    : `${date.getHours()}:${date.getMinutes()} am`}
                </p>
              </div>

              <div className="purchaseItem">
                {sendOrder &&
                  sendOrder.map((order, index) => (
                    <div key={index} className="item">
                      <p className="itemQuantity">{order.quantity}</p>
                      <p className="itemName">{order.iName}</p>
                      <p className="itemCost">
                        {" "}
                        Rs {order.price * order.quantity}
                      </p>
                    </div>
                  ))}
              </div>

              <table className="table1">
                <tbody>
                  <tr>
                    <td className="lAlign">SubTotal :</td>
                    <td className="rAlign">Rs {totalPayment}</td>
                  </tr>
                  <tr>
                    <td className="lAlign">Tax (0%)</td>
                    <td className="rAlign">Rs 0</td>
                  </tr>
                  <tr>
                    <td className="lAlign">Total :</td>
                    <td className="rAlign">Rs {totalPayment}</td>
                  </tr>
                  <tr>
                    <td className="lAlign">OrdId :</td>
                    <td className="rAlign">{orId}</td>
                  </tr>
                  <tr>
                    <td className="lAlign">payId :</td>
                    <td className="rAlign">{payId}</td>
                  </tr>
                </tbody>
              </table>

              <div className="tAmt">
                <p>Paid Amt : </p>
                <p>{totalPayment}</p>
              </div>

              <p className="billThank">Thank Your For Visiting</p>
            </div>
          </div>
          <div className="downloadQrHead">
            <button className="downloadQr" onClick={handleDownloadClick}>
              Download QR
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default User3;
