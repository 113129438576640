import React, { useState, useEffect } from "react";
import "./AboutUs.css";
import NavbarTop from "../../Navbar/NavbarTop";
import ManishImage from "./ManishPhoto.png";
import GaneshPhoto from "./ganesh.png";
import ManishS from "./ManishS.png";
import Footer from "../Footer/Footer";
function AboutUs() {
  const [manishContent, setManishContent] = useState(true);
  const [ganeshContent, setGaneshContent] = useState(true);
  const [manishSContent, setManishSContent] = useState(true);
  const [manishContact, setManishContact] = useState(false);
  const [ganeshContact, setGaneshContact] = useState(false);
  const [manishSContact, setManishSContact] = useState(false);

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <div className="aboutusPage">
      <div>
        <NavbarTop />
      </div>

      <div className="section1">
        <div className="section1Inside container">
          <div className="section1Items">
            {/* <h5>Welcome to EzzOrder</h5> */}
            <h1 className="aboutusTitle">About Us</h1>
            <p>
              EzzOrder is a pioneering tech-driven company focused on
              transforming the dining experience for small cafes and
              restaurants. Established with a vision to simplify and enhance the
              way people interact with menus and make payments, EzzOrder is at
              the forefront of the digitalization wave in the food industry.
            </p>
          </div>
          <div className="section1Items">
            <h5>Our Journey Begins</h5>
            <p>
              At EzzOrder, our journey is rooted in a passion for
              revolutionizing the dining experience for small cafes and
              restaurants. It all began with a vision to bring innovation to the
              heart of every meal, transforming the way people interact with
              menus and make payments.
            </p>
          </div>
          <div className="section1Items">
            <h5>The Birth of EzzOrder</h5>
            <p>
              EzzOrder was born out of a collective desire to address the
              challenges faced by small eateries. We envisioned a seamless and
              tech-driven solution that would empower these establishments to
              thrive in an increasingly digital world. The idea sparked from our
              belief that technology could be a catalyst for positive change,
              enhancing both customer satisfaction and operational efficiency.
            </p>
          </div>

          <div className="section1Items">
            <h5>Leading the Digitalization Wave</h5>
            <p>
              From day one, our focus has been on leading the charge in the
              digitalization wave sweeping through the food industry. We
              recognized the potential for technology to not only streamline
              processes but also elevate the overall dining experience. EzzOrder
              became the bridge between traditional hospitality and cutting-edge
              innovation.
            </p>
          </div>
          <div className="section1Items">
            <h5>Simplifying the Ordering Process</h5>
            <p>
              At the core of our mission is the commitment to simplify the menu
              browsing and ordering process. We understood that a user-friendly
              interface could make a significant impact on the customer's
              journey. EzzOrder is dedicated to providing an intuitive platform
              that empowers customers to explore diverse culinary offerings with
              ease.
            </p>
          </div>
          <div className="section1Items">
            <h5>Partners in Growth</h5>
            <p>
              EzzOrder is more than just a tech company; it's a partner in the
              growth of small cafes and restaurants. Our solutions are designed
              to enhance operational efficiency, optimize order management, and
              provide valuable insights. We take pride in contributing to the
              success of local businesses, fostering a vibrant and thriving
              culinary ecosystem.
            </p>
          </div>
          <div className="section1Items">
            <h5>Looking to the Future</h5>
            <p>
              As we reflect on our journey, we remain committed to staying at
              the forefront of technological advancements in the food industry.
              Our vision extends beyond the present, envisioning a future where
              every dining experience is seamless, delightful, and enhanced
              through the power of technology.
            </p>
          </div>
          <div className="section1Items">
            <h5> Key Features of EzzOrder:</h5>
            <p>
              Digital Menus: Say goodbye to traditional paper menus. EzzOrder
              enables businesses to showcase their offerings digitally, complete
              with vivid images and detailed descriptions, enhancing the overall
              dining experience. Secure Payments: The integration of secure and
              convenient payment gateways ensures that customers can settle
              their bills seamlessly, contributing to quicker table turnovers
              and increased revenue for our partner establishments.
              User-Friendly Interface: EzzOrder is designed with both customers
              and restaurant staff in mind. The interface is user-friendly,
              making it easy for anyone to navigate, whether it's a tech-savvy
              diner or a restaurant employee processing orders. Commitment to
              Excellence: EzzOrder is driven by a team of passionate individuals
              dedicated to excellence in every aspect of our work. From software
              developers crafting elegant code to customer support personnel
              ensuring smooth operations, our team is committed to providing
              unparalleled service.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className="ourTeamTitle" style={{ textAlign: "center" }}>
          Our Team
        </h1>
        <div className="row">
          <div className="column">
            <div className="card">
              <img src={ManishImage} alt="Jane" style={{ width: "100%" }} />
              <div className="container">
                <h2>Manish Singh</h2>
                <p className="title">CEO & Founder</p>
                <p className={manishContent ? "contentLess" : "contentFull"}>
                  Passionate about leading innovative projects and driving
                  organizational success. My journey is all about constant
                  growth, and I'm excited to explore opportunities where I can
                  contribute my technical prowess, creativity, and dedication.
                </p>

                <button
                  className="seeButton"
                  onClick={() => setManishContent(!manishContent)}
                >
                  {manishContent ? "see more" : "see less"}
                </button>
                <p>msrajpoot1000@gmail.com</p>
                <p>
                  <button
                    onClick={() => setManishContact(!manishContact)}
                    className="button"
                  >
                    {manishContact ? "9001874705" : "Contact"}
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <img src={GaneshPhoto} alt="Jane" style={{ width: "100%" }} />
              <div className="container">
                <h2>Ganesh Sharma</h2>
                <p className="title">Process Executer</p>
                <p className={ganeshContent ? "contentLess" : "contentFull"}>
                  Eager to spearhead cutting-edge initiatives and propel
                  organizational triumph, my professional odyssey is centered
                  around relentless advancement. I am enthusiastic about
                  exploring opportunities in the role of a Process Executor,
                  where I can leverage my technical expertise, foster
                  creativity, and channel unwavering commitment to drive
                  transformative projects and ensure operational success. Ready
                  to embrace challenges and lead with a dynamic blend of
                  innovation and dedication, I am poised to make a significant
                  impact on your organization's journey to excellence.
                </p>

                <button
                  className="seeButton"
                  onClick={() => setGaneshContent(!ganeshContent)}
                >
                  {ganeshContent ? "see more" : "see less"}
                </button>
                <p>ganeshsharma220121@gmail.com </p>
                <p>
                  <button
                    className="button"
                    onClick={() => setGaneshContact(!ganeshContact)}
                  >
                    {ganeshContact ? "8306474426" : "Contact"}
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <img src={ManishS} alt="Jane" style={{ width: "100%" }} />
              <div className="container">
                <h2>Manish Sharma</h2>
                <p className="title">Marketing Officer</p>
                <p className={manishSContent ? "contentLess" : "contentFull"}>
                  Passionate about steering groundbreaking projects and steering
                  organizational triumph, my professional journey is marked by a
                  continuous pursuit of growth. I am thrilled to explore
                  opportunities in the capacity of a Marketing Officer, where I
                  can deploy my marketing acumen, ignite creativity, and apply
                  unwavering dedication to elevate brand strategies and
                  contribute to the overall success of the company. Ready to
                  embrace challenges and lead with a dynamic fusion of
                  innovation and commitment, I am poised to make a substantial
                  impact on your organization's marketing endeavors.
                </p>

                <button
                  className="seeButton"
                  onClick={() => setManishSContent(!manishSContent)}
                >
                  {manishSContent ? "see more" : "see less"}
                </button>
                <p>bohramanish4959@gmail.com</p>
                <p>
                  <button
                    className="button"
                    onClick={() => setManishSContact(!manishSContact)}
                  >
                    {manishSContact ? "8306474426" : "Contact"}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
