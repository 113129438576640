import React, { useState, useContext, useEffect } from "react";
import "./EditMenu.css";
import { UserContext } from "../../../UserContext";
import NavbarTop from "../../Navbar/NavbarTop";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";

function EditMenu() {
  const [isToggled, setToggled] = useState(false);
  const [isToggled2, setToggled2] = useState(false);
  const [isToggled3, setToggled3] = useState(false);
  const [menu, setMenu] = useState([]);
  const [catMenu, setCatMenu] = useState({});
  const [loadMenuItems, setLoadMenuItems] = useState(true);
  const [buttonColor, setButtonColor] = useState("#ff5722");

  const { token } = useContext(UserContext);
  const [sendToken] = useState(token);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [dataLoad, setDataLoad] = useState(false);
  const [isEditing, setEditing] = useState({ category: null, index: null });

  useEffect(() => {
    if (loadMenuItems) {
      loadMenu();
      setLoadMenuItems(false);
    }
  }, [loadMenuItems]);

  useEffect(() => {
    setMenu();
    loadMenu();
  }, [location.pathname]);

  const loadMenu = async () => {
    setMenu();
    setCatMenu();
    try {
      const response = await axios.post(process.env.REACT_APP_LOAD_MENU, null, {
        headers: {
          Authorization: `bearer ${Cookies.get("userToken")}`,
        },
      });

      if (response.status === 200) {
        // Use 200 for a successful response
        // alert("Menu loaded successfully");
        await setMenu(response.data);
        setDataLoad(true);
      } else {
        alert("Error loading menu");
      }
    } catch (error) {
      console.error("Error loading menu:", error);
      alert("Error loading menu");
    }
  };
  useEffect(() => {
    // This code will run when menu changes
    const newCatMenu = {};
    if (menu) {
      menu.forEach((item) => {
        if (!newCatMenu[item.cateName]) {
          newCatMenu[item.cateName] = [];
        }
        const { cateName, _id, ...rest } = item; // Exclude _id
        newCatMenu[item.cateName].push(rest);
      });
    }

    setCatMenu(newCatMenu);
  }, [menu]);

  const handleAttributeChange = (category, index, attribute, value) => {
    const updatedCatMenu = { ...catMenu };
    updatedCatMenu[category][index][attribute] = value;
    setCatMenu(updatedCatMenu);
    console.log(catMenu);
  };

  const handleToggleStatus = (category, index) => {
    const updatedCatMenu = { ...catMenu };
    updatedCatMenu[category][index].status =
      !updatedCatMenu[category][index].status;
    setCatMenu(updatedCatMenu);
  };

  const handleEdit = (category, index) => {
    setEditing({ category, index });
    handleModel();
  };

  const handleSave = (category, index) => {
    setEditing({ category: null, index: null });
    console.log(catMenu);
  };

  const handleEditAttribute = (category, index, attributeName, value) => {
    const updatedCatMenu = { ...catMenu };
    updatedCatMenu[category][index][attributeName] = value;
    setCatMenu(updatedCatMenu);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleModel = () => {
    setShow(true);
  };

  const handleUpdateMenuItem = async () => {
    setButtonColor("#4CAF50");
    let convertedMenu = [];
    for (const category in catMenu) {
      catMenu[category].forEach((item) => {
        const convertedItem = { cateName: category, ...item };
        convertedMenu.push(convertedItem);
      });
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_UPDATE_MENU,
        {
          menuItemData: convertedMenu,
        },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );

      if (response.status === 201) {
        alert("update item successfully");
      } else {
        alert("Error adding menu item first");
      }
    } catch (error) {
      console.error("Error adding menu item:", error);
      alert("Error adding menu item");
    }
    convertedMenu = [];
  };

  return (
    <div className="editMenuHead">
      <div>
        <span className="titleEditMenu">Your Menu</span>
        {dataLoad === true ? (
          menu && menu.length > 0 ? (
            <div className="menu-container menuController">
              {Object.keys(catMenu).map((category) => (
                <div key={category} className="menu-category">
                  <h2>{category}</h2>
                  <ul>
                    {catMenu[category].map((item, index) => (
                      <li className="catItemList" key={index}>
                        <div className="itemNameEditMenuHead">
                          <strong>iName:</strong>{" "}
                          <h6 className="itemNameEditMenu">{item.iName}</h6>
                        </div>
                        <br />
                        <strong>Price:</strong> ₹{item.price}
                        <br />
                        <div className="status">
                          <strong>Status:</strong> {item.status ? "On" : "Off"}
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                handleToggleStatus(category, index)
                              }
                              checked={item.status}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="foodType">
                          <strong>foodType : </strong> {item.foodType}
                        </div>
                        <strong>des : </strong> {item.des}
                        <br />
                        {isEditing.category === category &&
                          isEditing.index === index && (
                            <Modal
                              show={show}
                              onHide={handleClose}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Update Data</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div>
                                  <table className="edit-table">
                                    <tr>
                                      <td>
                                        <label>Item Name:</label>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={item.iName}
                                          onChange={(e) =>
                                            handleEditAttribute(
                                              category,
                                              index,
                                              "iName",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label>Item Price:</label>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={item.price}
                                          onChange={(e) =>
                                            handleEditAttribute(
                                              category,
                                              index,
                                              "price",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label>Food Type:</label>
                                      </td>
                                      <td>
                                        <select
                                          value={item.foodType}
                                          onChange={(e) =>
                                            handleEditAttribute(
                                              category,
                                              index,
                                              "foodType",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="Veg">Veg</option>
                                          <option value="NVeg">NVeg</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label>Description:</label>
                                      </td>
                                      <td>
                                        <textarea
                                          value={item.des}
                                          onChange={(e) =>
                                            handleEditAttribute(
                                              category,
                                              index,
                                              "des",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                  <Button
                                    onClick={() => handleSave(category, index)}
                                  >
                                    Ok
                                  </Button>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        <button onClick={() => handleEdit(category, index)}>
                          Edit
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <h3>No Menu Items</h3>
          )
        ) : (
          <div className="growSpinnerHead">
            <Spinner
              className="growSpinner"
              style={{
                color: "#ff5722",
              }}
              animation="grow"
              role="status"
            ></Spinner>
          </div>
        )}
      </div>
      <button
        className="btnOrderNow"
        onClick={handleUpdateMenuItem}
        style={{
          backgroundColor: buttonColor,
          display: dataLoad && menu && menu.length > 0 ? "" : "none",
        }}
      >
        Make changes
      </button>
    </div>
  );
}

export default EditMenu;
