import React, { useState, useContext, useEffect } from "react";
import "./Setting.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../UserContext";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";

import {
  faPhone,
  faEdit,
  faEnvelope,
  faMapMarkerAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

function Setting() {
  const { token } = useContext(UserContext);
  const [resNameModel, setResNameModel] = useState(false);
  const [emailModel, setEmailModel] = useState(false);
  const [mobileModel, setMobileModel] = useState(false);
  const [addressModel, setAddressModel] = useState(false);
  const [timingModel, setTimingModel] = useState(false);

  const [resName, setResName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState({
    address: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [timing, setTiming] = useState({
    start: "",
    close: "",
  });

  const [dataLoad, setDataLoad] = useState(false);
  const [dataResNameUpdate, setDataResNameUpdate] = useState(false);
  const [dataAddressUpdate, setDataAddressUpdate] = useState(false);
  const [message, setMessage] = useState("");

  const handleModelClose = () => {
    setResNameModel(false);
    setEmailModel(false);
    setMobileModel(false);
    setAddressModel(false);
    setTimingModel(false);
    setMessage("");
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        // "http://localhost:5000/setting",
        //  process.env.REACT_APP_ADD_KYC,
        process.env.REACT_APP_ADD_SETTING,
        {},
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      if (response.status === 200) {
        const responseData = response.data;
        setEmail(responseData.email);
        setResName(responseData.nameOfCafe);
        setMobile(responseData.mobile);
        setAddress({
          address: responseData.address.street,
          city: responseData.address.city,
          state: responseData.address.state,
          pincode: responseData.address.pCode,
        });
        setTiming({
          start: responseData.timing.start,
          close: responseData.timing.close,
        });
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditResName = async () => {
    setDataResNameUpdate(true);
    try {
      const response = await axios.post(
        // "http://localhost:5000/edit-res-name",
        process.env.REACT_APP_ADD_SETTING_EDIT_RES_NAME,
        { resName: resName },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      setDataResNameUpdate(false);
      const data = response.data;
      if (response.status === 200) {
        setMessage(response.data.message);
      } else if (data.message === "login first") {
        alert("Login first");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };

  const handleEditAddress = async () => {
    setDataAddressUpdate(true);
    try {
      const response = await axios.post(
        // "http://localhost:5000/edit-address",
        process.env.REACT_APP_ADD_SETTING_EDIT_ADDRESS,
        { address: address },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      const data = response.data;
      setDataAddressUpdate(false);
      if (response.status === 200) {
        setMessage(response.data.message);
      } else if (data.message === "login first") {
        alert("Login first");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  return (
    <div className="settingHead" style={{ opacity: !dataLoad ? "1" : "0.5" }}>
      <div className="spinnerHead" style={{ display: dataLoad ? "" : "none" }}>
        <Spinner
          className=""
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner>
      </div>
      <div className="setting">
        <span className="titleSetting">Setting</span>
        <div className="settingBody">
          <div className="edit_menu">
            <h6 className="edit_menu_title"> Edit Detail</h6>
            <div className="edit_detail_button">
              <button onClick={() => setResNameModel(true)}>
                &gt;&nbsp;
                <FontAwesomeIcon icon={faEdit} /> Restaurant Name
              </button>
              <button onClick={() => setEmailModel(true)}>
                &gt;&nbsp;
                <FontAwesomeIcon icon={faEnvelope} /> Email
              </button>
              <button onClick={() => setMobileModel(true)}>
                &gt; &nbsp;
                <FontAwesomeIcon icon={faPhone} /> Phone
              </button>
              <button onClick={() => setAddressModel(true)}>
                &gt;&nbsp;
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Address
              </button>
              <button onClick={() => setTimingModel(true)}>
                &gt;&nbsp; <FontAwesomeIcon icon={faClock} /> Timing
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modalResName">
        <Modal
          show={resNameModel}
          onHide={handleModelClose}
          className="d-flex justify-content-center align-items-center modal "
        >
          <Modal.Body
            className="modalBody"
            style={{ opacity: dataResNameUpdate ? "0.5" : "1" }}
          >
            <Spinner
              className="modalSpinner"
              style={{
                color: "#ff5722",
                display: dataResNameUpdate ? "" : "none",
              }}
              animation="grow"
              role="status"
            ></Spinner>
            <div className="modalEmailModalBody ">
              <p
                className="editMessage"
                style={{ display: message === "" ? "none" : "" }}
              >
                {message}
              </p>
              <label className="label1">Enter Restaurant Name</label>
              <div className="inputHead">
                <input
                  className="input"
                  value={resName}
                  placeholder="Enter Restaurant Name "
                  onChange={(event) => setResName(event.target.value)}
                />
              </div>
            </div>
            <div className="bottomButton">
              <button onClick={handleModelClose}>Cancel</button>
              <button onClick={handleEditResName}>Submit</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="modalEmail">
        <Modal
          show={emailModel}
          onHide={handleModelClose}
          className="d-flex justify-content-center align-items-center modal "
        >
          <Modal.Body className="modalBody">
            <div className="modalEmailModalBody">
              <label className="label1">Enter Email</label>
              <div className="inputHead">
                <input
                  className="input"
                  value={email}
                  placeholder="Enter Email"
                />
              </div>
            </div>
            <div className="bottomButton">
              <button onClick={handleModelClose}>Cancel</button>
              <button>Submit</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="modelMobile">
        <Modal
          show={mobileModel}
          onHide={handleModelClose}
          className="d-flex justify-content-center align-items-center modal "
        >
          <Modal.Body className="modalBody">
            <div className="modalEmailModalBody">
              <label className="label1">Enter Phone</label>
              <div className="inputHead">
                <input
                  className="input"
                  value={mobile}
                  placeholder="Enter Phone"
                />
              </div>
            </div>
            <div className="bottomButton">
              <button onClick={handleModelClose}>Cancel</button>
              <button>Submit</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="modelAddress">
        <Modal
          show={addressModel}
          onHide={handleModelClose}
          className="d-flex justify-content-center align-items-center modal "
        >
          <Modal.Body
            className="modalBody"
            style={{ opacity: dataAddressUpdate ? "0.5" : "1" }}
          >
            <Spinner
              className="modalSpinner"
              style={{
                color: "#ff5722",
                display: dataAddressUpdate ? "" : "none",
              }}
              animation="grow"
              role="status"
            ></Spinner>
            <div className="modalEmailModalBody">
              <p
                className="editMessage"
                style={{ display: message === "" ? "none" : "" }}
              >
                {message}
              </p>
              <label className="label1">Enter Address</label>
              <div className="inputHead">
                <input
                  className="input"
                  name="address"
                  value={address.address}
                  placeholder="Enter Email"
                  onChange={handleAddressChange}
                />
              </div>

              <label className="label1">Enter City</label>
              <div className="inputHead">
                <input
                  className="input"
                  name="city"
                  value={address.city}
                  placeholder="Enter City"
                  onChange={handleAddressChange}
                />
              </div>

              <label className="label1">Enter State</label>
              <div className="inputHead">
                <input
                  className="input"
                  name="state"
                  value={address.state}
                  placeholder="Enter State"
                  onChange={handleAddressChange}
                />
              </div>

              <label className="label1">Enter Pincode</label>
              <div className="inputHead">
                <input
                  className="input"
                  name="pincode"
                  value={address.pincode}
                  placeholder="Enter Pincode"
                  onChange={handleAddressChange}
                />
              </div>
            </div>
            <div className="bottomButton">
              <button onClick={handleModelClose}>Cancel</button>
              <button onClick={handleEditAddress}>Submit</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="modalTiming">
        <Modal
          show={timingModel}
          onHide={handleModelClose}
          className="d-flex justify-content-center align-items-center modal "
        >
          <Modal.Body className="modalBody">
            <div className="modalEmailModalBody">
              <label className="label1">Enter Open Timing</label>
              <div className="inputHead">
                <input
                  className="input"
                  value={timing.start}
                  placeholder="Enter Email"
                />
              </div>
              <label className="label1">Enter Close Timing</label>
              <div className="inputHead">
                <input
                  className="input"
                  value={timing.close}
                  placeholder="Enter Email"
                />
              </div>
            </div>
            <div className="bottomButton">
              <button onClick={handleModelClose}>Cancel</button>
              <button>Submit</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
export default Setting;
