import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import User from "./components/user/User";
import MenuCreation from "./components/ownerOfCafe/MenuCreation/MenuCreation";
import MainPage from "./components/MainPage/MainPage";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import CafeRegister from "./components/ownerOfCafe/CafeRegister/CafeRegister";
import Register from "./components/LoginLogoutOwner/Register/Register";
import PhotoSubmitAndCheck from "./components/ownerOfweb/PhotoSubmitAndCheck";
import GetOrders from "./components/ownerOfCafe/OrderControl/GetOrders";
import QRcode from "./components/ownerOfCafe/QRcode/QRcode";
import { UserContext } from "./UserContext";
import User2 from "./components/user/User2";
import LoginAndSignup from "./components/LoginLogoutOwner/LoginAndSignup/LoginAndSignup";
import Dashboard from "./components/ownerOfCafe/Dashboard/Dashboard";
import AudioCheck from "./components/ownerOfCafe/OrderControl/AudioCheck";
import User3 from "./components/user/User3";
import User4 from "./components/user/User4";
import AboutUs from "./components/ownerOfweb/AboutUs/AboutUs";
import TermsAndCondition from "./components/ownerOfweb/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./components/ownerOfweb/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./components/ownerOfweb/ContactUs/ContactUs";
import CookiePolicy from "./components/ownerOfweb/CookiePolicy/CookiePolicy";
import User5 from "./components/user/User5";
import RefundCanUser from "./components/ownerOfweb/RefundCan/RefundCanUser/RefundCanUser";
import RefundCanEndUser from "./components/ownerOfweb/RefundCan/RefundCanEndUser/RefundCanEndUser";
import Payment from "./components/user/Payment";
import Kyc from "./components/ownerOfCafe/Kyc/Kyc";
import AdminDashBoard from "./components/ownerOfweb/AdminDashBoard/AdminDashBoard";
import AdminLogin from "./components/ownerOfweb/AdminLogin/AdminLogin";
import VerifyEmail from "./components/ownerOfCafe/VerifyEmail/VerifyEmail";
import ForgotPage from "./components/ownerOfCafe/ForgotPage/ForgotPage";
import ShippingPolicy from "./components/ownerOfweb/ShippingPolicy/ShippingPolicy";
import AdminRegister from "./components/ownerOfweb/AdminRegister/AdminRegister";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const { token } = useContext(UserContext);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/*Admin Section*/}
          <Route
            path="/admin-dash-board/*"
            element={<AdminDashBoard />}
          ></Route>
          <Route path="/login-admin" element={<AdminLogin />} />
          <Route
            path="/verify-email/:param1/:param2"
            element={<VerifyEmail />}
          />
          <Route path="/forgot-pass" element={<ForgotPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/menu/:param" element={<User />} />
          <Route path="/user2" element={<User2 />} />
          <Route path="/login-signup" element={<LoginAndSignup />} />
          <Route path="/dashboard/*" element={<Dashboard />}></Route>
          <Route path="/audio-check" element={<AudioCheck />}></Route>
          <Route path="/user3" element={<User3 />}></Route>
          <Route path="/user4" element={<User4 />}></Route>
          <Route path="/CafeRegister" element={<CafeRegister />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/admin-register" element={<AdminRegister />} />
          {/* <Route path="/user5" element={<User5 />}></Route> */}
          <Route path="/cookie-policy" element={<CookiePolicy />}></Route>
          <Route path="/refund-can-user" element={<RefundCanUser />}></Route>
          <Route
            path="/terms-condition-end-user"
            element={<RefundCanEndUser />}
          />
          <Route
            path="/refund-can-end-user"
            element={<RefundCanEndUser />}
          ></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndCondition />}
          ></Route>
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/kyc" element={<Kyc />}></Route>
          <Route path="/CafeRegister" element={<CafeRegister />}></Route>

          {token ? (
            <>
              <Route path="/MenuCreation" element={<MenuCreation />}></Route>
              <Route path="/CafeRegister" element={<CafeRegister />}></Route>
              <Route
                path="/admin/PhotoSubmitAndCheck"
                element={<PhotoSubmitAndCheck />}
              ></Route>
              <Route
                path="/owerofCafe/GetOrders"
                element={<GetOrders />}
              ></Route>
              <Route path="/your-qr-code" element={<QRcode />}></Route>
              <Route path="/dashboard/*" element={<Dashboard />}></Route>
            </>
          ) : (
            <>
              <Route path="*" element={<MainPage />}></Route>
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
