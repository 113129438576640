import React, { useState } from "react";
import "./Support.css";
function Support() {
  const [message, setMessage] = useState("");

  const handleSendMessage = async () => {
    alert("Message sent successfully!");
    setMessage("");
  };
  return (
    <div className="support">
      <div className="insideSupport">
        <span className="titleSupport">Support</span>
        <div className="support-form">
          <textarea
            rows="4"
            cols="50"
            placeholder="Type your message here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <br />
          <button className="supportBtn" onClick={handleSendMessage}>
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
}
export default Support;
