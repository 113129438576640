import React, { useEffect, useState, useContext } from "react";
import Account from "./account2.png";
import axios from "axios";
import "./AdminDashBoard.css";
import Setting from "../../ownerOfCafe/Setting/Setting";
import { useNavigate, Route, Routes } from "react-router-dom";
import { UserContext } from "../../../UserContext";
import Spinner from "react-bootstrap/Spinner";
import Home from "./Home/AdminDashBoardHome";
import Kyc from "../Kyc/Kyc";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
// import Setting1 from "../../se"

import {
  faHome,
  faCog,
  faUser,
  faBell,
  faIdCard,
  faUserCheck,
  faUserShield,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

function AdminDashBoard() {
  const [dataLoad, setDataLoad] = useState(true);
  const [currentPageName, setCurrentPageName] = useState("Home");
  const [widthOfScreen, setWidthOfScreen] = useState(window.innerWidth);

  const navigate = useNavigate();
  const { token } = useContext(UserContext);

  useEffect(() => {
    function handleResize() {
      setWidthOfScreen(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [widthOfNav, setwidthOfNav] = useState(
    widthOfScreen < 800 ? "0%" : "20%"
  );

  const toggleNavWidth = () => {
    setwidthOfNav(
      widthOfNav === "0%" ? (widthOfScreen < 800 ? "80%" : "20%") : "0%"
    );
  };

  useEffect(() => {
    navigate("/admin-dash-board/home");
  }, []);

  const toggleMenu = (route, currentPageName) => {
    navigate(route);
    setwidthOfNav(widthOfScreen < 800 ? "0%" : "");
    setCurrentPageName(currentPageName);
  };

  useEffect(() => {
    setwidthOfNav(widthOfScreen < 800 ? "0%" : "20%");
  }, [widthOfScreen]);

  return (
    <div className="dashboard" style={{ opacity: dataLoad ? "1" : "0.7" }}>
      <div
        className="growSpinnerHead"
        style={{ display: dataLoad ? "none" : "" }}
      >
        <Spinner
          className="growSpinner"
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner>
      </div>
      <div className="navbarSection">
        <div className="toggleButton navSecton1" onClick={toggleNavWidth}>
          <p className="line"> </p>
          <p className="line"></p>
          <p className="line"></p>
        </div>
        <div className="navSection2">
          <div className="navLeft">
            <p className="home">{currentPageName}</p>
          </div>
          <div className="navRight">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0rem 0.2rem",
              }}
            >
              <h4 className="adminPanel">Admin Panel </h4>
              <FontAwesomeIcon
                icon={faBell}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="insideDashboard">
        <div
          className="navigationbar"
          style={{
            width: widthOfNav,
            transition: "width 0.5s",
            padding: "0%",
          }}
        >
          <h4 className="navCompanyName" onClick={() => navigate("/")}>
            Ezzorder
          </h4>
          <div className="navItems">
            <button
              className="sideButton"
              onClick={() => toggleMenu("/admin-dash-board/home", "Home")}
            >
              <FontAwesomeIcon
                icon={faHome}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Home
            </button>
            <button
              className="sideButton"
              onClick={() =>
                toggleMenu("/admin-dash-board/admin-kyc", "Setting")
              }
            >
              <FontAwesomeIcon
                icon={faIdCard}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Kyc
            </button>
            <button
              className="sideButton"
              onClick={() =>
                toggleMenu("/admin-dash-board/admin-kyc", "Setting")
              }
            >
              <FontAwesomeIcon
                icon={faComment}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Messages
            </button>
            <button
              className="sideButton"
              onClick={() => toggleMenu("/admin-dash-board/setting", "Setting")}
            >
              <FontAwesomeIcon
                icon={faCog}
                style={{ color: "white", paddingRight: "0.3rem" }}
              />
              Setting
            </button>
          </div>
        </div>
        <div
          className="layout"
          style={{
            opacity:
              widthOfScreen < 800 ? (widthOfNav == "80%" ? "0.5" : "1") : "1",
          }}
          onClick={() => (widthOfScreen < 800 ? setwidthOfNav("0%") : null)}
        >
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/admin-kyc" element={<Kyc />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminDashBoard;
