// // import React, { useRef } from "react";
// // import html2canvas from "html2canvas";
// // import img1 from "./wscube_youtube8.jpg";
// // import img2 from "./217.png";
// // import img3 from "./qr.png";
// // import "./Kyc.css";
// // function Kyc() {
// //   // const divRef = useRef(null);
// //   // const handleDownloadClick = () => {
// //   //   // Capture the content of the div as an image
// //   //   html2canvas(divRef.current).then((canvas) => {
// //   //     // Optionally resize the captured image
// //   //     const resizedCanvas = document.createElement("canvas");
// //   //     const ctx = resizedCanvas.getContext("2d");
// //   //     const scaleFactor = 0.5; // Example scale factor
// //   //     resizedCanvas.width = canvas.width * scaleFactor;
// //   //     resizedCanvas.height = canvas.height * scaleFactor;
// //   //     ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

// //   //     // Convert the resized canvas to a data URL
// //   //     const imageUrl = resizedCanvas.toDataURL("image/png");

// //   //     // Create a temporary link element
// //   //     const downloadLink = document.createElement("a");
// //   //     downloadLink.href = imageUrl;
// //   //     downloadLink.download = "downloaded_image.png"; // Set the file name

// //   //     // Trigger the download
// //   //     downloadLink.click();
// //   //   });
// //   // };
// //   const divRef = useRef(null);

// //   const handleDownloadClick = () => {
// //     // Capture the content of the div as an image
// //     html2canvas(divRef.current).then((canvas) => {
// //       // Create a canvas with the desired dimensions
// //       const resizedCanvas = document.createElement("canvas");
// //       resizedCanvas.width = 300;
// //       resizedCanvas.height = 450;
// //       const ctx = resizedCanvas.getContext("2d");

// //       // Scale and draw the captured image onto the resized canvas
// //       ctx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

// //       // Convert the resized canvas to a data URL
// //       const imageUrl = resizedCanvas.toDataURL("image/png");

// //       // Create a temporary link element
// //       const downloadLink = document.createElement("a");
// //       downloadLink.href = imageUrl;
// //       downloadLink.download = "downloaded_image.png"; // Set the file name

// //       // Trigger the download
// //       downloadLink.click();
// //     });
// //   };
// //   return (
// //     <div className="container">
// //       <div class="container2" ref={divRef}>
// //         <p className="menuName">Menu Name</p>
// //         <img src={img2} alt="300x300 Image" className="mainImg" />
// //         <img class="small-image" src={img3} alt="100x100 Image"></img>
// //         <p className="scanYour">Scan Your Order</p>
// //       </div>

// //       <div>
// //         <div>
// //           {/* Content to be downloaded */}
// //           <h1>Hello, World!</h1>
// //           <p>This is the content of the div.</p>
// //         </div>
// //         <button onClick={handleDownloadClick}>Download</button>
// //       </div>
// //     </div>
// //   );
// // }
// // export default Kyc;

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import "./Kyc.css";
import NavbarTop from "../../Navbar/NavbarTop";
import Footer from "../../ownerOfweb/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../UserContext";
import Spinner from "react-bootstrap/Spinner";
import Cookies from "js-cookie";

function Kyc() {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [submitClick, setSubmitClick] = useState(false);
  const [account, setAccount] = useState({
    bankName: "",
    accNo: "",
    reAccNo: "",
    ifsc: "",
    name: "",
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const user = Cookies.get("userToken");
    console.log("User Token:", user);
  }, []);

  const handleSumit = async () => {
    setSubmitClick(true);
    setSubmitClick(false);

    if (account.accNo == " ") {
      setMessage("Enter Account Number !. ");
      setSubmitClick(false);
    } else if (account.reAccNo == "") {
      setMessage("Enter ReEnter Account Number !. ");
      setSubmitClick(false);
    } else if (account.accNo !== account.reAccNo) {
      setMessage("Account Number not same! ");
      setSubmitClick(false);
    } else if (account.ifsc == "") {
      setMessage("Enter IFSC CODE!.");
      setSubmitClick(false);
    } else if (account.name == "") {
      setMessage("Enter Name");
      setSubmitClick(false);
    } else {
      const sendObj = {
        bankName: account.bankName,
        accNo: parseInt(account.accNo).toString().trim(),
        ifsc: account.ifsc.trim(),
        name: account.name.trim(),
      };

      try {
        const response = await axios.post(
          // "http://localhost:5000/kyc",
          process.env.REACT_APP_ADD_KYC,
          { accountInfo: sendObj },
          {
            headers: {
              Authorization: `bearer ${Cookies.get("userToken")}`,
            },
          }
        );
        if (response.status === 200) {
          navigate("/dashboard");
          setSubmitClick(false);
        } else {
          console.log("Unexpected status code:", response.status);
        }
      } catch (error) {
        alert("Error in cafe registration");
        console.error("Error:", error);
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccount({ ...account, [name]: value.toUpperCase() });
  };

  return (
    <div className=" ">
      <div>
        <NavbarTop />
      </div>
      <div
        className="spinnerHead"
        style={{ display: submitClick ? "" : "none" }}
      >
        <Spinner
          className=""
          style={{
            color: "#ff5722",
          }}
          animation="grow"
          role="status"
        ></Spinner>
      </div>
      <div
        className="container kycBody"
        style={{
          opacity: submitClick ? "0.7" : "1",
        }}
      >
        <div>
          <h2 className="title">KYC Form</h2>
        </div>
        <div className="skipHead">
          <button className="skip" onClick={() => navigate("/dashboard")}>
            skip
          </button>
        </div>
        <div className="section1">
          <div className="message">
            <p>{message}</p>
          </div>
          <div className="item">
            <label>Bank Name</label>
            <input
              name="bankName"
              placeholder="Type here"
              type="text"
              value={account.bankName}
              onChange={handleInputChange}
            />
          </div>
          <div className="item">
            <label>Bank Account Number</label>
            <input
              name="accNo"
              placeholder="Type here"
              type=""
              value={account.accNo}
              onChange={handleInputChange}
            />
          </div>
          <div className="item">
            <label>Re-enter Account Number</label>
            <input
              name="reAccNo"
              placeholder="ReEnter Price"
              value={account.reAccNo}
              onChange={handleInputChange}
            />
          </div>
          <div className="item">
            <label>IFSC Code</label>
            <input
              name="ifsc"
              placeholder="Type here"
              type="text"
              value={account.ifsc}
              onChange={handleInputChange}
            />
          </div>
          <div className="item">
            <label>Beneficiary Name</label>
            <input
              name="name"
              placeholder="Type here"
              type="text"
              value={account.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="item">
            <button onClick={handleSumit}> Submit </button>{" "}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
export default Kyc;

// import React, { useState, useContext } from "react";
// import axios from "axios";
// import { UserContext } from "../../../UserContext";

// const Kyc = () => {
//   const [data, setData] = useState("manish");
//   const [secretKey, setSecretKey] = useState("manish");
//   const [encryptedData, setEncryptedData] = useState("");
//   const [decryptedData, setDecryptedData] = useState("");
//   const { token } = useContext(UserContext);
//   const accountInfo = {
//     accNo: "4680100012249",
//     ifsc: "BARB0BAGRAN",
//     name: "Manish Singh",
//   };
//   const handleEncrypt = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/encrypt", // Added comma here
//         { accountInfo: accountInfo }, // Assuming you meant to send a single accountInfo object
//         {
//           headers: {
//             Authorization: `bearer ${token}`,
//           },
//         }
//       );
//     } catch (error) {
//       alert("Error in cafe registration");
//       console.error("Error:", error);
//     }
//   };

//   const handleDecrypt = async () => {
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/decrypt", // Added comma here
//         { accountInfo: "manish" }, // Assuming you meant to send a single accountInfo object
//         {
//           headers: {
//             Authorization: `bearer ${token}`,
//           },
//         }
//       );
//     } catch (error) {
//       alert("Error in cafe registration");
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Data Encryption and Decryption</h1>
//       <label>
//         Data:
//         <input
//           type="text"
//           value={data}
//           onChange={(e) => setData(e.target.value)}
//         />
//       </label>
//       <br />
//       <label>
//         Secret Key:
//         <input
//           type="text"
//           value={secretKey}
//           onChange={(e) => setSecretKey(e.target.value)}
//         />
//       </label>
//       <br />
//       <button onClick={handleEncrypt}>Encrypt</button>
//       <button onClick={handleDecrypt}>Decrypt</button>
//       <br />
//       <label>
//         Encrypted Data:
//         <input type="text" value={encryptedData} readOnly />
//       </label>
//       <br />
//       <label>
//         Decrypted Data:
//         <input type="text" value={decryptedData} readOnly />
//       </label>
//       <h1>{token}</h1>
//     </div>
//   );
// };

// export default Kyc;

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const Kyc = () => {
//   const [data, setData] = useState("");
//   const [secretKey, setSecretKey] = useState("");
//   const [encryptedData, setEncryptedData] = useState("");
//   const [decryptedData, setDecryptedData] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [token, setToken] = useState(""); // State to store JWT token

//   // Function to get JWT token from local storage
//   const getToken = () => {
//     const storedToken = localStorage.getItem("token");
//     if (storedToken) {
//       setToken(storedToken);
//     }
//   };

//   useEffect(() => {
//     getToken();
//   }, []); // Run once on component mount to get the token

//   const handleEncrypt = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/encrypt",
//         { data: data, secretKey: "secretKey" },
//         {
//           headers: {
//             Authorization: `Bearer ${"manish"}`, // Include token in request header
//           },
//         }
//       );
//       setEncryptedData(response.data.encryptedData);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       setError("Error in encryption");
//       console.error("Error:", error);
//     }
//   };

//   const handleDecrypt = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         "http://localhost:5000/decrypt",
//         { data: encryptedData, secretKey: "secretKey" },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`, // Include token in request header
//           },
//         }
//       );
//       setDecryptedData(response.data.decryptedData);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       setError("Error in decryption");
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Data Encryption and Decryption</h1>
//       <label>
//         Data:
//         <input
//           type="text"
//           value={data}
//           onChange={(e) => setData(e.target.value)}
//         />
//       </label>
//       <br />
//       <label>
//         Secret Key:
//         <input
//           type="text"
//           value={secretKey}
//           onChange={(e) => setSecretKey(e.target.value)}
//         />
//       </label>
//       <br />
//       <button onClick={handleEncrypt} disabled={loading}>
//         Encrypt
//       </button>
//       <button onClick={handleDecrypt} disabled={loading}>
//         Decrypt
//       </button>
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       <br />
//       <label>
//         Encrypted Data:
//         <input type="text" value={encryptedData} readOnly />
//       </label>
//       <br />
//       <label>
//         Decrypted Data:
//         <input type="text" value={decryptedData} readOnly />
//       </label>
//     </div>
//   );
// };

// export default Kyc;
