import React, { useState } from "react";
import axios from "axios"; // Import Axios
import "./PhotoSubmitAndCheck.css"; // Import the CSS file

function PhotoSubmitAndCheck() {
  const [email, setEmail] = useState('');
  const [pass, setPassword] = useState('');
  const [catName, setCatName] = useState('');
  const [catImg, setCatImg] = useState('');


  const [showImages, showSetImages] = useState([]);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];

    if (imageFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setCatImg(base64Image);
      };

      reader.readAsDataURL(imageFile);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = {
      email,
      pass,
      catName,
      catImg
    };

    try {
      const response = await axios.post(
        "https://digital-menu-backend3.onrender.com/imageAdd",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        console.log(response.data.message); // User created successfully
        alert(response.data.message);
      } else {
        console.error('Registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };





  const handleLogin = async () => {
    const userData = {
      email,
      pass
    };

    try {
      const response = await axios.post(
        "https://digital-menu-backend3.onrender.com/seeImages",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        showSetImages(response.data); // Set retrieved image data
        alert("please wait images are loading")
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container">
      <div className="photoCheckHead ">
        <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        <input type="text" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
        <input type="text" placeholder="Category Name" onChange={(e) => setCatName(e.target.value)} />
        <input type="file" className="inputImg" onChange={handleImageChange} />
        <button type="submit" onClick={handleSubmit}>Register</button>
      </div>
      <div>{catImg}</div>




      <div className="checkallimages in backend">
        <div> <h1>Check Images</h1></div>
        <div>
          <div className="photoCheckHead container">
            <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            <button type="button" onClick={handleLogin}>show</button>
          </div>
          <div className="imageContainer">
            <div className="groupOfImg">
            {showImages.map((image, index) => (
                <div>
                <img className="checkImg" key={index} src={image.cateImg} alt={image.cateName} />
               <h5>{image.cateName}</h5>
              </div>
            ))}
               </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default PhotoSubmitAndCheck;

