import React, { useEffect } from "react";
import NavbarTop from "../../Navbar/NavbarTop";
import "./TermsAndCondition.css";
import Footer from "../Footer/Footer";
function TermsAndCondition() {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);
  return (
    <div>
      <div>
        <NavbarTop />
      </div>
      <div className="container termsHead">
        <div>
          <h1 className="termsTitle">Terms and Conditions</h1>
        </div>
        <div className="TermsItems">
          <h4>Service Usage:</h4>
          <p>
            EzzOrder, a cutting-edge digital menu and payment platform tailored
            for small cafes and restaurants, is dedicated to providing a
            seamless and compliant service to its users. By engaging with
            EzzOrder, users commit to abiding by all local, state, and federal
            laws and regulations applicable to their operations. We strive to
            create an environment where businesses can thrive within the
            boundaries of the law, fostering a secure and trustworthy platform
            for both users and establishments
          </p>
        </div>
        <div className="TermsItems">
          <h4>Account Information:</h4>
          <p>
            User security is a paramount concern at EzzOrder. Users are
            entrusted with the responsibility of safeguarding their account
            information, including passwords and login credentials. While
            EzzOrder implements robust security measures, users are the first
            line of defense against unauthorized access. EzzOrder disclaims any
            liability for unauthorized usage and urges users to promptly report
            any suspicious activities or breaches, ensuring a collaborative
            effort to maintain the integrity of the platform.
          </p>
        </div>
        <div className="TermsItems">
          <h4> Payment Gateway:</h4>
          <p>
            EzzOrder seamlessly integrates with secure third-party payment
            gateways to facilitate financial transactions. Users are required to
            adhere to the terms and conditions set forth by the chosen payment
            gateway. EzzOrder, while facilitating these transactions, holds no
            responsibility for the policies or performance of external payment
            services. Our commitment lies in providing a reliable and efficient
            platform for users to conduct secure transactions within the
            guidelines of external payment gateways.
          </p>
        </div>
        <div className="TermsItems">
          <h4>4. Data Security:</h4>
          <p>
            EzzOrder prioritizes the security of user data. Employing
            industry-standard security measures, our platform is designed to
            protect user information. Users are encouraged to actively
            participate in maintaining data security by promptly reporting any
            potential vulnerabilities or concerns. EzzOrder is committed to
            fostering a secure environment, leveraging the latest technologies
            to uphold the confidentiality and integrity of user data.
          </p>
        </div>
        <div className="TermsItems">
          <h4> Content Ownership</h4>
          <p>
            At EzzOrder, we respect the ownership rights of restaurants over
            their menu content. Users are explicitly prohibited from using,
            copying, or reproducing any content without obtaining explicit
            permission from the respective restaurant owners. This commitment
            ensures that businesses maintain control over their intellectual
            property, contributing to a fair and ethical usage of the EzzOrder
            platform
          </p>
        </div>
        <div className="TermsItems">
          <h4>Service Modifications:</h4>
          <p>
            EzzOrder, driven by a dedication to continuous improvement, reserves
            the right to update, modify, or enhance its services. These
            modifications may arise to meet industry standards, legal
            requirements, or improve overall user experience. Users will be
            provided with advance notification of significant changes, ensuring
            transparency and allowing them to adapt seamlessly to the evolving
            features and functionalities of the EzzOrder platform.
          </p>
        </div>
        <div className="TermsItems">
          <h4>User Conduct:</h4>
          <p>
            Users engaging with the EzzOrder platform are expected to uphold
            high standards of conduct. The platform is meant for lawful and
            ethical usage only. Any abusive, fraudulent, or inappropriate
            behavior is strictly prohibited and may result in immediate account
            suspension. EzzOrder reserves the right to take legal action if
            necessary to maintain the integrity and reputation of the platform.
          </p>
        </div>

        <div className="TermsItems">
          <h4>Liability Limitation:</h4>
          <p>
            Users acknowledge and accept that their use of the EzzOrder platform
            is at their own risk. EzzOrder is not liable for any direct,
            indirect, incidental, consequential, or exemplary losses, damages,
            or disruptions arising from the use or inability to use our
            services. This acknowledgment underscores the importance of users
            exercising due diligence and discretion in their interactions with
            the platform.
          </p>
        </div>

        <div className="TermsItems">
          <h4>Termination:</h4>
          <p>
            EzzOrder reserves the right to terminate user accounts in the event
            of a breach of these terms and conditions or misuse of the platform.
            Simultaneously, users retain the autonomy to terminate their
            accounts at any time, ensuring a mutual understanding of the
            importance of compliance and ethical usage on both sides.
          </p>
        </div>

        <div className="TermsItems">
          <h4>Governing Law:</h4>
          <p>
            he terms and conditions of EzzOrder are governed by the laws of
            [jurisdiction]. In the event of disputes, arbitration will be the
            preferred method of resolution, adhering to the rules of the
            [Arbitration Association]. This ensures a fair and legally sound
            mechanism for addressing disagreements and conflicts, fostering a
            harmonious and accountable relationship between EzzOrder and its
            users.
          </p>
        </div>
        <div className="TermsItems">
          <p>
            By accessing and using EzzOrder, users explicitly agree to adhere to
            these comprehensive terms and conditions. The aim is to create a
            secure, transparent, and mutually beneficial environment for
            businesses and users within the EzzOrder community. Continuous
            collaboration and adherence to these terms contribute to the success
            and sustainability of the EzzOrder platform. Users are advised to
            review these terms regularly to stay informed about any changes or
            updates, and for legal advice tailored to specific circumstances,
            users are encouraged to seek professional consultat
          </p>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default TermsAndCondition;
