import React, { useState, useContext, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./NavbarTop.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./../../UserContext";
import Typed from "typed.js";
import Cookies from "js-cookie";
import axios from "axios";

function NavbarTop() {
  const { token, setToken } = useContext(UserContext);
  const [loginSucess, setloginSucess] = useState(false);
  const navigate = useNavigate();
  const navigateHomePage = () => {
    navigate("/");
  };
  const navigateLoginSignup = () => {
    navigate("/login-signup");
  };
  const navigateToMenu = () => {
    navigate("/user3");
  };
  const Logout = () => {
    Cookies.remove("userToken");
    setToken("");

    navigate("/");
    window.location.reload();
  };
  useEffect(() => {
    const user = Cookies.get("userToken");
    if (user === undefined) {
      console.log("not logged in");
      setloginSucess(false);
    } else {
      setloginSucess(true);
    }
  }, []);

  const handleLoadAccStatus = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_ACC_STATUS,
        {},
        {
          headers: {
            Authorization: `bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data === "1") {
          alert("first complete this");
          navigate("/CafeRegister");
        } else if (response.data == "2") {
          alert("Please complete this kyc");
          navigate("/kyc");
        } else {
          navigate("/dashboard/home");
        }
      } else {
        alert("something went wrong");
      }
    } catch (error) {
      alert("Error in cafe registration");
      console.error("Error:", error);
    }
  };

  return (
    <div className="navbarTop">
      <div className="navbarTopInside">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand className="navbarHeading" onClick={navigateHomePage}>
            EzzOrder
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="toggleBtn"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end navbarCollapse"
          >
            <Nav className="ml-auto HomePageLink">
              <Nav.Link className="collapseItem " onClick={navigateHomePage}>
                Home
              </Nav.Link>
              <Nav.Link className="collapseItem " onClick={navigateToMenu}>
                Menu
              </Nav.Link>
              {loginSucess ? (
                <div className="loginItem items d-flex flex-row">
                  <Nav.Link
                    className="collapseItem"
                    onClick={handleLoadAccStatus}
                  >
                    Dashboard
                  </Nav.Link>
                  <Nav.Link className="collapseItem" onClick={Logout}>
                    Logout
                  </Nav.Link>
                </div>
              ) : (
                <div className="sus">
                  <Nav.Link
                    className="collapseItem"
                    onClick={navigateLoginSignup}
                  >
                    Login/Signup
                  </Nav.Link>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default NavbarTop;
