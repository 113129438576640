import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
function VerifyEmail() {
  const navigate = useNavigate();
  const { param1, param2 } = useParams();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_VERIFY_EMAIL, {
          email: param1,
          otp: param2,
        });
        const data = response.data;
        if (response.status === 200) {
          alert(response.data.message);
        } else if (data.message === "login first") {
          // Handle login requirement
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    verifyEmail();
  }, [navigate, param1, param2]);

  return (
    <div>
      <h1>Hello</h1>
      <h1>{param1}</h1>
      <h1>{param2}</h1>
    </div>
  );
}

export default VerifyEmail;
