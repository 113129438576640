import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ContactUs.css";
import NavbarTop from "../../Navbar/NavbarTop";
import Footer from "../../ownerOfweb/Footer/Footer";

const Query = () => {
  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [message, setMessage] = useState("");
  const [messageLen, setMessageLen] = useState(query.message.length);

  const handleNameChange = (e) => {
    setQuery({
      ...query,
      name: e.target.value,
    });
  };

  const handleEmailChange = (e) => {
    setQuery({
      ...query,
      email: e.target.value,
    });
  };

  const handleMessageChange = (e) => {
    const messageValue = e.target.value;
    setQuery({
      ...query,
      message: messageValue,
    });
    setMessageLen(query.message.length);
    setMessage("");
    console.log(query.message.length);
  };

  const handleSubmit = async () => {
    if (query.name === "") {
      setMessage("Please Enter Your Name");
    } else if (query.message === "") {
      setMessage("Please Enter Your Message");
    } else if (query.email === "") {
      setMessage("Please Enter Your Email");
    } else if (query.message.length >= 700) {
      setMessage("String is too long", query.message.length);
      console.log(query.message.length);
    } else {
      try {
        const response = await axios.post(process.env.REACT_APP_SEND_QUERY, {
          query: query,
        });

        alert(response.data.message);
        setMessage(response.data.message);
        setQuery({
          ...query,
          name: "",
          email: "",
          message: "",
        });
      } catch (error) {
        console.error(error);
        alert("An error occurred");
      }
    }
  };

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    ScrollToTop();
  }, []);

  return (
    <div className="query-container">
      <div>
        <NavbarTop />
      </div>
      <div className="headOfPage">
        <div className="section1 container">
          <p style={{ display: message ? "" : "none" }} className="message">
            {message}
          </p>
          <div className="item">
            <label>Enter Your Name</label>
            <input
              type="text"
              placeholder="Type here"
              name="name"
              value={query.name}
              onChange={handleNameChange}
            />
          </div>
          <div className="item">
            <label>Enter your email</label>
            <input
              type="text"
              placeholder="Type here"
              name="email"
              value={query.email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="item">
            <label className="messageLabel">
              Enter your message {messageLen > 700 ? <p> (too long)</p> : ""}
            </label>
            <textarea
              name="message"
              placeholder="Enter your message (max 100 words)"
              rows="5"
              value={query.message}
              onChange={handleMessageChange}
            />
          </div>
          <div className="item">
            <button onClick={handleSubmit}>Send</button>
          </div>
        </div>
      </div>
      <div className="container emailContact">
        <p>
          Contact Email :{" "}
          <a className="emailAdd" href="mailto:msrajpoot1000@email.com">
            msrajpoot1000@gmail.com
          </a>{" "}
        </p>
        <p>
          Address : C-94, Ground Floor, Dev Vihar, Agra Road, Jaipur, Rajasthan
        </p>
      </div>
      <div className="queryFooter">
        <Footer />
      </div>
    </div>
  );
};

export default Query;
